import Masonry from 'react-masonry-css';
import { useTranslation } from 'react-i18next';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import DeviceCard from '@dashboard/components/molecules/DeviceCard/DeviceCard';
import { useSpaceContext } from '@src/components/pages/SpacePage/SpaceProvider';
import styled from 'styled-components';
import FullScreenToggle from '@shared/components/atoms/FullScreenToggle/FullScreenToggle';

const masonryBreakpointColumns = {
  default: 3,
  1600: 2,
  900: 1
};

const SpacePage_Devices = () => {
  const { t } = useTranslation();
  const { devices } = useSpaceContext();

  return (
    <PaddedContainer>
      <FlexRow>
        <Title
          text={t('Space.Devices', { ns: 'organisms' })}
        />

        <FullScreenToggle styles={{ marginLeft: 'auto' }} />
      </FlexRow>

      <Masonry
        breakpointCols={masonryBreakpointColumns}
        className="space-page-devices-masonry-grid"
        columnClassName="space-page-devices-masonry-grid_column"
      >
        {devices?.map((device) => (
          <Card noMargin noPadding autoWidth key={device.id}>
            <DeviceCard
              device={device}
              width="350px"
            />
          </Card>
        ))}
      </Masonry>
    </PaddedContainer>
  );
};

export default SpacePage_Devices;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;