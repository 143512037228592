import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getCountries } from '@shared/utils/LocalisationUtils';
import { useApi } from '@shared/hooks/useApi';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { NewSite } from '@shared/api/models/Site/NewSite';
import SiteCreateCommand from '@settings/api/queries/Sites/SiteCreateCommand';
import SiteImageUploadCommand from '@settings/api/queries/Sites/SiteImageUploadCommand';
import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { Select } from '@shared/components/atoms/Select/Select';
import { BlobFileUploadArea } from '@shared/components/molecules/BlobFileUploadArea/BlobFileUploadArea';
import { Button } from '@shared/components/atoms/Button/Button';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';

const countriesArray = getCountries();

type FormValues = {
  name: string,
  address: {
    addressLine1: string,
    addressLine2?: string,
    city: string,
    region?: string,
    country: string,
    postCode: string,
  }
}

const SiteCreate = () => {
  const { t } = useTranslation()
  const theme = useTheme();
  const navigate = useNavigate();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { register, handleSubmit, control, reset, formState: { errors } } = useForm<FormValues>();
  const { file, fileName, fileHasChanged, handleFileChange, handleFileDelete } = useFileHandler();
  const { execute } = useApi();
  const { localisation } = useLocalisation();

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const newSite: NewSite = {
      name: data.name,
      address: data.address
    };

    const siteDto = await execute({
      query: new SiteCreateCommand(newSite),
      successMessage: t('Sites.SiteCreate.SiteCreated', { ns: 'settingsAsset' }),
      errorMessage: t('Sites.SiteCreate.SiteCreateFailed', { ns: 'settingsAsset' })
    });

    if (siteDto && fileHasChanged && file) {
      const res = await execute({
        query: new SiteImageUploadCommand(file, siteDto.id),
        errorMessage: t('Sites.SiteCreate.ImageUploadFailed', { ns: 'settingsAsset' })
      });
      siteDto.siteImageId = res?.blobName;
    }

    setSavingInProgress(false);

    if (siteDto) {
      navigate(`./../site/${siteDto?.id}/settings`);
    }
  }

  const onClear = () => {
    reset({
      name: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        country: '',
        postCode: '',
      }
    });
  };

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('BackToSites', { ns: 'navigation' })}
          url='./..'
        />

        <Card cardTitle={t('Sites.SiteCreate.CreateSite', { ns: 'settingsAsset' })}>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteCreate.Name', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('name', { required: t('Sites.SiteCreate.FieldRequired', { ns: 'settingsAsset' }) })} />
                  <ErrorMessage>{errors.name?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteCreate.AddressLine1', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.addressLine1', { required: t('Sites.SiteCreate.FieldRequired', { ns: 'settingsAsset' }) })} />
                  <ErrorMessage>{errors.address?.addressLine1?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteCreate.AddressLine2', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.addressLine2', {
                    setValueAs: value => nullIfEmptyString(value)
                  })} />
                  <ErrorMessage>{errors.address?.addressLine2?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteCreate.City', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.city', { required: t('Sites.SiteCreate.FieldRequired', { ns: 'settingsAsset' }) })} />
                  <ErrorMessage>{errors.address?.city?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteCreate.Region', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.region', {
                    setValueAs: value => nullIfEmptyString(value)
                  })} />
                  <ErrorMessage>{errors.address?.region?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteCreate.Country', { ns: 'settingsAsset' })}</Label>
                  <Controller
                    control={control}
                    name="address.country"
                    rules={{ required: t('Sites.SiteCreate.FieldRequired', { ns: 'settingsAsset' }) }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={countriesArray.find(country => country.label === value) ?? null}
                        placeholder={t('Sites.SiteCreate.SelectCountry', { ns: 'settingsAsset' })}
                        isSearchable={true}
                        onChange={selected => selected && onChange(selected.label)}
                        options={countriesArray}
                      />
                    )}
                  />
                  <ErrorMessage>{errors.address?.country?.message}</ErrorMessage>
                </div>
                <div className="col-md-2">
                  <Label>{localisation.localPostcodeName}</Label>
                  <Input {...register('address.postCode', { required: t('Sites.SiteCreate.FieldRequired', { ns: 'settingsAsset' }) })} />
                  <ErrorMessage>{errors.address?.postCode?.message}</ErrorMessage>
                </div>
              </div>
            </div>
          </Form>
        </Card>
        <Card>
          <div className="container">
            <div className="row">
              <div className="col">
                <Label>{t('Sites.SiteCreate.SiteImage', { ns: 'settingsAsset' })}</Label>
                <BlobFileUploadArea
                  blobName={fileName}
                  mainText={t('Sites.SiteCreate.UploadImage', { ns: 'settingsAsset' })}
                  dimText={'JPEG, PNG, SVG, CAD'}
                  acceptedTypes={['image/*']}
                  onFileChange={handleFileChange}
                  onFileDelete={handleFileDelete}
                />
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div style={{ display: 'flex' }}>
            <Button
              label={t('Sites.SiteCreate.CreateSite', { ns: 'settingsAsset' })}
              onClick={handleSubmit(onSave)}
              disabled={!isEmpty(errors)}
              loading={savingInProgress}
            />

            <div style={{ marginLeft: 'auto' }} />

            <Button
              tertiary
              label={t('Sites.SiteCreate.ClearFields', { ns: 'settingsAsset' })}
              onClick={onClear}
              color={theme.palette.red}
              style={{ marginLeft: 'auto' }}
            />
          </div>
        </Card>
      </Container>
    </PaddedContainer>
  );
}

export default SiteCreate;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;