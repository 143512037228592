import ChartActionButtons from '@dashboard/components/atoms/charts/ChartActionButtons';
import { Card } from '@shared/components/molecules/Card/Card';
import { LineBarChartType } from '@shared/components/molecules/Charts/Chart.types';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IChartContainer {
  cardTitle: string;
  chartType: LineBarChartType,
  onChartTypeChange: (type: LineBarChartType) => void
  onResetZoom: () => void,
  hasBeenZoomed: boolean
  children?: ReactNode;
}

const ChartContainer = ({ cardTitle, chartType, onChartTypeChange, onResetZoom, hasBeenZoomed, children }: IChartContainer) => {
  const { t } = useTranslation(['molecules']);

  return (
    <Card
      cardTitle={cardTitle}
      modalTitle={t('DynamicReport.ChartInteractions', { ns: 'molecules' })}
      modalContent={
        <>
          <div>
            <Trans i18nKey='molecules:DynamicReport.Zoom'>
              <BoldText></BoldText>
            </Trans>
          </div><br />
          <div>
            <Trans i18nKey='molecules:DynamicReport.Pan'>
              <BoldText></BoldText>
            </Trans>
          </div><br />
          <div>
            <Trans i18nKey='molecules:DynamicReport.Legend'>
              <BoldText></BoldText>
            </Trans>
          </div>
        </>
      }
      titleRowElement={
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          <ChartActionButtons
            hasBeenZoomed={hasBeenZoomed}
            handleResetZoom={onResetZoom}
            selectedChartType={chartType}
            setSelectedChartType={onChartTypeChange}
          />
        </div>
      }
      style={{ marginBottom: '20px' }}
    >
      <Wrapper>
        {children}
      </Wrapper>
    </Card>

  );
};

export default ChartContainer;

const Wrapper = styled.div`
  height: 250px;
`;

const BoldText = styled.span`
  font-weight: 500;
`;