import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import { hasOverridenValues } from '../ClimateControlUtils';
import SpaceExceptions from '../Setup/SpaceExceptions/SpaceExceptions';
import SetupInformation from '../SetupInformation';

const Exceptions = () => {
  const { t } = useTranslation();
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();
  const theme = useTheme();
  const navigate = useNavigate();

  const exceptions = useMemo(() => (
    siteClimateControl?.spaceClimateControls.filter((exception: SpaceClimateControlDto) => hasOverridenValues(exception))
  ), [siteClimateControl?.spaceClimateControls]);

  if (!siteClimateControl) {
    return null;
  }

  const onUpdate = async (): Promise<void> => {
    navigate('./..');
    toast.success(t('ChangesSaveSuccess', { ns: 'status' }));
  };

  return (
    <ResponsiveContainer>
      <Title
        size='lg'
        text={t('ClimateControl.ManageExceptions', { ns: 'molecules' })}
      />
      <SpaceExceptions
        siteClimateControl={siteClimateControl}
        refreshSiteClimateControl={refreshSiteClimateControl}
        exceptions={exceptions ?? []}
        showStatus={true}
      />
      <SetupInformation />
      <ButtonContainer>
        <Button
          tertiary
          label={t('Cancel', { ns: 'common' })}
          onClick={() => navigate('./..')}
          color={theme.palette.red}
        />
        <Button
          label={t('Update', { ns: 'common' })}
          onClick={onUpdate}
          analytics={{ action: 'update_exceptions', category: 'climate_control' }}
        />
      </ButtonContainer>
    </ResponsiveContainer>
  )
}

export default Exceptions

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  @media print {
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;