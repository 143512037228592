import styled, { css, useTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { transparentize } from 'polished';
import React, { useEffect, useState } from 'react';
import { formatDistanceToNowStrict, sub } from 'date-fns';
import DeviceNoteAttachmentModal from './DeviceNoteAttachmentModal';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { DeviceNoteUpdateCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteUpdateCommand';
import { DeviceNoteCreateCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteCreateCommand';
import { DeviceNoteDeleteCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteDeleteCommand';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { useModal } from '@shared/hooks/useModal';
import { Button } from '@shared/components/atoms/Button/Button';
import { UserRole } from '@shared/api/enums/UserRole/UserRole';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { TextArea } from '@shared/components/atoms/Form/Form';

interface IStickyNote {
  note?: DeviceNote;
  deviceId: number;
  refreshNotes: () => void;
}

const StickyNote = ({ note, deviceId, refreshNotes }: IStickyNote) => {
  const theme = useTheme();
  const { execute } = useApi();
  const { hasRole } = useUserContext();
  const [content, setContent] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: refModal } = useModal({});
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});

  const useModifedDate = note && new Date(note.modifiedOn) > sub(new Date(), { years: 100 });
  const { t } = useTranslation(['molecules']);

  useEffect(() => {
    if (note) {
      setContent(note.contents);
    } else {
      setEditMode(true);
    }
  }, [note]);

  const onCancel = () => {
    if (note) {
      setContent(note.contents);
      setEditMode(false);
    } else {
      setContent('');
    }
  };

  const onSave = async () => {
    if (note) {
      await execute({ query: new DeviceNoteUpdateCommand(note.id, content) });
      setEditMode(false);
    } else {
      await execute({ query: new DeviceNoteCreateCommand(deviceId, content) });
      setContent('');
    }

    refreshNotes();
  };

  const deleteNote = async () => {
    if (note) {
      await execute({ query: new DeviceNoteDeleteCommand(note.id) });
    }

    refreshNotes();
  };

  return (
    <>
      <Container>
        <Header>
          {note &&
            <div>
              <Label style={{ fontWeight: 500 }} wide={!editMode}>{note.createdByName}</Label>
              <Label wide={!editMode}>{formatDistanceToNowStrict(new Date(useModifedDate ? note.modifiedOn : note.createdOn), { addSuffix: true })}</Label>
            </div>
          }

          {editMode && content.length > 0 &&
            <>
              <div style={{ marginLeft: 'auto' }} />
              <Button
                tertiary
                circle
                label={<FontAwesomeIcon icon={solid('times')} style={{ width: '16px', height: '16px' }} />}
                onClick={onCancel}
                style={{ padding: '5px' }}
                color={theme.palette.red}
                hoverBackgroundColor='#00000020'
              />
              <Button
                tertiary
                circle
                label={<FontAwesomeIcon icon={solid('check')} style={{ width: '16px', height: '16px' }} />}
                onClick={onSave}
                style={{ padding: '5px' }}
                hoverBackgroundColor='#00000020'
              />
            </>
          }

          {(note?.createdByCurrentUser || hasRole(UserRole.SuperAdministrator)) && !editMode &&
            <>
              <div style={{ marginLeft: 'auto' }} />
              <Hidden>
                <Button
                  tertiary
                  circle
                  label={<FontAwesomeIcon icon={solid('trash-xmark')} />}
                  onClick={toggleDeleteDialog}
                  style={{ padding: '7px' }}
                  color={theme.palette.red}
                  hoverBackgroundColor='#00000020'
                />
              </Hidden>
              <Hidden>
                <Button
                  tertiary
                  circle
                  label={<FontAwesomeIcon icon={solid('pen-to-square')} />}
                  onClick={() => setEditMode(true)}
                  style={{ padding: '7px' }}
                  hoverBackgroundColor='#00000020'
                />
              </Hidden>
              <Hidden forceVisible={note && note.attachments.length > 0}>
                <Button
                  tertiary
                  circle
                  label={<FontAwesomeIcon icon={solid('paperclip')} />}
                  onClick={() => toggleModal()}
                  style={{ padding: '7px' }}
                  hoverBackgroundColor='#00000020'
                />
              </Hidden>
            </>
          }
        </Header>

        <Content>
          <StyledTextArea
            onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContent(e.currentTarget.value)}
            placeholder={t('DeviceCard.LeaveANote', { ns: 'molecules' })}
            value={content}
            readOnly={!editMode}
          />
        </Content>
        <WarpSpan />
      </Container>

      {note &&
        <DeviceNoteAttachmentModal
          modalRef={refModal}
          open={modalIsOpen}
          hide={toggleModal}
          note={note}
          refreshNotes={refreshNotes}
        />
      }

      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('DeviceCard.DeleteNoteConfirm', { ns: 'molecules' })}
        confirmButton={t('DeviceCard.Delete', { ns: 'molecules' })}
        onCancel={toggleDeleteDialog}
        onConfirm={deleteNote}
      />
    </>
  )
}

export default StickyNote;

const Container = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  box-shadow: ${p => p.theme.palette.stickyNotes.shadow1} 0px 8px 6px, ${p => p.theme.palette.stickyNotes.shadow2} 0px 3px 6px;
  border-radius: 3px;

  &:hover {
    transform: scale(1.1);
    transition: all 400ms ease;
    z-index: 1;
  }
`;

const WarpSpan = styled.span`
  position: absolute;
  width: 100%;
  height: 13px;
  bottom: -26px; /* ~equal to the height + shadow y offset + shadow blur radius */
  right: 0px;
  box-shadow: 0px -10px 5px ${p => p.theme.palette.backgrounds.background};
  -moz-box-shadow: 0px -10px 5px ${p => p.theme.palette.backgrounds.background};
  -webkit-box-shadow: 0px -10px 5px ${p => p.theme.palette.backgrounds.background};
  border-radius: 50% / 20px;
  -moz-border-radius: 50% / 20px;
  -webkit-border-radius: 400px 20px;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${p => p.theme.palette.stickyNotes.backgroundHeader};
  border-radius: 3px 3px 0 0;

  display: flex;
  align-items: center;
  padding: 0 10px 0 15px;
`;

const Content = styled.div`
  width: 100%;
  height: 210px;
  overflow: auto;
  font-size: 16px;
  background: ${p => `radial-gradient(ellipse at left bottom, ${p.theme.palette.stickyNotes.backgroundLight} 0%,  ${p.theme.palette.stickyNotes.background} 62%,  ${p.theme.palette.stickyNotes.background} 100%)`};
  border-radius:  0 0 3px 3px;
`;

const Hidden = styled.div<{ forceVisible?: boolean }>`
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease;
  
  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
  }

  ${p => p.forceVisible && css`
    opacity: 1;
    visibility: visible;
  `}
`;

const StyledTextArea = styled(TextArea)`
  background-color: transparent;
  box-shadow: none;
  padding: 15px;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: ${p => p.theme.palette.stickyNotes.text};

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: #525666;
    font-weight: 400;
  }
`;

const Label = styled.div<{ wide: boolean }>`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${p => transparentize(0.3, p.theme.palette.stickyNotes.text)};

  ${Container}:hover & {
    color: ${p => transparentize(0.2, p.theme.palette.stickyNotes.text)};
  }

  max-width: ${p => p.wide ? '160px' : '110px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;