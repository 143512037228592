import { stringToFloat, stringToPercentage } from '@shared/utils/NumberUtils';
import { MetricType } from './MetricType';

/**
 * Get typed value
 */
export const MetricType_TypedValue = ({ metricType, value }: { metricType: MetricType, value: string }): string | number | Date => {
  const modifierFunc = metricTypeToTypedValueModifierFunction[metricType];
  return modifierFunc !== undefined ? modifierFunc(value) : value;
}

/**
 * Map MetricType to the appropriate typed value
 */
const metricTypeToTypedValueModifierFunction: { [key in MetricType]: (x: string) => string | number | Date; } = Object.freeze({
  /** Common */
  [MetricType.BatteryVoltage]: (x: string) => stringToFloat(x, 1),
  [MetricType.BatteryPercentage]: (x: string) => stringToFloat(x, 1),
  [MetricType.RSSI]: (x: string) => stringToFloat(x, 1),
  [MetricType.SNR]: (x: string) => stringToFloat(x, 1),
  [MetricType.SignalStrength]: (x: string) => stringToFloat(x, 0),
  [MetricType.DataRate]: (x: string) => x,
  [MetricType.DeviceLastMeasuredOn]: (x: string) => new Date(x),

  /** Environmental */
  [MetricType.CO2]: (x: string) => stringToFloat(x, 1),
  [MetricType.OutdoorHumidity]: (x: string) => stringToPercentage(x, 0),
  [MetricType.Humidity]: (x: string) => stringToFloat(x, 2),
  [MetricType.Light]: (x: string) => stringToFloat(x, 1),
  [MetricType.LightLevel]: (x: string) => stringToFloat(x, 0),
  [MetricType.Noise]: (x: string) => stringToFloat(x, 1),
  [MetricType.NoiseRaw]: (x: string) => stringToFloat(x, 1),
  [MetricType.PIR]: (x: string) => stringToFloat(x, 0),
  [MetricType.OutdoorTemperature]: (x: string) => stringToFloat(x, 1),
  [MetricType.Temperature]: (x: string) => stringToFloat(x, 1),
  [MetricType.MotionMeasuredOn]: (x: string) => new Date(x),
  [MetricType.VOC]: (x: string) => stringToFloat(x, 2),
  [MetricType.Pressure]: (x: string) => stringToFloat(x, 0),
  [MetricType.CO]: (x: string) => stringToFloat(x, 2),
  [MetricType.O2]: (x: string) => stringToFloat(x, 2),
  [MetricType.H2S]: (x: string) => stringToFloat(x, 0),
  [MetricType.Pollutants]: (x: string) => stringToFloat(x, 0),
  [MetricType.Particulates2_5]: (x: string) => stringToFloat(x, 0),
  [MetricType.Particulates10]: (x: string) => stringToFloat(x, 0),
  [MetricType.NO]: (x: string) => stringToFloat(x, 2),
  [MetricType.NO2]: (x: string) => stringToFloat(x, 2),
  [MetricType.IAQ]: (x: string) => stringToFloat(x, 0),
  [MetricType.HCHO]: (x: string) => stringToFloat(x, 2),
  [MetricType.O3]: (x: string) => stringToFloat(x, 0),
  [MetricType.WaterLeak]: (x: string) => x,
  [MetricType.Emissions]: (x: string) => stringToFloat(x, 0),

  /** Wellness */
  [MetricType.CO2Score]: (x: string) => stringToFloat(x, 0),
  [MetricType.HumidityScore]: (x: string) => stringToFloat(x, 0),
  [MetricType.LightScore]: (x: string) => stringToFloat(x, 0),
  [MetricType.NoiseScore]: (x: string) => stringToFloat(x, 0),
  [MetricType.TemperatureScore]: (x: string) => stringToFloat(x, 0),
  [MetricType.WellnessScore]: (x: string) => stringToFloat(x, 1),

  /** Energy Monitoring */
  [MetricType.ElectricityKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityKwhDelta]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityGeneratedKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityGeneratedKwhDelta]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityKwhCost]: (x: string) => stringToFloat(x, 2),
  [MetricType.GasVolume]: (x: string) => stringToFloat(x, 2),
  [MetricType.GasVolumeDelta]: (x: string) => stringToFloat(x, 2),
  [MetricType.GasVolumeCost]: (x: string) => stringToFloat(x, 2),
  [MetricType.GasKwh]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.GasKwhDelta]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.HeatingKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.HeatingKwhDelta]: (x: string) => stringToFloat(x, 2),
  [MetricType.HeatingKwhCost]: (x: string) => stringToFloat(x, 2),
  [MetricType.WaterVolume]: (x: string) => stringToFloat(x, 2),
  [MetricType.WaterVolumeDelta]: (x: string) => stringToFloat(x, 2),
  [MetricType.WaterVolumeCost]: (x: string) => stringToFloat(x, 2),
  [MetricType.CarbonIntensityElectricity]: (x: string) => stringToFloat(x, 2),
  [MetricType.CarbonIntensityElectricitySaved]: (x: string) => stringToFloat(x, 2),
  [MetricType.CoolingKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.CoolingKwhDelta]: (x: string) => stringToFloat(x, 2),
  [MetricType.CoolingKwhCost]: (x: string) => stringToFloat(x, 2),
  [MetricType.CarbonIntensityCooling]: (x: string) => stringToFloat(x, 2),

  [MetricType.ElectricityL1Voltage]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL2Voltage]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL3Voltage]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL1Current]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL2Current]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL3Current]: (x: string) => stringToFloat(x, 2),

  [MetricType.ElectricityL1PowerFactor]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL2PowerFactor]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL3PowerFactor]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL1ActivePower]: (x: string) => stringToFloat(x, 3),
  [MetricType.ElectricityL2ActivePower]: (x: string) => stringToFloat(x, 3),
  [MetricType.ElectricityL3ActivePower]: (x: string) => stringToFloat(x, 3),

  [MetricType.ElectricityTotalActivePower]: (x: string) => stringToFloat(x, 3),
  [MetricType.ElectricityMaxTotalPowerDemand]: (x: string) =>
    stringToFloat(x, 3),
  [MetricType.ElectricityMaxL1CurrentDemand]: (x: string) =>
    stringToFloat(x, 2),
  [MetricType.ElectricityMaxL2CurrentDemand]: (x: string) =>
    stringToFloat(x, 2),
  [MetricType.ElectricityMaxL3CurrentDemand]: (x: string) =>
    stringToFloat(x, 2),
  [MetricType.ElectricityMaxNCurrentDemand]: (x: string) => stringToFloat(x, 2),

  [MetricType.ElectricityTotalKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL1ImportKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL2ImportKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL3ImportKwh]: (x: string) => stringToFloat(x, 2),

  [MetricType.ElectricityL1ExportKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL2ExportKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL3ExportKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL1TotalKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL2TotalKwh]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityL3TotalKwh]: (x: string) => stringToFloat(x, 2),

  [MetricType.ElectricityGeneratedL1KwhDelta]: (x: string) =>
    stringToFloat(x, 2),
  [MetricType.ElectricityGeneratedL2KwhDelta]: (x: string) =>
    stringToFloat(x, 2),
  [MetricType.ElectricityGeneratedL3KwhDelta]: (x: string) =>
    stringToFloat(x, 2),

  [MetricType.ElectricityConsumedL1KwhDelta]: (x: string) =>
    stringToFloat(x, 2),
  [MetricType.ElectricityConsumedL2KwhDelta]: (x: string) =>
    stringToFloat(x, 2),
  [MetricType.ElectricityConsumedL3KwhDelta]: (x: string) =>
    stringToFloat(x, 2),

  /** Occupancy */
  [MetricType.IsolatedPeopleCount]: (x: string) => stringToFloat(x, 0),
  [MetricType.EnterCount]: (x: string) => stringToFloat(x, 0),
  [MetricType.ExitCount]: (x: string) => stringToFloat(x, 0),
  [MetricType.CalculatedOccupancy]: (x: string) => stringToFloat(x, 0),

  /** Relay */
  [MetricType.RelayOutputStatus]: (x: string) => (x === 'true' ? 'ON' : 'OFF'),

  /** Switch */
  [MetricType.ContactSwitch]: (x: string) => (x === '1' ? 'Open' : 'Closed'),

  /** Wavetrend */
  [MetricType.Sensor1Temp]: (x: string) => stringToFloat(x, 1),
  [MetricType.Sensor2Temp]: (x: string) => stringToFloat(x, 1),
  [MetricType.Sensor3Temp]: (x: string) => stringToFloat(x, 1),
  [MetricType.Sensor1MinTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor1MaxTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor1FlowCount]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor1Compliant]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor1Error]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor2MinTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor2MaxTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor2FlowCount]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor2Compliant]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor2Error]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor3MinTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor3MaxTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor3FlowCount]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor3Compliant]: (x: string) => stringToFloat(x, 0),
  [MetricType.Sensor3Error]: (x: string) => stringToFloat(x, 0),
  [MetricType.SensorScald]: (x: string) => stringToFloat(x, 0),
  [MetricType.SensorScaldTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.SensorFreeze]: (x: string) => stringToFloat(x, 0),
  [MetricType.SensorFreezeTemp]: (x: string) => stringToFloat(x, 0),
  [MetricType.ErrorMessage]: (x: string) => x,

  /** Weather */
  [MetricType.WindSpeedAverage]: (x: string) => stringToFloat(x, 2),
  [MetricType.WindDirectionAverage]: (x: string) => stringToFloat(x, 0),

  /** IoTSens Sound Monitor */
  [MetricType.Percentile_P1]: (x: string) => stringToFloat(x, 1),
  [MetricType.Percentile_P10]: (x: string) => stringToFloat(x, 1),
  [MetricType.Percentile_P50]: (x: string) => stringToFloat(x, 1),
  [MetricType.Percentile_P90]: (x: string) => stringToFloat(x, 1),
  [MetricType.Percentile_P99]: (x: string) => stringToFloat(x, 1),
  [MetricType.DBA_Min]: (x: string) => stringToFloat(x, 1),
  [MetricType.LAEQ]: (x: string) => stringToFloat(x, 1),
  [MetricType.DBA_Max]: (x: string) => stringToFloat(x, 1),

  /**External Metrics */
  [MetricType.CarbonIntensityForecast]: (x: string) => stringToFloat(x, 2),

  /** Axioma Water Meter */
  [MetricType.WaterMeterAlarm]: (x: string) => x,

  /**Vutility HotDrop */
  [MetricType.AmpHourAccumulation]: (x: string) => stringToFloat(x, 2),
  [MetricType.AverageAmps]: (x: string) => stringToFloat(x, 2),
  [MetricType.MaximumAmps]: (x: string) => stringToFloat(x, 2),
  [MetricType.MinimumAmps]: (x: string) => stringToFloat(x, 2),
  [MetricType.CapacitorVoltage]: (x: string) => stringToFloat(x, 2),

  /**Utopi Eastron SDM230 */
  [MetricType.ElectricityMaxExportPowerDemand]: (x: string) => stringToFloat(x, 3),
  [MetricType.ElectricityMaxImportPowerDemand]: (x: string) => stringToFloat(x, 3),
  [MetricType.ElectricityExportKwhDelta]: (x: string) => stringToFloat(x, 2),
  [MetricType.ElectricityExportKwh]: (x: string) => stringToFloat(x, 2),

  /** MClimate Vicki TRV */
  [MetricType.TemperatureTarget]: (x: string) => `${stringToFloat(x, 1)}`,
  [MetricType.MinTemperature]: (x: string) => `${stringToFloat(x, 1)}`,
  [MetricType.MaxTemperature]: (x: string) => `${stringToFloat(x, 1)}`,
  [MetricType.ManualTargetTemperatureUpdate]: (x: string) => `${stringToFloat(x, 1)}`,
  [MetricType.WindowPosition]: (x: string) => x,
  [MetricType.ChildLock]: (x: string) => x,
  [MetricType.MotorRange]: (x: string) => x,
  [MetricType.MotorPosition]: (x: string) => x,
  [MetricType.HighMotorConsumption]: (x: string) => x,
  [MetricType.LowMotorConsumption]: (x: string) => x,
  [MetricType.BrokenSensor]: (x: string) => x,
  [MetricType.CalibrationFailed]: (x: string) => x,
  [MetricType.AttachedBackplate]: (x: string) => x,
  [MetricType.PerceiveAsOnline]: (x: string) => x,
  [MetricType.OperationalMode]: (x: string) => x,
  [MetricType.KeepAliveTime]: (x: string) => x,

  /**Axioma Qalcasonic E3 */
  [MetricType.HeatingTemperature]: (x: string) => stringToFloat(x, 2),
  [MetricType.HeatingPower]: (x: string) => stringToFloat(x, 2),
  [MetricType.HeatingVolume]: (x: string) => stringToFloat(x, 2),
  [MetricType.CoolingTemperature]: (x: string) => stringToFloat(x, 2)
});