import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import ChallengeInfoContent from './ChallengeInfoContent';
import { FormProvider, useForm } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import PrizeInfoContent from './PrizeInfoContent';
import ImageInfoContent, { ImageType } from './ImageInfoContent';
import { useState } from 'react';
import { Button } from '@shared/components/atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useApi } from '@shared/hooks/useApi';
import { ChallengesResidentAppCreateCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppCreateCommand';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';
import { isEndDateAfterStartDate } from '../ResidentAppUtils';
import { ChallengesResidentAppUploadImageCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppUploadImageCommand';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { dateToUtcDate } from '@shared/utils/DateUtils';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';

export type CreateChallengeForm = {
  startDate: Dayjs;
  endDate: Dayjs;
  averageTemperature: number;
  title: string;
  challengeDescription: string;
  prizes: Array<{
    id?: number;
    prizeName: string;
    prizeValue?: number;
  }>;
  shortTitle?: string;
  imageType: ImageType;
}


const ResidentApp_CreateChallenge = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const navigate = useNavigate();
  const { execute, loading, error } = useApi();
  const { site } = useSiteContext();
  const {file, handleFileChange, handleFileDelete} = useFileHandler();
  const { fromLocale } = useLocalisation();

  const methods = useForm<CreateChallengeForm>({
    defaultValues: {
      prizes: [{prizeName: '', prizeValue: undefined}],
      imageType: ImageType.UseTemplate
    }
  })

  const [customImageUrl, setCustomImageUrl] = useState<string | undefined>(undefined)

  const handleImageChange = (files: File[]) => {
    const url = URL.createObjectURL(files[0]);
    setCustomImageUrl(url);
    handleFileChange(files[0]);
  };

  const handleImageDelete = () => {
    setCustomImageUrl(undefined);
    handleFileDelete();
  };

  const handleCreateChallenge = async (data: CreateChallengeForm) => {
    if (!isEndDateAfterStartDate(data.startDate, data.endDate)){
      methods.setFocus('startDate');
      return;
    }

    if (data.imageType === ImageType.UploadImage && !customImageUrl) {
      methods.setFocus('imageType');
      return;
    }

    const shortTitleData = data.shortTitle && data.shortTitle.length > 0 ? data.shortTitle : t('ResidentApp.NewChallenge');
    const prizes: OperatorPrizeDto[] = data.prizes.map((prize) => ({
      prizeName: prize.prizeName,
      prizeValue: prize.prizeValue ?? 0,
      id: 0,
      challengeId: 0,
    }));
    const response = await execute({
      query: new ChallengesResidentAppCreateCommand(
        data.title,
        shortTitleData,
        data.challengeDescription,
        MetricType.Temperature,
        fromLocale(MetricType.Temperature, data.averageTemperature),
        dateToUtcDate(data.startDate).toISOString(),
        dateToUtcDate(data.endDate).toISOString(),
        site.id,
        prizes
      ),
      successMessage: t('CreateChallengeSuccess', { ns: 'status' }),
      errorMessage: t('CreateChallengeError', { ns: 'status' }),
      pendingMessage: t('CreateChallengePending', { ns: 'status' }),
    });

    if (response && !error) {
      // if it's a custom image then call upload image api
      if(data.imageType === ImageType.UploadImage && file) {
        await execute({
          query: new ChallengesResidentAppUploadImageCommand(response.id, file),
          successMessage: t('UploadChallengeImageSuccess', { ns: 'status' }),
          errorMessage: t('UploadChallengeImageError', { ns: 'status' }),
          pendingMessage: t('UploadChallengeImagePending', { ns: 'status' }),
        })
      }
      // navigate to challenge view page
      navigate(`../resident-app/view-challenge/${response.id}`);
    }
  };
  
  return (
    <PaddedContainer>
      <TitleWithButton title={t('ResidentApp.CreateChallenge')} backButtonUrl='../resident-app' style={{padding: '0 0 30px 0'}} />
      <CreateChallengeContainer noPadding maxWidth='fit-content'>
        <FormProvider {...methods}>
          <CreateChallengeForm>
            <ChallengeInfoContent methods={methods} />
            <PrizeInfoContent methods={methods} />
            <ImageInfoContent
              methods={methods}
              onImageChange={handleImageChange}
              onImageDelete={handleImageDelete}
              imageUrl={customImageUrl}
            />

            <Buttons>
              <Button
                label={t('Cancel', { ns: 'common' })}
                onClick={() => navigate('../resident-app')}
                tertiary
                color={theme.palette.systemMessage.error}
                disabled={loading}
              />
              <Button 
                label={t('Create', { ns: 'common' })}
                onClick={methods.handleSubmit((data) => handleCreateChallenge(data))}
                disabled={loading}
              />
            </Buttons>
          </CreateChallengeForm>
        </FormProvider>
      </CreateChallengeContainer>
    </PaddedContainer>
  );
}

export default ResidentApp_CreateChallenge;

const CreateChallengeContainer = styled(Card)`
  padding: 25px 20px;
  margin-top: 30px;
`;

const CreateChallengeForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;