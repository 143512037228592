import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { EnergyMeterGetByDeviceIdQuery } from '@dashboard/api/queries/energyMeters/EnergyMetersGetByDeviceIdQuery';
import { EnergyMeter } from '@shared/api/models/EnergyMeter/EnergyMeter';
import { useApi } from '@shared/hooks/useApi';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';

interface IDeviceCardEnergyMeters {
  deviceId: number;
}

const DeviceCardEnergyMeters = ({ deviceId }: IDeviceCardEnergyMeters) => {
  const { execute } = useApi();
  const [energyMeters, setEnergyMeters] = useState<EnergyMeter[]>([]);
  const { t } = useTranslation(['molecules']);
  const { displayString } = useLocalisation();

  useEffect(() => {
    const fetchEnergyMeters = async () => {
      const meters = await execute({ query: new EnergyMeterGetByDeviceIdQuery(deviceId) });
      meters && setEnergyMeters(meters);
    };

    fetchEnergyMeters();
  }, [deviceId, execute]);

  return (
    <>
      {energyMeters.map((energyMeter) => (
        <div key={energyMeter.id}>
          <div className="row" style={{ paddingBottom: '18px' }}>
            <div className="col">
              <LabelValuePair
                label={t('DeviceCard.MeterType', { ns: 'molecules' })}
                value={energyMeter.meterType}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
            </div>
            <div className="col">
              <LabelValuePair
                label={t('DeviceCard.MeterId', { ns: 'molecules' })}
                value={energyMeter.meterNumber}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
            </div>
          </div>
          {energyMeter.latestMeasurements[0]?.value &&
            <div className="row">
              <div className="col">
                <LabelValuePair
                  label={t('DeviceCard.LastReading', { ns: 'molecules' })}
                  value={displayString(energyMeter.latestMeasurements[0].metricType, energyMeter.latestMeasurements[0]?.value)}
                  size="xs"
                  valueStyle={{ fontWeight: 500 }}
                />
              </div>
            </div>
          }

          <Divider />
        </div>
      ))}
    </>
  );
};

export default DeviceCardEnergyMeters;

const Divider = styled.div`
  margin: 14px 0;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
`;