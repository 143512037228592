import { Feature } from '@shared/api/models/Feature/Feature';
import { Tenant } from '@shared/api/models/Tenant/Tenant';
import { useState } from 'react';
import styled from 'styled-components';
import TenantLogo from './TenantLogo';
import { Card } from '@shared/components/molecules/Card/Card';
import TenantFeatureList from './TenantFeatureList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface ITenantListItem {
  tenant: Tenant;
  features: Feature[];
  refresh: () => void;
}
const TenantListItem = ({ tenant, features, refresh }: ITenantListItem) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Card noPadding>
        <Header onClick={() => setIsOpen(prevState => !prevState)}>
          <TenantLogo tenant={tenant} />

          {tenant.name}

          <Icon
            icon={isOpen ? solid('chevron-up') : solid('chevron-down')}
          />
        </Header>
      </Card>

      <Collapsible isOpen={isOpen}>
        <TenantFeatureList
          features={features}
          tenant={tenant}
          refresh={refresh}
        />
      </Collapsible>
    </div>
  );
};

export default TenantListItem;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  height: 45px;
  padding: 10px 15px;
  border-left: 5px solid ${p => p.theme.palette.primary};
  border-radius: 5px 0 0 5px;
`;

const Collapsible = styled.div<{ isOpen: boolean }>`
  display: ${p => p.isOpen ? 'block' : 'none'};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  padding: 5px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  margin-left: auto;
  transition: all 150ms ease;

  ${Header}:hover & {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;