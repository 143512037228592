import BuildingDeleteCommand from '@settings/api/queries/Buildings/BuildingDeleteCommand';
import BuildingImageDeleteCommand from '@settings/api/queries/Buildings/BuildingImageDeleteCommand';
import BuildingImageUploadCommand from '@settings/api/queries/Buildings/BuildingImageUploadCommand';
import BuildingUpdateCommand from '@settings/api/queries/Buildings/BuildingUpdateCommand';
import SiteMetadataGetBySiteIdQuery from '@settings/api/queries/SiteMetadata/SiteMetadataGetBySiteIdQuery';
import BulkSpaceCreateCommand from '@settings/api/queries/Spaces/BulkSpaceCreateCommand';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Building } from '@shared/api/models/Building/Building';
import { MonthlyWeighting } from '@shared/api/models/Building/BuildingMetadata';
import { Site } from '@shared/api/models/Site/Site';
import { ClustersAndSpaces } from '@shared/api/models/Space/ClustersAndSpaces';
import { BlobGetSasUriQuery } from '@shared/api/queries/Blob/BlobGetSasUriQuery';
import { Button } from '@shared/components/atoms/Button/Button';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { Select } from '@shared/components/atoms/Select/Select';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { BlobFileUploadArea } from '@shared/components/molecules/BlobFileUploadArea/BlobFileUploadArea';
import { Card } from '@shared/components/molecules/Card/Card';
import { FileDropzone } from '@shared/components/molecules/FileDropzone/FileDropzone';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { RouterPrompt } from '@shared/components/navigation/RouterPrompt/RouterPrompt';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { useModal } from '@shared/hooks/useModal';
import { currencyDecimalName } from '@shared/utils/CurrencyUtils';
import { downloadFile } from '@shared/utils/DownloadUtils';
import { getCountries } from '@shared/utils/LocalisationUtils';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { isEmpty, isEqual, isMatch } from 'lodash';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import { CsvObject, UnitScheduleForm, breakUpCsvObjectArrayByFloor, downloadUpdatedTableDataAsCSV, mapStringArrayToObject, parseCsv, trimAndSplitCsvLines } from '../CsvImport/CsvUtils';
import EditableTable from '../CsvImport/EditableTable';
import MonthlyWeightingInput from './MonthlyWeightingInput';

const countriesArray = getCountries();

const masonryBreakpointColumns = {
  default: 2,
  1600: 1
};

interface IBuildingEdit {
  site: Site;
  building: Building;
  onBuildingUpdate: (building: Building) => void;
}

type FormValues = {
  name: string,
  email: string,
  phoneNumber?: string,
  assetArea?: number,
  address: {
    addressLine1: string,
    addressLine2?: string,
    city: string,
    region?: string,
    country: string,
    postCode: string,
  },
  buildingMetadata: {
    epc?: string,
    gresb?: number,
    fitwel?: number,
    breeam?: number,
    electricityPricePerKwh?: number,
    gasPricePerM3?: number,
    heatingPricePerKwh?: number,
    waterPricePerM3?: number,
    targetCarbon?: number,
    baselineCarbon?: number,
    electricityCarbonIntensity?: number
    gasCarbonIntensity?: number
    heatingCarbonIntensity?: number,
    electricityConsumptionTarget?: number,
    gasConsumptionTarget?: number,
    heatingConsumptionTarget?: number,
    waterConsumptionTarget?: number,
    electricityConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
    gasConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
    heatingConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
    waterConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
    waterCarbonIntensity?: number,
    wasteWaterCarbonIntensity?: number,
    wasteWaterProportion?: number,
    wasteWaterPricePerM3?: number
  }
}

const BuildingEdit = ({ site, building, onBuildingUpdate }: IBuildingEdit) => {
  const { t } = useTranslation(['settingsAsset']);
  const { localisation } = useLocalisation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { execute } = useApi();
  const { getUnit, toLocale, fromLocale } = useLocalisation();
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: refModal } = useModal({});
  const [csvFile, setCsvFile] = useState<File>();
  const { file, fileName, fileHasChanged, fileToBeDeleted, handleFileChange, handleFileDelete, resetFile } = useFileHandler(building.buildingImageId);
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteConfirmationDialog, ref: deleteDialogRef } = useModal({});
  const [editMode, setEditMode] = useState(false);
  const [useSiteAddress, setUseSiteAddress] = useState(building.useSiteAddress);
  const { data: siteMetadata } = useApiState({
    query: site && new SiteMetadataGetBySiteIdQuery(site.id.toString())
  }, [site]);
  const decimalName = siteMetadata?.currency != null ? currencyDecimalName(siteMetadata?.currency, t) : t('GBPDecimal', { ns: 'common' });
  const [csvObject, setCsvObject] = useState<CsvObject[] | undefined>();
  const { isOpen: csvPreviewModalIsOpen, toggle: toggleCsvPreviewModal, ref: refCsvPreviewModal } = useModal({});
  const { register, reset, handleSubmit, setValue, formState: { errors }, control } = useForm<FormValues>({
    defaultValues: {
      name: building.name,
      email: building.email,
      phoneNumber: building.phoneNumber,
      assetArea: toLocale(AltUnits.Area, building.assetArea ?? 0, { round: 2 }),
      address: {
        addressLine1: building.address.addressLine1,
        addressLine2: building.address.addressLine2,
        city: building.address.city,
        region: building.address.region,
        country: building.address.country,
        postCode: building.address.postCode,
      },
      buildingMetadata: {
        breeam: building.buildingMetadata?.breeam,
        epc: building.buildingMetadata?.epc,
        fitwel: building.buildingMetadata?.fitwel,
        gresb: building.buildingMetadata?.gresb,
        electricityConsumptionTarget: building.buildingMetadata?.electricityConsumptionTarget,
        gasConsumptionTarget: building.buildingMetadata?.gasConsumptionTarget,
        heatingConsumptionTarget: building.buildingMetadata?.heatingConsumptionTarget,
        waterConsumptionTarget: building.buildingMetadata?.waterConsumptionTarget,
        electricityConsumptionTargetMonthlyWeighting: building.buildingMetadata?.electricityConsumptionTargetMonthlyWeighting,
        gasConsumptionTargetMonthlyWeighting: building.buildingMetadata?.gasConsumptionTargetMonthlyWeighting,
        heatingConsumptionTargetMonthlyWeighting: building.buildingMetadata?.heatingConsumptionTargetMonthlyWeighting,
        waterConsumptionTargetMonthlyWeighting: building.buildingMetadata?.waterConsumptionTargetMonthlyWeighting,
        electricityPricePerKwh: building.buildingMetadata?.electricityPricePerKwh,
        gasPricePerM3: building.buildingMetadata?.gasPricePerM3,
        heatingPricePerKwh: building.buildingMetadata?.heatingPricePerKwh,
        waterPricePerM3: building.buildingMetadata?.waterPricePerM3,
        targetCarbon: toLocale(AltUnits.CO2WeightArea, building.buildingMetadata?.targetCarbon ?? 0, { round: 4 }),
        baselineCarbon: toLocale(AltUnits.CO2WeightArea, building.buildingMetadata?.baselineCarbon ?? 0, { round: 4 }),
        electricityCarbonIntensity: toLocale(AltUnits.CO2WeightIntensity, building.buildingMetadata?.electricityCarbonIntensity ?? 0, { round: 4 }),
        gasCarbonIntensity: toLocale(AltUnits.CO2SubWeightArea, building.buildingMetadata?.gasCarbonIntensity ?? 0, { round: 4 }),
        heatingCarbonIntensity: toLocale(AltUnits.CO2WeightIntensity, building.buildingMetadata?.heatingCarbonIntensity ?? 0, { round: 4 }),
        waterCarbonIntensity: toLocale(AltUnits.CO2SubWeightArea, building.buildingMetadata?.waterCarbonIntensity ?? 0, { round: 4 }),
        wasteWaterCarbonIntensity: toLocale(AltUnits.CO2SubWeightArea, building.buildingMetadata?.wasteWaterCarbonIntensity ?? 0, { round: 4 }),
        wasteWaterProportion: building.buildingMetadata?.wasteWaterProportion,
        wasteWaterPricePerM3: building.buildingMetadata?.wasteWaterPricePerM3,
      }
    }
  });
  const { tenant } = useTenantContext();

  const formMethods = useForm<UnitScheduleForm>();

  const updateAddress = (siteAddress: boolean) => {
    setUseSiteAddress(siteAddress);

    if (siteAddress) {
      setValue('address.addressLine1', site.address.addressLine1);
      setValue('address.addressLine2', site.address.addressLine2);
      setValue('address.city', site.address.city);
      setValue('address.region', site.address.region);
      setValue('address.region', site.address.country);
      setValue('address.postCode', site.address.postCode);
    } else {
      setValue('address.addressLine1', building.address.addressLine1);
      setValue('address.addressLine2', building.address.addressLine2);
      setValue('address.city', building.address.city);
      setValue('address.region', building.address.region);
      setValue('address.region', building.address.country);
      setValue('address.postCode', building.address.postCode);
    }
  };

  const exitEditMode = () => {
    setEditMode(false)
    reset();
    resetFile();
    setUseSiteAddress(building.useSiteAddress);
  };

  const onSave: SubmitHandler<FormValues> = async data => {
    setEditMode(false);
    let isMounted = true;

    const modifiedBuilding: Building = {
      ...building,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      useSiteAddress: useSiteAddress,
      address: data.address,
      assetArea: fromLocale(AltUnits.Area, data.assetArea ?? 0),
      buildingMetadata: {
        breeam: data.buildingMetadata.breeam,
        epc: data.buildingMetadata.epc,
        fitwel: data.buildingMetadata.fitwel,
        gresb: data.buildingMetadata.gresb,
        electricityConsumptionTarget: data.buildingMetadata.electricityConsumptionTarget,
        gasConsumptionTarget: data.buildingMetadata.gasConsumptionTarget,
        heatingConsumptionTarget: data.buildingMetadata.heatingConsumptionTarget,
        waterConsumptionTarget: data.buildingMetadata.waterConsumptionTarget,
        electricityConsumptionTargetMonthlyWeighting: data.buildingMetadata.electricityConsumptionTargetMonthlyWeighting,
        gasConsumptionTargetMonthlyWeighting: data.buildingMetadata.gasConsumptionTargetMonthlyWeighting,
        heatingConsumptionTargetMonthlyWeighting: data.buildingMetadata.heatingConsumptionTargetMonthlyWeighting,
        waterConsumptionTargetMonthlyWeighting: data.buildingMetadata.waterConsumptionTargetMonthlyWeighting,
        electricityPricePerKwh: data.buildingMetadata.electricityPricePerKwh,
        gasPricePerM3: data.buildingMetadata.gasPricePerM3,
        heatingPricePerKwh: data.buildingMetadata.heatingPricePerKwh,
        waterPricePerM3: data.buildingMetadata.waterPricePerM3,
        targetCarbon: fromLocale(AltUnits.CO2WeightArea, data.buildingMetadata.targetCarbon ?? 0, { round: 4 }),
        baselineCarbon: fromLocale(AltUnits.CO2WeightArea, data.buildingMetadata.baselineCarbon ?? 0, { round: 4 }),
        gasCarbonIntensity: fromLocale(AltUnits.CO2SubWeightArea, data.buildingMetadata.gasCarbonIntensity ?? 0, { round: 4 }),
        heatingCarbonIntensity: fromLocale(AltUnits.CO2WeightIntensity, data.buildingMetadata.heatingCarbonIntensity ?? 0, { round: 4 }),
        waterCarbonIntensity: fromLocale(AltUnits.CO2SubWeightArea, data.buildingMetadata.waterCarbonIntensity ?? 0, { round: 4 }),
        wasteWaterCarbonIntensity: fromLocale(AltUnits.CO2SubWeightArea, data.buildingMetadata.wasteWaterCarbonIntensity ?? 0, { round: 4 }),
        wasteWaterProportion: data.buildingMetadata.wasteWaterProportion,
        wasteWaterPricePerM3: data.buildingMetadata.wasteWaterPricePerM3,
        electricityCarbonIntensity: fromLocale(AltUnits.CO2WeightIntensity, data.buildingMetadata.electricityCarbonIntensity ?? 0, { round: 4 })
      }
    };

    if (!isMatch(building, modifiedBuilding)) {
      const buildingDto = await execute({
        query: new BuildingUpdateCommand(modifiedBuilding),
        pendingMessage: t('Buildings.BuildingEditing.Saving', { ns: 'settingsAsset' }),
        successMessage: t('Buildings.BuildingEditing.ChangesSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Buildings.BuildingEditing.UpdateFailed', { ns: 'settingsAsset' })
      });
      isMounted = buildingDto !== undefined;
    }

    if (fileHasChanged && file) {
      const res = await execute({
        query: new BuildingImageUploadCommand(file, building.id),
        successMessage: t('Buildings.BuildingEditing.ImageSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Buildings.BuildingEditing.ImageUploadFailed', { ns: 'settingsAsset' })
      });
      modifiedBuilding.buildingImageId = res?.blobName;
      isMounted = res !== undefined;
    } else if (fileToBeDeleted && building.buildingImageId) {
      const res = await execute({
        query: new BuildingImageDeleteCommand(building.buildingImageId, building.id),
        successMessage: t('Buildings.BuildingEditing.ImageDeleted', { ns: 'settingsAsset' }),
        errorMessage: t('Buildings.BuildingEditing.ImageDeleteFailed', { ns: 'settingsAsset' })
      });
      modifiedBuilding.buildingImageId = undefined;
      isMounted = res !== undefined;
    }

    if (!isMounted) {
      return;
    }

    onBuildingUpdate(modifiedBuilding);
  }

  const onDelete = async () => {
    await execute({
      query: new BuildingDeleteCommand(building.id),
      successMessage: t('Buildings.BuildingEditing.BuildingDeleted', { ns: 'settingsAsset' }),
      errorMessage: t('Buildings.BuildingEditing.DeleteFailed', { ns: 'settingsAsset' })
    });

    navigate('../../settings/site/buildings');
  }

  const handleFiles = (files: File[]) => {
    if (files[0]) {
      setCsvFile(files[0]);
    }
    else {
      setCsvFile(undefined);
    }
  };

  const handlePreviewFile = () => {
    if (csvFile) {
      parseCsv(csvFile)
        .then(async (rawCsvData) => {
          const trimmedCsvData = trimAndSplitCsvLines(rawCsvData);
          const mappedCsvData = mapStringArrayToObject(trimmedCsvData);

          setCsvObject(mappedCsvData);
          toggleCsvPreviewModal();
        })
        .catch(() => {
          return;
        });
    }
  };

  const onClickDownloadTemplate = async () => {
    const blobUri = await execute({
      query: new BlobGetSasUriQuery(
        'UnitScheduleBulkImport.xlsx',
        BlobStorageContainerType.Shared
      )
    });

    if (blobUri) {
      downloadFile(blobUri, 'UtopiConnect-UnitScheduleBulkImportTemplate.xlsx');
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: handleFiles,
    accept: '.csv'
  });

  const importSpaces = async (formData: UnitScheduleForm): Promise<void> => {
    const clustersAndSpacesByFloor = breakUpCsvObjectArrayByFloor(formData.data)

    const promises: Promise<ClustersAndSpaces | undefined>[] = [];

    for (const spacesAndClustersByFloor of clustersAndSpacesByFloor) {
      if (!building.id) {
        return;
      }

      const spaceFormValues = spacesAndClustersByFloor.spacesAndClusters.spaces
      const floorNumber = spacesAndClustersByFloor.floorName;
      const cluster = spacesAndClustersByFloor.spacesAndClusters.clusters;

      promises.push(
        execute({
          query: new BulkSpaceCreateCommand(spaceFormValues, building.id, floorNumber, cluster),
          pendingMessage: t('Buildings.BuildingEditing.ImportPending', { ns: 'settingsAsset' }),
        })
      )
    }

    /**
     * Makes sure we only get a single Success or Error message per import
    */
    const outcomes: string[] = [];

    const processPromises = async (promises: Promise<ClustersAndSpaces | undefined>[]) => {
      for (const promise of promises) {
        try {
          const result = await promise;
          if (result !== undefined) {
            outcomes.push('Success')
          } else {
            outcomes.push('Error')
          }
        } catch (error) {
          outcomes.push('Error')
        }
      }
    }

    await processPromises(promises);

    if (outcomes.some(value => value === 'Error')) {
      toast.error(t('Buildings.Spaces.SpaceManagement.CsvPreview.CsvImportError', { ns: 'settingsAsset' }));
    } else {
      toast.success(t('Buildings.Spaces.SpaceManagement.CsvPreview.CsvImportSuccess', { ns: 'settingsAsset' }));
    }

    if (!isEqual(formData.data, csvObject)) {
      downloadCsvIfChangesHaveBeenMadeToForm(formData)
    }

    toggleCsvPreviewModal()
  };

  const downloadCsvIfChangesHaveBeenMadeToForm = (formData: UnitScheduleForm): void => {
    const currentDateTimeString: string = new Date().toLocaleString('en-GB');

    const fileName = `${tenant.name}_${site.name}_${building.name}_Unit_Schedule_Import_${currentDateTimeString}`;

    downloadUpdatedTableDataAsCSV(fileName, formData)

    toast.success(t('Buildings.Spaces.SpaceManagement.CsvPreview.DownloadSuccess', { ns: 'settingsAsset' }));
  };

  return (
    <>
      <RouterPrompt when={editMode} />

      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('Buildings.BuildingEditing.DeleteBuildingConfirm', { ns: 'settingsAsset' })}
        confirmButton={t('Buildings.BuildingEditing.Delete', { ns: 'settingsAsset' })}
        onCancel={toggleDeleteConfirmationDialog}
        onConfirm={onDelete}
      />

      <FlexRow>
        {editMode &&
          <>
            <Button
              tertiary
              label={t('Cancel', { ns: 'common' })}
              onClick={() => exitEditMode()}
              color={theme.palette.red}
            />

            <Button
              label={t('Buildings.BuildingEditing.Save', { ns: 'settingsAsset' })}
              onClick={handleSubmit(onSave)}
              disabled={!isEmpty(errors)}
            />
          </>
        }

        {!editMode &&
          <>
            <Button
              tertiary
              label={t('Buildings.BuildingEditing.Delete', { ns: 'settingsAsset' })}
              onClick={toggleDeleteConfirmationDialog}
              color={theme.palette.red}
            />

            <Button
              tertiary
              label={t('Buildings.BuildingEditing.ImportUnitSchedule', { ns: 'settingsAsset' })}
              onClick={toggleModal}
            />

            <Button
              label="Edit"
              onClick={() => setEditMode(true)}
            />
          </>
        }
      </FlexRow>

      <InteractiveDialog
        modalRef={refModal}
        isOpen={modalIsOpen}
        hide={toggleModal}
        title={t('Buildings.BuildingEditing.ImportUnitSchedule', { ns: 'settingsAsset' })}
        confirmButton={{
          label: t('Buildings.BuildingEditing.Preview', { ns: 'settingsAsset' }),
          onClick: handlePreviewFile,
          disabled: csvFile === undefined,
        }}
        cancelButton={{
          label: t('Cancel', { ns: 'common' }),
          onClick: toggleModal
        }}
        content={
          <>
            <SubHeader>
              {t('Buildings.BuildingEditing.DownloadTemplateHeader', { ns: 'settingsAsset' })}
            </SubHeader>
            <p>
              {t('Buildings.BuildingEditing.DownloadTemplateText', { ns: 'settingsAsset' })}
            </p>

            <ModalFlexRow>
              <Button
                label={t('Buildings.BuildingEditing.Download', { ns: 'settingsAsset' })}
                onClick={onClickDownloadTemplate}
              />
            </ModalFlexRow>

            <SubHeader>
              {t('Buildings.BuildingEditing.UploadUnitScheduleHeader', { ns: 'settingsAsset' })}
            </SubHeader>
            <p>
              {t('Buildings.BuildingEditing.UploadUnitScheduleText', { ns: 'settingsAsset' })}
            </p>

            <FileDropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              fileName={csvFile?.name}
              dimText={csvFile ? '' : t('Buildings.BuildingEditing.ImportCSVFile', { ns: 'settingsAsset' })}
              containerStyle={{ height: '100px', width: 'fill' }}
              onDelete={() => setCsvFile(undefined)}
            />
          </>
        }
      />

      <InteractiveDialog
        modalRef={refCsvPreviewModal}
        isOpen={csvPreviewModalIsOpen}
        width='1200px'
        hide={toggleCsvPreviewModal}
        title={t('Buildings.Spaces.SpaceManagement.CsvPreview.TableTitle', { ns: 'settingsAsset' })}
        confirmButton={{
          label: t('Buildings.BuildingEditing.Import', { ns: 'settingsAsset' }),
          onClick: formMethods.handleSubmit(importSpaces),
          disabled: formMethods.formState.isSubmitting,
        }}
        cancelButton={{
          label: t('Cancel', { ns: 'common' }),
          onClick: toggleCsvPreviewModal
        }}
        content={
          <FormProvider {...formMethods}>
            <EditableTable
              data={csvObject ?? []}
            />
          </FormProvider>
        }
      />

      <Form>
        <Masonry
          breakpointCols={masonryBreakpointColumns}
          className="building-edit-masonry-grid"
          columnClassName="building-edit-masonry-grid_column"
        >
          <Card cardTitle="General">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.Name', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('name', { required: t('Buildings.BuildingEditing.FieldRequired', { ns: 'settingsAsset' }) })} readOnly={!editMode} />
                  <ErrorMessage>{errors.name?.message}</ErrorMessage>
                </div>
                <div className="col-auto">
                  <Label>{t('Buildings.BuildingEditing.UseSiteAddress', { ns: 'settingsAsset' })}</Label>
                  <Switch
                    checked={useSiteAddress}
                    onChange={() => updateAddress(!useSiteAddress)}
                    styles={{ marginLeft: '2px' }}
                    disabled={!editMode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.AddressLine1', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('address.addressLine1', { required: t('Buildings.BuildingEditing.FieldRequired', { ns: 'settingsAsset' }) })}
                    readOnly={!editMode}
                    disabled={useSiteAddress}
                  />
                  <ErrorMessage>{errors.address?.addressLine1?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.AddressLine2', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('address.addressLine2', { setValueAs: value => nullIfEmptyString(value) })}
                    readOnly={!editMode}
                    disabled={!editMode || useSiteAddress} />
                  <ErrorMessage>{errors.address?.addressLine2?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.City', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('address.city', { required: t('Buildings.BuildingEditing.FieldRequired', { ns: 'settingsAsset' }) })}
                    readOnly={!editMode}
                    disabled={useSiteAddress}
                  />
                  <ErrorMessage>{errors.address?.city?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.Region', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('address.region', { setValueAs: value => nullIfEmptyString(value) })}
                    readOnly={!editMode}
                    disabled={!editMode || useSiteAddress}
                  />
                  <ErrorMessage>{errors.address?.region?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.Country', { ns: 'settingsAsset' })}</Label>
                  <Controller
                    control={control}
                    name="address.country"
                    rules={{ required: t('Buildings.BuildingEditing.FieldRequired', { ns: 'settingsAsset' }) }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={countriesArray.find(country => country.label === value)}
                        isSearchable={true}
                        onChange={(selected) => selected && onChange(selected.label)}
                        options={countriesArray}
                        readOnly={!editMode}
                        isDisabled={useSiteAddress}
                      />
                    )}
                  />
                  <ErrorMessage>{errors.address?.country?.message}</ErrorMessage>
                </div>
                <div className="col-md-2">
                  <Label>{localisation.localPostcodeName}</Label>
                  <Input
                    {...register('address.postCode', { required: t('Buildings.BuildingEditing.FieldRequired', { ns: 'settingsAsset' }) })}
                    readOnly={!editMode}
                    disabled={useSiteAddress}
                  />
                  <ErrorMessage>{errors.address?.postCode?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.Phone', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('phoneNumber', { setValueAs: value => nullIfEmptyString(value) })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.phoneNumber?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.Email', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('email', {
                      setValueAs: value => nullIfEmptyString(value),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t('Buildings.BuildingEditing.EmailValidation', { ns: 'settingsAsset' })
                      }
                    })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.email?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingEditing.AssetArea', {
                    ns: 'settingsAsset',
                    unit: getUnit(AltUnits.Area)
                  })}</Label>
                  <Input
                    {...register('assetArea', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Buildings.BuildingEditing.AssetAreaValidation', {
                          ns: 'settingsAsset',
                          unit: getUnit(AltUnits.Area)
                        })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Buildings.BuildingEditing.AssetAreaPlaceholder', {
                      ns: 'settingsAsset',
                      unit: getUnit(AltUnits.Area)
                    })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.assetArea?.message}</ErrorMessage>
                </div>
              </div>
            </div>
          </Card>

          <Card cardTitle="Utilities">
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.ElectricityPrice', { decimalName: decimalName })}</Label>
                <Input
                  {...register('buildingMetadata.electricityPricePerKwh', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.ElectricityPriceValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.EnergyPricePlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.electricityPricePerKwh?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.GasPrice', { decimalName: decimalName, unit: getUnit(MetricType.GasVolume) })}</Label>
                <Input
                  {...register('buildingMetadata.gasPricePerM3', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.GasPriceValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.GasPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.GasVolume) })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.gasPricePerM3?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.HeatingPrice', { decimalName: decimalName })}</Label>
                <Input
                  {...register('buildingMetadata.heatingPricePerKwh', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.HeatingPriceValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.EnergyPricePlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.heatingPricePerKwh?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.WaterPrice', { decimalName: decimalName, unit: getUnit(MetricType.WaterVolume) })}</Label>
                <Input
                  {...register('buildingMetadata.waterPricePerM3', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.WaterPriceValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.WaterPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.waterPricePerM3?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.WasteWaterPrice', { decimalName: decimalName, unit: getUnit(MetricType.WaterVolume) })}</Label>
                <Input
                  {...register('buildingMetadata.wasteWaterPricePerM3', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.WasteWaterPriceValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.WaterPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.wasteWaterPricePerM3?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.WasteWaterProportion', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('buildingMetadata.wasteWaterProportion', {
                    min: {
                      value: 1,
                      message: t('Buildings.BuildingEditing.WasteWaterProportionMin', { ns: 'settingsAsset' })
                    },
                    max: {
                      value: 99,
                      message: t('Buildings.BuildingEditing.WasteWaterProportionMax', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.WasteWaterProportionPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.wasteWaterProportion?.message}</ErrorMessage>
              </div>
            </div>
          </Card>

          <Card cardTitle={t('Buildings.BuildingEditing.CertificationsAndRatings', { ns: 'settingsAsset' })}>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.EPCRating', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('buildingMetadata.epc', {
                    setValueAs: value => nullIfEmptyString(value),
                    pattern: {
                      value: /[A-G]/,
                      message: t('Buildings.BuildingEditing.RatingValidation', { ns: 'settingsAsset' })
                    }
                  }
                  )}
                  placeholder={t('Buildings.BuildingEditing.RatingPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.epc?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.GRESB', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('buildingMetadata.gresb', {
                    pattern: {
                      value: /\d{1,3}/,
                      message: t('Buildings.BuildingEditing.GRESBValidation', { ns: 'settingsAsset' })
                    },
                    max: {
                      value: 100,
                      message: t('Buildings.BuildingEditing.GRESBValidation', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 1,
                      message: t('Buildings.BuildingEditing.GRESBValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.GRESBPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.gresb?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.Fitwel', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('buildingMetadata.fitwel', {
                    pattern: {
                      value: /\d{2,3}/,
                      message: t('Buildings.BuildingEditing.FitwelValidation', { ns: 'settingsAsset' })
                    },
                    max: {
                      value: 144,
                      message: t('Buildings.BuildingEditing.FitwelValidation', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 90,
                      message: t('Buildings.BuildingEditing.FitwelValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.FitwelPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.fitwel?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.BREEAM', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('buildingMetadata.breeam', {
                    pattern: {
                      value: /\d{1,3}/,
                      message: t('Buildings.BuildingEditing.BREEAMValidation', { ns: 'settingsAsset' })
                    },
                    max: {
                      value: 100,
                      message: t('Buildings.BuildingEditing.BREEAMValidation', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 1,
                      message: t('Buildings.BuildingEditing.BREEAMValidation', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.BREEAMPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.breeam?.message}</ErrorMessage>
              </div>
            </div>
          </Card>

          <Card cardTitle={t('Buildings.BuildingEditing.Emissions', { ns: 'settingsAsset' })}>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.BaselineCarbonEmission', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}</Label>
                <Input
                  {...register('buildingMetadata.baselineCarbon', {
                    pattern: {
                      value: /[0-9]/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.BaselineCarbonEmissionPlaceholder', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.baselineCarbon?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.TargetCarbonEmission', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}</Label>
                <Input
                  {...register('buildingMetadata.targetCarbon', {
                    pattern: {
                      value: /[0-9]/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.TargetCarbonEmissionPlaceholder', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.targetCarbon?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.ElectricityCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightIntensity) })}</Label>
                <Input
                  {...register('buildingMetadata.electricityCarbonIntensity', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.ElectricityCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.electricityCarbonIntensity?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.HeatingCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightIntensity) })}</Label>
                <Input
                  {...register('buildingMetadata.heatingCarbonIntensity', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.HeatingCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.heatingCarbonIntensity?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.WaterCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
                <Input
                  {...register('buildingMetadata.waterCarbonIntensity', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.WaterCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.waterCarbonIntensity?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.WasteWaterCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
                <Input
                  {...register('buildingMetadata.wasteWaterCarbonIntensity', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.WasteWaterCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.wasteWaterCarbonIntensity?.message}</ErrorMessage>
              </div>

            </div>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.GasCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
                <Input
                  {...register('buildingMetadata.gasCarbonIntensity', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  placeholder={t('Buildings.BuildingEditing.GasCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.gasCarbonIntensity?.message}</ErrorMessage>
              </div>
            </div>
          </Card>

          <div />

          <Card cardTitle={t('Buildings.BuildingEditing.YearlyConsumptionTargets', { ns: 'settingsAsset' })}>
            <div className="row" style={{ marginBottom: '15px' }}>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.Electricity', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('buildingMetadata.electricityConsumptionTarget', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.electricityConsumptionTarget?.message}</ErrorMessage>
              </div>
            </div>

            <div style={{ marginBottom: '35px' }}>
              <Controller
                control={control}
                name={'buildingMetadata.electricityConsumptionTargetMonthlyWeighting'}
                render={({ field: { onChange, value } }) => (
                  <MonthlyWeightingInput
                    weighting={value}
                    onChange={(weighting) => onChange(weighting)}
                    readOnly={!editMode}
                  />
                )}
              />
            </div>

            <div className="row" style={{ marginBottom: '15px' }}>
              <div className="col-md-6">
                <Label>{t('Buildings.BuildingEditing.Heating', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('buildingMetadata.heatingConsumptionTarget', {
                    pattern: {
                      value: /^\d+\.?\d{0,2}$/,
                      message: t('Buildings.BuildingEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                    },
                    min: {
                      value: 0,
                      message: t('Buildings.BuildingEditing.MustNotBeNegative', { ns: 'settingsAsset' })
                    },
                    setValueAs: value => stringToNumber(value)
                  }
                  )}
                  type="number"
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.buildingMetadata?.heatingConsumptionTarget?.message}</ErrorMessage>
              </div>
            </div>

            <div style={{ marginBottom: '25px' }}>
              <Controller
                control={control}
                name={'buildingMetadata.heatingConsumptionTargetMonthlyWeighting'}
                render={({ field: { onChange, value } }) => (
                  <MonthlyWeightingInput
                    weighting={value}
                    onChange={(weighting) => onChange(weighting)}
                    readOnly={!editMode}
                  />
                )}
              />
            </div>
          </Card>


          <Card cardTitle={t('Buildings.BuildingEditing.Image', { ns: 'settingsAsset' })}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <BlobFileUploadArea
                    blobName={fileName}
                    mainText={t('Buildings.BuildingEditing.UploadImage', { ns: 'settingsAsset' })}
                    dimText={'JPEG, PNG, SVG, CAD'}
                    acceptedTypes={['image/*']}
                    onFileChange={handleFileChange}
                    onFileDelete={handleFileDelete}
                    readOnly={!editMode}
                  />
                </div>
              </div>
            </div>
          </Card>

        </Masonry>
      </Form>
    </>
  );
}

export default BuildingEdit;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-bottom: 20px;
`;

const ModalFlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const SubHeader = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${p => p.theme.palette.text.fair};
  padding-bottom: 5px;
`;