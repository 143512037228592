export const env = {
  version: process.env.REACT_APP_VERSION_NUMBER ?? '1.0.0',
  app: {
    apiUrl: process.env.REACT_APP_API_URL ?? 'http://localhost:5001/api',
    alertingFunctionUrl: process.env.REACT_APP_ALERTING_FUNCTION_URL ?? 'http://localhost:7076/',
    operatorApiUrl: process.env.REACT_APP_OPERATOR_API_URL ?? 'http://127.0.0.1:6001'
  },
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_B2C_AUTHORITY ?? '',
    scopes: process.env.REACT_APP_B2C_SCOPES ?? '',
    passwordReset: process.env.REACT_APP_B2C_PASSWORDRESET ?? '',
    updateMfa: process.env.REACT_APP_B2C_UPDATE_MFA ?? ''
  },
  apiKeys: {
    google: process.env.REACT_APP_GOOGLE_API_KEY ?? ''
  },
  deviceConfigs: {
    blobContainer: process.env.REACT_APP_DEVICE_CONFIGS_BLOB_CONTAINER ?? 'device-configs',
    filename: process.env.REACT_APP_DEVICE_CONFIGS_FILE ?? 'device-configs'
  },
  alertDelay: {
    maxDelayInDays: process.env.REACT_APP_MAX_DELAY_IN_DAYS ?? '14'
  },
  analytics: {
    mixpanelToken: process.env.REACT_APP_ANALYTICS_MIXPANEL_TOKEN ?? '',
    debugModeEnabled: process.env.REACT_APP_ANALYTICS_DEBUG_MODE_ENABLED ? process.env.REACT_APP_ANALYTICS_DEBUG_MODE_ENABLED === 'true' : false
  },
  emails: {
    contact: process.env.REACT_APP_CONTACT_EMAIL ?? ''
  },
  externalLinks: {
    privacyPolicy: 'https://static1.squarespace.com/static/627198f401545e2c828aa82a/t/6659872d32449e6df7edbf4e/1717143343157/Privacy+Policy+-+May+31st+2024.pdf'
  }
}