import ChartContainer from './ChartContainer';
import { useTranslation } from 'react-i18next';
import { ChartDataUnit, TimeScaleRange, getChartDataUnit } from '@dashboard/utils/ChartUtils';
import { ChartDataset, LineBarChartType } from '@shared/components/molecules/Charts/Chart.types';
import { SpaceWithMetricTypes } from '@dashboard/api/models/DynamicReportDataDto';
import LineBarChart from '@dashboard/components/atoms/charts/LineBarChart';
import { TimeRange } from '@dashboard/components/atoms/RangePicker/RangePicker';
import { useCallback, useEffect, useState } from 'react';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';

type PropTypes = {
  data: SpaceWithMetricTypes,
  chartType: LineBarChartType,
  onChartTypeChange: (type: LineBarChartType) => void
  timeRange?: TimeRange
}

const formatValue = (x: string, unitType: ChartDataUnit): string => {
  const modifiedValue = unitType.modifier ? unitType.modifier(x) : x
  return modifiedValue.toString();
};

const ChartGroupedBySpace = ({ data, chartType, onChartTypeChange, timeRange }: PropTypes) => {
  const { t } = useTranslation(['enums']);
  const { toLocale, getUnit, getUnitLabel } = useLocalisation();

  const [zoomPanRange, setZoomPanRange] = useState<{ min: number, max: number }>();
  const [hasBeenZoomed, setHasBeenZoomed] = useState(false);

  const cardTitle = `${data.location.spaceName} ${data.location.buildingName}`;

  const chartDatasets: ChartDataset[] = data.metricTypes.map((metricType) => {
    const dataUnit = getChartDataUnit(metricType.metricType);
    dataUnit.suffix = getUnit(metricType.metricType)
    dataUnit.label = getUnitLabel(metricType.metricType)

    return ({
      label: metricType.metricType,
      displayLabel: t(metricType.metricType, { ns: 'enums' }),
      legendSubLabel: dataUnit.label,
      dataset: metricType.metrics.map(a => ({ x: (new Date(a.measuredOn)).valueOf(), y: formatValue(toLocale(a.metricType, parseFloat(a.value)).toFixed(2), dataUnit) })),
      dataUnit: dataUnit,
      labels: metricType.metrics.map(a => (new Date(a.measuredOn)).toString()),
      color: metricType.color
    });
  });

  const timeScaleRange: TimeScaleRange | undefined = zoomPanRange ?
    {
      scaleRange: {
        min: zoomPanRange.min,
        max: zoomPanRange.max
      },
      xScaleTimeUnit: undefined,
    } : undefined

  const handleZoomReset = useCallback(() => {
    if (timeRange) {
      setZoomPanRange({ min: +timeRange.from, max: +timeRange.to });
      setHasBeenZoomed(false);
    }
  }, [timeRange]);

  useEffect(() => {
    handleZoomReset();
  }, [handleZoomReset])

  return (
    <ChartContainer
      cardTitle={cardTitle}
      chartType={chartType}
      onChartTypeChange={onChartTypeChange}
      onResetZoom={handleZoomReset}
      hasBeenZoomed={hasBeenZoomed}
    >
      <LineBarChart
        datasets={chartDatasets}
        timeScaleRange={timeScaleRange}
        type={chartType}
        onZoomPanComplete={(min: number, max: number) => { setZoomPanRange({ min: min, max: max }); setHasBeenZoomed(true) }}
        showLegend
        chartOptions={{ fillLineDisabled: true, tooltipLabelDecimalPlaces: 2 }}
        enableZoom
      />
    </ChartContainer>
  );
};

export default ChartGroupedBySpace;