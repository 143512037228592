import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useTheme } from 'styled-components';
import SiteOperatorInfo from './SiteOperatorInfo/SiteOperatorInfo';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import SiteOperatorEditModal from './SiteOperatorInfo/SiteOperatorEditModal';
import { useState } from 'react';
import { Button } from '@shared/components/atoms/Button/Button';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import ChallengeOverviewTable from './Challenges/ChallengeOverviewTable';
import { useNavigate } from 'react-router-dom';
import ResidentAppPreview from './ResidentAppPreview';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import IconWithText from '@dashboard/components/molecules/ResidentApp/Common/IconWithText';

const ResidentApp = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const navigate = useNavigate();
  const { siteOperator} = useSiteContext();
  const [showEditModal, setShowEditModal] = useState(false);

  const getTooltipOptions = (label: string, style: CSSProperties) => ({
    content: label,
    placement: TooltipPlacement.Bottom,
    fontSize: '14px',
    styles: {...style, maxWidth: 215},
    tooltipColor: theme.palette.tooltip.background.primary,
    hideTriangle: false
  });

  if(!siteOperator) {
    return <ResidentAppPreview />;
  }

  return (
    <>
      <PaddedContainer>
        <TitleWithButton 
          title={t('ResidentApp.ResidentApp', { ns: 'molecules' })} 
          button={
            <Button
              label={<IconWithText icon={regular('users')} text={t('ResidentApp.ManageUsers')} />}
              onClick={() => navigate('./manage-users')}
              style={{marginRight: '-16px'}}
              tertiary 
              disabled={!siteOperator.email}
              tooltip={
                !siteOperator
                  ? getTooltipOptions(t('ResidentApp.ManageUsersDisabledTooltip'), { padding: 10, marginRight: -25})
                  : undefined
              }
            />
          } 
        />
        <TitleWithButton
          title={t('ResidentApp.GeneralInformation')}
          titleSize="md"
          button={
            <Button
              label={<IconWithText icon={solid('pencil')} text={t('Edit', {ns: 'common'})} />}
              secondary
              onClick={() => setShowEditModal(true)}
            />
          }
          style={{ padding: '50px 0 20px' }}
        />
        <SiteOperatorInfo siteOperator={siteOperator} />

        <TitleWithButton
          title={t('ResidentApp.Challenges')}
          titleSize="md"
          style={{ padding: '35px 0 20px' }}
          button={
            <Button
              label={<IconWithText icon={solid('plus')} text={t('ResidentApp.CreateChallenge')} />}
              disabled={!siteOperator.email}
              onClick={() => navigate('./create-challenge')}
              tooltip={
                !siteOperator
                  ? getTooltipOptions(t('ResidentApp.CreateChallengeDisabledTooltip'), { padding: 10})
                  : undefined
              }
            />
          }
        />
        <ChallengeOverviewTable hasSiteOperator={!!siteOperator.email} />
      </PaddedContainer>

      <SiteOperatorEditModal
        showModal={showEditModal}
        onCloseModal={() => setShowEditModal(false)}
      />
    </>
  );
};

export default ResidentApp;