import { ClimateControlStatus } from '@shared/api/enums/ClimateControlStatus/ClimateControlStatus';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@shared/hooks/useApi';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { hasOverridenValues, submitSiteClimateControlForm, updateSiteClimateControl } from '../ClimateControlUtils';
import EmptyState from './EmptyState';
import ReviewAndActivate from './ReviewAndActivate';
import SpaceExceptions from './SpaceExceptions/SpaceExceptions';
import Stepper, { Steps } from './Stepper';
import TemperatureRanges from './TemperatureRanges/TemperatureRanges';
import { TemperatureRangeForm } from './types/TemperatureRangeForm';
import { RouteLinkAnalytics } from '@shared/types/Route';

const getButtonAnalytics = (step: number): RouteLinkAnalytics | undefined => {
  switch (step) {
    case 1:
      return { action: 'setup_complete_step1', category: 'climate_control' };
    case 2:
      return { action: 'setup_complete_step2', category: 'climate_control' };
    case 3:
      return { action: 'setup_activate', category: 'climate_control' };
  }
}

type SetupProps = {
  siteClimateControl?: SiteClimateControlDto;
  refreshSiteClimateControl: () => void;
}

const Setup = ({ siteClimateControl, refreshSiteClimateControl }: SetupProps) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const largeScreen = useMediaQuery('(max-width: 1200px)');
  const temperatureRangeFormMethods = useForm<TemperatureRangeForm>({ mode: 'onChange' });
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const { execute } = useApi();
  const { fromLocale } = useLocalisation();

  const exceptions = useMemo(() => (
    siteClimateControl?.spaceClimateControls.filter((exception: SpaceClimateControlDto) => hasOverridenValues(exception))
  ), [siteClimateControl?.spaceClimateControls]);

  if (!siteClimateControl) {
    return null;
  }

  const steps: Steps[] = [{
    step: 1,
    title: t('ClimateControl.TemperatureRanges', { ns: 'molecules' })
  }, {
    step: 2,
    title: t('ClimateControl.SpaceExceptions', { ns: 'molecules' })
  }, {
    step: 3,
    title: t('ClimateControl.ReviewAndActivate', { ns: 'molecules' })
  }]

  const getButtonText = () => {
    if (activeStep === 2) {
      return exceptions?.length === 0 ? t('Skip', { ns: 'common' }) : t('Next', { ns: 'common' })
    } else if (activeStep === 3) {
      return t('Activate', { ns: 'common' })
    } else {
      return t('Next', { ns: 'common' })
    }
  }

  const onSave = async (): Promise<void> => {
    if (savingInProgress) {
      return;
    }

    setSavingInProgress(true);
    try {
      switch (activeStep) {
        case 1:
          await submitSiteClimateControlForm(siteClimateControl, temperatureRangeFormMethods.getValues(), temperatureRangeFormMethods.formState, fromLocale, t, execute);
          setActiveStep(prevState => prevState + 1);
          break;
        case 2:
          setActiveStep(prevState => prevState + 1);
          break;
        case 3:
          await updateSiteClimateControl({ ...siteClimateControl, status: ClimateControlStatus.Enabled }, t, execute);
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      refreshSiteClimateControl();
      setSavingInProgress(false);
    }
  };

  return (
    <ResponsiveContainer>
      {activeStep === 0 ?
        <EmptyState
          handleOnClick={() => setActiveStep(1)}
        /> :
        <>
          <HeaderContainer>
            <Title
              size='lg'
              text={t('ClimateControl.ClimateControlSetup', { ns: 'molecules' })}
              wrapperStyle={{ width: largeScreen ? '100%' : '40%' }}
            />
            <Stepper
              activeStep={activeStep}
              steps={steps}
              style={{ justifyContent: largeScreen ? 'flex-start' : 'flex-end', marginTop: largeScreen ? 30 : 0 }}
            />
          </HeaderContainer>

          {activeStep === 3 &&
            <HeaderDescription>{t('ClimateControl.ClimateControlSetupDesc', { ns: 'molecules' })}</HeaderDescription>
          }

          {activeStep === 1 &&
            <FormProvider {...temperatureRangeFormMethods}>
              <TemperatureRanges siteClimateControl={siteClimateControl} />
            </FormProvider>
          }
          {activeStep === 2 &&
            <SpaceExceptions
              siteClimateControl={siteClimateControl}
              exceptions={exceptions ?? []}
              refreshSiteClimateControl={refreshSiteClimateControl}
            />
          }
          {activeStep === 3 &&
            <ReviewAndActivate
              siteClimateControl={siteClimateControl}
              exceptions={exceptions ?? []}
              onEdit={(step) => setActiveStep(step)}
            />
          }

          <ButtonContainer firstStep={activeStep === 1}>
            {activeStep > 1 &&
              <Button
                tertiary
                label={t('Back', { ns: 'common' })}
                onClick={() => setActiveStep(prevState => prevState - 1)}
                color={theme.palette.text.medium}
              />
            }
            <NextButtonContainer>
              <Button
                tertiary
                label={t('Cancel', { ns: 'common' })}
                onClick={() => setActiveStep(0)}
                color={theme.palette.red}
              />
              <Button
                label={getButtonText()}
                onClick={onSave}
                disabled={savingInProgress}
                analytics={getButtonAnalytics(activeStep)}
              />
            </NextButtonContainer>
          </ButtonContainer>

        </>}


    </ResponsiveContainer>
  );
}

export default Setup

const ResponsiveContainer = styled.div`
  padding: 30px 35px;
  position: relative;

  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  @media print {
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const HeaderDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${p => p.theme.text.secondary};
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div<{ firstStep: boolean }>`
  display: flex;
  justify-content: ${p => p.firstStep ? 'flex-end' : 'space-between'};
  margin-top: 20px;
  gap: 20px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;