import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export class DeviceNoteCreateCommand extends PostQuery<DeviceNote> {
  deviceId: number;
  contents: string;

  constructor(deviceId: number, contents: string) {
    super();
    this.deviceId = deviceId;
    this.contents = contents;
  }

  public targetUrl(): string {
    return '/devicenotes';
  }
}