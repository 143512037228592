import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * Returns date object for the current UTC date
 */
export const getUtcDate = (): Date => {
  return new Date((new Date()).toISOString());
}

/**
 * Convert Dayjs to a UTC date (with time being 00:00:00)
 */
export const dateToUtcDate = (d: Dayjs): Date => {
  return new Date(Date.UTC(d.year(), d.month(), d.date()));
}

/**
 * Convert Dayjs to a UTC date (with time being converted as well)
 */
export const dateToUtcDateTime = (d: Dayjs): Date => {
  return new Date(Date.UTC(d.year(), d.month(), d.date(), d.hour(), d.minute(), d.second(), d.millisecond()));
}

/**
 * List of month names
 */
export const MonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

/**
 * Returns the month name
 */
export const getMonthName = (index: number): string => {
  return MonthNames[index - 1] ?? '';
}

/**
 * Returns the month index
 */
export const getMonthIndex = (name: string): number => {
  const MonthNamesAbbreviated = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return MonthNamesAbbreviated.indexOf(name);
}


/**
 * Returns the abbreviated month name
 */
export const getAbbreviatedMonthName = (index: number): string => {
  const MonthNamesAbbreviated = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return MonthNamesAbbreviated[index - 1] ?? '';
}

/**
 * Maps an abbreviated month name to the full name
 */
export const AbbreviatedToFullMonthName = (abbreviatedName: string): string => {
  const AbbreviatedToFull: { [key: string]: string } = {
    'Jan': 'January',
    'Feb': 'February',
    'Mar': 'March',
    'Apr': 'April',
    'May': 'May',
    'Jun': 'June',
    'Jul': 'July',
    'Aug': 'August',
    'Sep': 'September',
    'Oct': 'October',
    'Nov': 'November',
    'Dec': 'December',
  };

  return AbbreviatedToFull[abbreviatedName] ?? '';
}

/**
 * Maps a full month name to the abbreviated name
 */
export const FullToAbbreviatedMonthName = (abbreviatedName: string): string => {
  const FullToAbbreviated: { [key: string]: string } = {
    'January': 'Jan',
    'February': 'Feb',
    'March': 'Mar',
    'April': 'Apr',
    'May': 'May',
    'June': 'Jun',
    'July': 'Jul',
    'August': 'Aug',
    'September': 'Sep',
    'October': 'Oct',
    'November': 'Nov',
    'December': 'Dec',
  };

  return FullToAbbreviated[abbreviatedName] ?? '';
}

/**
 * Returns the day name (with index 1-7)
 */
export const getDayName = (index: number): string => {
  const DayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  return DayNames[index - 1] ?? '';
}