import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import styled from 'styled-components';
import { Title } from '@shared/components/atoms/Title/Title';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from '@shared/components/atoms/Button/Button';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import NoResultsError from '@dashboard/components/atoms/Errors/NoResultsError';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { SalesOrderItem } from '@shared/api/models/SalesOrder/SalesOrderItem';
import { useCallback, useMemo } from 'react';
import SalesOrderItemActions from './SalesOrderItemActions';

const SalesOrderManagement = () => {
  const { t } = useTranslation();
  const { salesOrder, refreshSalesOrder, loadingSalesOrder } = useSiteContext();

  const generateSalesOrderItemActions = useCallback((item: SalesOrderItem) => {
    return (
      <SalesOrderItemActions
        salesOrderItem={item}
        refreshItems={refreshSalesOrder}
      />
    )
  }, [refreshSalesOrder])

  const tableColumns: ITableColumn<SalesOrderItem>[] = useMemo(() => ([
    {
      label: t('SalesOrder.TableColumns.DeviceType', { ns: 'molecules' }),
      key: 'deviceType',
    },
    {
      label: t('SalesOrder.TableColumns.DevicesOrdered', { ns: 'molecules' }),
      key: 'numberOfOrders',
    },
    {
      label: t('SalesOrder.TableColumns.DevicesToBeInstalled', { ns: 'molecules' }),
      key: 'numberToBeInstalled',
    },
    {
      label: t('SalesOrder.TableColumns.SpareDevices', { ns: 'molecules' }),
      key: '',
      displayFormat: item => (item.numberOfOrders - item.numberToBeInstalled).toString()
    },
    {
      label: '',
      key: '',
      customElement: (item) => generateSalesOrderItemActions(item),
      width: 1,
      rightAlign: true
    }
  ]), [t, generateSalesOrderItemActions]);

  return (
    <PaddedContainer style={{ maxWidth: '100%' }}>
      <FlexContainer>
        <Title text={t('SalesOrder', { ns: 'routes' })} />
        <Link to={'new'}>
          <Button
            tertiary
            circle
            label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
          />
        </Link>

        {loadingSalesOrder &&
          <Loading
            fullWidthCentered
            message={t('SalesOrder.LoadingSalesOrder', { ns: 'molecules' })}
          />
        }
      </FlexContainer>

      {((salesOrder?.salesOrderItems && salesOrder?.salesOrderItems?.length < 1) || !salesOrder) && !loadingSalesOrder &&
        <NoResultsError
          noResultsError={{
            errorIcon: light('file-invoice'),
            errorTitle: t('SalesOrder.NoSalesOrder', { ns: 'molecules' }),
            errorSubtitle: t('SalesOrder.ClickPlusToAdd', { ns: 'molecules' })
          }}
          customIconHeight={48}
          customContainerHeight={300}
        />
      }

      {salesOrder?.salesOrderItems && salesOrder?.salesOrderItems.length > 0 && !loadingSalesOrder &&
        <Table
          columns={tableColumns}
          records={salesOrder.salesOrderItems}
          recordKey="id"
          defaultSortColumn='deviceType'
          disableSorting
          cardEffect
        />
      }
    </PaddedContainer>
  )
}

export default SalesOrderManagement;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;    
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;
