import { UtilityBillDto } from '@shared/api/models/UtilityBill/UtilityBill';
import { Button } from '@shared/components/atoms/Button/Button';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn, TableSortOrder } from '@shared/components/molecules/Table/Table.types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { Label } from '@shared/components/atoms/Form/Form';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';
import { TopBarHeight, HorizontalNavbarHeight } from '@shared/theme/designConstants';
import { includesCI } from '@shared/utils/StringUtils';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { useApiState } from '@shared/hooks/useApiState';
import SitesGetAllWithHierarchyQuery from '@settings/api/queries/Sites/SitesGetAllWithHierarchyQuery';
import { Card } from '@shared/components/molecules/Card/Card';
import EnergyMeterBillsGetAllByTenantIdQuery from '@settings/api/queries/EnergyMeters/UtilityBills/EnergyMeterBillsGetAllByTenantIdQuery';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { format } from 'date-fns';
import NoResultsError from '@dashboard/components/atoms/Errors/NoResultsError';
import { useNavigate } from 'react-router';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useModal } from '@shared/hooks/useModal';
import EnergyMeterBillDeleteCommand from '@settings/api/queries/EnergyMeters/UtilityBills/EnergyMeterBillDeleteCommand';
import { useApi } from '@shared/hooks/useApi';
import { Title } from '@shared/components/atoms/Title/Title';
import BuildingSelect from './BuildingSelect';

type UtilityTabs = {
  utilityType: EnergyMeterType,
  icon: IconDefinition,
  width: number,
  color: string
};

const UtilityConsumptionList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const navigate = useNavigate();
  const { tenant } = useTenantContext();
  const lineRef = useRef<HTMLDivElement>(null);
  const [selectedUtilityIndex, setSelectedUtilityIndex] = useState<number>(0);
  const [selectedUtilityType, setSelectedUtilityType] = useState<EnergyMeterType>(EnergyMeterType.Electricity);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const [searchString, setSearchString] = useState<string>('');
  const [filteredBills, setFilteredBills] = useState<UtilityBillDto[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
  const [billToBeDeleted, setBillToBeDeleted] = useState<UtilityBillDto | undefined>(undefined);
  const { isOpen: dialogIsOpen, toggle, ref } = useModal({});

  const { data: sites, loading: loadingSites } = useApiState({
    query: new SitesGetAllWithHierarchyQuery(),
    initialState: [],
  }, []);

  const { data: allBills, loading: loadingBills, execute: refreshBills } = useApiState({
    query: new EnergyMeterBillsGetAllByTenantIdQuery(tenant.tenantId),
    initialState: [],
  }, []);

  const utilityTabs: UtilityTabs[] = [
    { utilityType: EnergyMeterType.Electricity, icon: solid('bolt'), width: 13, color: '#FFB500' },
    { utilityType: EnergyMeterType.Gas, icon: solid('fire'), width: 15, color: '#00C851' },
    { utilityType: EnergyMeterType.Water, icon: regular('droplet'), width: 13, color: '#1B98FF' }
  ]

  useEffect(() => {
    allBills.forEach((bill) => {
      if (bill.modifiedOn.toString() === '0001-01-01T00:00:00Z') {
        bill.modifiedOn = new Date(bill.createdOn);
        bill.modifiedOn.setHours(0, 0, 0, 0);
      }
    });
  }, [allBills]);

  useEffect(() => {
    const tab = tabRefs.current[selectedUtilityIndex];

    if (tab && lineRef.current) {
      lineRef.current.style.left = tab.offsetLeft + 'px';
      lineRef.current.style.width = tab.clientWidth + 'px';
    }

    setFilteredBills(allBills.filter(bill => bill.meterType === selectedUtilityType))
  }, [selectedUtilityIndex, allBills, selectedUtilityType]);

  useEffect(() => {
    setFilteredBills(allBills.filter(bill => bill.meterType === selectedUtilityType))

    if (selectedUtilityIndex) {
      setFilteredBills(allBills.filter(bill => bill.meterType === selectedUtilityType))
    }

    if (selectedBuildings.length > 0) {
      setFilteredBills(allBills.filter(bill =>
        bill.meterType === selectedUtilityType &&
        selectedBuildings.some(building => bill.buildingName === building)
      ));
    }
  }, [selectedBuildings, selectedUtilityIndex, allBills, selectedUtilityType]);

  const deleteBill = useCallback(async () => {
    if (billToBeDeleted) {
      await execute({
        query: new EnergyMeterBillDeleteCommand(billToBeDeleted.id),
        successMessage: t('UtilityConsumption.UtilityBillManagement.BillDeleteSuccess', { ns: 'molecules' }),
        errorMessage: t('UtilityConsumption.UtilityBillManagement.BillDeleteFailure', { ns: 'molecules' }),
      });
    }

    setBillToBeDeleted(undefined);
    toggle();
    refreshBills();
  }, [billToBeDeleted, execute, t, toggle, refreshBills]);

  const handleTabClick = (currentIndex: number) => {
    setSelectedUtilityIndex(currentIndex);

    if (currentIndex === 0) {
      setSelectedUtilityType(EnergyMeterType.Electricity)
    }
    if (currentIndex === 1) {
      setSelectedUtilityType(EnergyMeterType.Gas)
    }
    if (currentIndex === 2) {
      setSelectedUtilityType(EnergyMeterType.Water)
    }
  }

  const searchFilter = useCallback((x: UtilityBillDto) => {
    return includesCI(x.siteName, searchString)
      || includesCI(x.buildingName, searchString)
      || includesCI(x.editor, searchString)
      || includesCI(x.meterNumber, searchString)
  }, [searchString]);

  const isCreatedOn = (date: Date): boolean => {
    if (Number.isNaN(parseInt(date.toString()[0]))) {
      return false;
    }
    return true;
  }

  const tableColumns: ITableColumn<UtilityBillDto>[] = useMemo(() => ([
    {
      label: t('UtilityConsumption.ListColumns.MeasuredOn', { ns: 'molecules' }),
      key: 'measuredOn',
      displayFormat: record => !record.meterReading ? format(new Date(record.periodStartDate), 'dd MMM yyyy') + '\n - ' + format(new Date(record.periodEndDate), 'dd MMM yyyy') : format(new Date(record.measuredOn), 'dd MMM yyyy'),
    },
    {
      label: t('UtilityConsumption.ListColumns.SiteName', { ns: 'molecules' }),
      key: 'siteName',
    },
    {
      label: t('UtilityConsumption.ListColumns.BuildingName', { ns: 'molecules' }),
      key: 'buildingName',
    },
    {
      label: t('UtilityConsumption.ListColumns.MeterNumber', { ns: 'molecules' }),
      key: 'meterNumber',
    },
    {
      label: t('UtilityConsumption.ListColumns.MeterReading', { ns: 'molecules' }),
      key: 'meterReading',
    },
    {
      label: t('UtilityConsumption.ListColumns.Consumption', { ns: 'molecules' }),
      key: 'consumption',
    },
    {
      label: t('UtilityConsumption.ListColumns.Editor', { ns: 'molecules' }),
      key: 'editor',
    },
    {
      label: t('UtilityConsumption.ListColumns.LastEdited', { ns: 'molecules' }),
      key: 'modifiedOn',
      displayFormat: (record) => isCreatedOn(record.modifiedOn) ? format(new Date(record.modifiedOn), 'dd MMM yyyy HH:mm') : format(new Date(record.modifiedOn), 'dd MMM yyyy'),
      sortFormat: (record) => record.modifiedOn && new Date(record.modifiedOn),
    },
    {
      key: 'actionButtons',
      customElement: record =>
        <ActionButtonWrapper>
          <Button
            tertiary
            circle
            label={<ActionIcon icon={regular('trash')} color={theme.palette.red} />}
            onClick={() => {
              setBillToBeDeleted(record)
              toggle()
            }}
          />

          <Button
            tertiary
            circle
            label={<ActionIcon icon={regular('pen-to-square')} color={theme.palette.text.weak} />}
            onClick={() => {
              navigate(`${record.id}`)
            }}
          />
        </ActionButtonWrapper>
    },
  ]), [t, navigate, theme, toggle]);

  return (
    <PaddedContainer>
      <FlexContainer>
        <Title text={t('UtilityManagement', { ns: 'routes' })} />

        <RightAligned>
          <FlexRow>
            <SearchField
              placeholder={t('UtilityConsumption.UtilityBillManagement.SearchBills', { ns: 'molecules' })}
              onSearchChange={setSearchString}
              style={{ background: theme.action.filled }}
            />

            <BuildingSelect
              sites={sites}
              onChange={setSelectedBuildings}
              resetPulse={true}
            />

            <Link to={'create'}>
              <Button
                secondary
                label={
                  <ButtonIconText>
                    <FontAwesomeIcon icon={solid('plus')} style={{ width: '14px', height: '16px' }} />
                    <span>{t('UtilityConsumption.UtilityBillManagement.AddBill', { ns: 'molecules' })}</span>
                  </ButtonIconText>
                }
              />
            </Link>
          </FlexRow>
        </RightAligned>
      </FlexContainer>
      <Tabs>
        {utilityTabs.map((utilityTab, i) => {
          return (
            <Tab
              key={utilityTab.utilityType.toString()}
              ref={element => tabRefs.current[i] = element}
              selected={selectedUtilityIndex === i}
              onClick={() => handleTabClick(i)}
              color={utilityTab.color}
            >
              <Icon icon={utilityTab.icon} $width={utilityTab.width} />
              <Text>{t(`${utilityTab.utilityType.toString()}`)}</Text>
            </Tab>
          );
        })}
        <TabLine ref={lineRef} />
      </Tabs>

      {allBills.length === 0 &&
        <Card
          centered={true}
          style={{ height: '500px', display: 'grid', alignItems: 'center' }}
        >
          <NoResultsError noResultsError={{
            errorIcon: solid('list-alt'),
            errorTitle: t('UtilityConsumption.UtilityBillManagement.NoBillsFound', { ns: 'molecules' }),
          }} />
        </Card>
      }

      {(allBills.length !== 0 && filteredBills.length < 1) &&
        <Card
          centered={true}
          style={{ height: '500px', display: 'grid', alignItems: 'center' }}
        >
          <NoResultsError noResultsError={{
            errorIcon: solid('list-alt'),
            errorTitle: t('UtilityConsumption.Errors.NoAddedBills', { ns: 'molecules' }),
            errorSubtitle: t('UtilityConsumption.Errors.AdjustFilter', { ns: 'molecules' })
          }} />
        </Card>
      }

      {filteredBills.length > 0 &&
        <Table
          columns={tableColumns}
          records={filteredBills}
          recordKey="id"
          emptyMessage={t('UtilityConsumption.Errors.AdjustSearch', { ns: 'molecules' })}
          disableSorting
          defaultSortColumn="modifiedOn"
          defaultSortOrder={TableSortOrder.DESC}
          loading={loadingBills || loadingSites}
          cardEffect
          highlightString={searchString}
          filters={[searchFilter]}
          fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 240}
        />
      }

      <WarningDialog
        modalRef={ref}
        isOpen={dialogIsOpen}
        sectionOne={t('UtilityConsumption.UtilityBillManagement.DeleteConfirmation', { ns: 'molecules', site: billToBeDeleted?.siteName, building: billToBeDeleted?.buildingName })}
        sectionTwo={t('UtilityConsumption.UtilityBillManagement.NoRecoveryWarning', { ns: 'molecules' })}
        confirmButton={t('Confirm', { ns: 'common' })}
        onCancel={toggle}
        onConfirm={deleteBill}
      />

    </PaddedContainer>
  );
}

export default UtilityConsumptionList;

const FlexContainer = styled.div` 
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const FlexRow = styled.div` 
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const ButtonIconText = styled.div` 
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const RightAligned = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-left: auto;
`;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

const Icon = styled(FontAwesomeIcon) <{ $width?: number }>`
  width: ${p => p.$width ?? 17}px;
  height: 17px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  user-select: none;
`;

const TabLine = styled.div`
  height: 2px;
  background-color: ${p => p.theme.palette.primary};
  transition: left 300ms ease, width 300ms ease;

  position: absolute;
  bottom: 0px;
  left: 0;
`;

const Tab = styled.div<{ selected: boolean, color: string }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 12px;
  transition: all 150ms ease;
  color: ${p => transparentize(0.2, p.theme.palette.text.fair)};
  cursor: pointer;

  ${p => p.selected && css`

    ${Label} {
      color: ${p => p.theme.palette.text.medium};
    }

    ${Icon} {
      color: ${p.color};
    }
  `}
`;

const ActionIcon = styled(FontAwesomeIcon) <{ $color?: string }>`
  font-size: 14px;
  cursor: pointer;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80px;
`;


