import { BuildingHierarchiesWithDevicesGetBySiteIdQuery } from '@dashboard/api/queries/building/BuildingHierarchiesWithDevicesGetBySiteIdQuery';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RangeStatus } from '@shared/api/enums/RangeStatus/RangeStatus';
import { DeviceIssueDto } from '@shared/api/models/ClimateControl/Device/DeviceIssueDto';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import { SpaceClimateControlDetailsDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDetailsDto';
import { DeviceIssuesGetBySiteIdQuery } from '@shared/api/queries/ClimateControl/Device/DeviceIssuesGetBySiteIdQuery';
import { SpaceClimateControlDetailsGetBySiteQuery } from '@shared/api/queries/ClimateControl/Space/SpaceClimateControlDetailsGetBySiteQuery';
import { Select } from '@shared/components/atoms/Select/Select';
import { useApiState } from '@shared/hooks/useApiState';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import styled, { css, useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import CSVExport from './CSVExport';
import DeviceIssuesTable from './DeviceIssuesTable';
import SpacesTable from './SpacesTable';
import TableSkeletonLoader from './TableSkeletonLoader';
import { TableTab } from '../types/TableTab';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { RouteLinkAnalytics } from '@shared/types/Route';

type TableTabsProps = {
  siteClimateControl: SiteClimateControlDto;
  refreshSiteClimateControl: () => void;
}

const TableTabs = ({ siteClimateControl, refreshSiteClimateControl }: TableTabsProps) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const [selected, setSelected] = useState<number>(0);
  const mediumScreen = useMediaQuery('(max-width: 900px)');
  const theme = useTheme();
  const { data: spaceClimateControlDetails, loading: loadingSpaces } = useApiState({
    query: siteClimateControl && new SpaceClimateControlDetailsGetBySiteQuery(siteClimateControl.siteId),
    initialState: [],
  }, [siteClimateControl]);
  const { data: deviceIssues, loading: loadingDeviceIssues } = useApiState({
    query: siteClimateControl && new DeviceIssuesGetBySiteIdQuery(siteClimateControl.siteId),
    initialState: [],
  }, [siteClimateControl]);
  const { data: buildings } = useApiState({
    query: siteClimateControl && new BuildingHierarchiesWithDevicesGetBySiteIdQuery(siteClimateControl.siteId),
    initialState: [],
  }, []);

  const tabs: TableTab[] = [
    {
      id: 0,
      label: t('AllSpaces', { ns: 'common' }),
      data: spaceClimateControlDetails,
      analytics: { action: 'list_all_spaces', category: 'climate_control' }
    },
    {
      id: 1,
      label: t('Overheated', { ns: 'common' }),
      data: spaceClimateControlDetails.filter(x => x.rangeStatus === RangeStatus.Over),
      icon: solid('chevrons-up'),
      iconColor: theme.error.main,
      emptyListLabel: t('NoOverheatedSpacesMessage', { ns: 'status' }),
      analytics: { action: 'list_overheated_spaces', category: 'climate_control' }
    },
    {
      id: 2,
      label: t('Underheated', { ns: 'common' }),
      data: spaceClimateControlDetails.filter(x => x.rangeStatus === RangeStatus.Under),
      icon: solid('chevrons-down'),
      iconColor: theme.complimentary.blue.main,
      emptyListLabel: t('NoUnderheatedSpacesMessage', { ns: 'status' }),
      analytics: { action: 'list_underheated_spaces', category: 'climate_control' }
    },
    {
      id: 3,
      label: t('ClimateControl.DeviceIssues.Label', { ns: 'molecules' }),
      data: deviceIssues,
      icon: solid('triangle-exclamation'),
      iconColor: theme.warning.main,
      emptyListLabel: t('NoDeviceIssuesMessage', { ns: 'status' }),
      loading: loadingDeviceIssues,
      analytics: { action: 'list_device_issues', category: 'climate_control' }
    }
  ];

  const tabSelectOptions = tabs?.map((x, index) => ({ value: index.toString(), label: x.label, analytics: x.analytics }));

  if (loadingSpaces) {
    return <TableSkeletonLoader />;
  }

  return (
    <StyledCard>
      <Header>
        {mediumScreen ?
          <StyledSelect>
            <Select
              options={tabSelectOptions}
              value={tabSelectOptions?.find(x => x.value === selected.toString())}
              onChange={(selected: SingleValue<{ label: string, value: string, analytics: RouteLinkAnalytics } | undefined>) => {
                if (selected) {
                  setSelected(parseInt(selected.value));
                  trackAction(selected.analytics.action, selected.analytics.category);
                }
              }}
            />
          </StyledSelect> :
          <Tabs>
            {tabs?.map((tab, i) => (
              <Tab
                key={uuidv4()}
                selected={selected === i}
                onClick={() => {
                  if (!tab.loading) {
                    setSelected(i);
                    trackAction(tab.analytics.action, tab.analytics.category);
                  }
                }}
                disabled={tab.loading}
              >
                {(tab.icon && tab.iconColor) &&
                  <Icon icon={tab.icon} color={tab.iconColor} />
                }
                <Label>{tab.label} {tab.loading ? <LoadingSpinner icon={solid('spinner')} className="fa-pulse" /> : `(${tab.data.length})`}</Label>
              </Tab>
            ))}
          </Tabs>
        }

        {tabs && spaceClimateControlDetails.length > 0 &&
          <CSVExport
            data={tabs}
          />
        }
      </Header>

      {tabs?.map((tab, i) => (
        <Content
          key={uuidv4()}
          selected={selected === i}
        >
          {tab.id === 3 ? (
            <DeviceIssuesTable
              tableData={tab.data as DeviceIssueDto[]}
              buildings={buildings}
              emptyListLabel={tab.emptyListLabel}
            />
          ) : (
            <SpacesTable
              tableData={tab.data as SpaceClimateControlDetailsDto[]}
              buildings={buildings}
              siteClimateControl={siteClimateControl}
              emptyListLabel={tab.emptyListLabel}
              refreshSiteClimateControl={refreshSiteClimateControl}
            />
          )}
        </Content>
      ))}
    </StyledCard>
  )
}

export default TableTabs;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${p => p.theme.action.divider};
  background-color: ${(p) => p.theme.background.container};
  box-shadow: 0px 3px 8px ${(p) => p.theme.palette.shadows.medium};
  margin: 40px;
  padding: 25px 30px;
  gap: 20px;

  @media (max-width: 600px) {
    padding: 25px;
  }

  @media (max-width: 375px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 375px) {
    gap: 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 12px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
`;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

const Tab = styled.div<{ selected: boolean, disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 8px;
  transition: all 150ms ease;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  border-radius: 4px;

  ${p => p.selected && css`
    background-color: ${p => p.theme.primary.background};

    ${Label} {
      color: ${p => p.theme.primary.main};
    }
  `}

  ${p => p.disabled && css`
    opacity: 0.6;
    cursor: unset;
  `}
`;

const Content = styled.div<{ selected: boolean }>`
  display: none;

  ${p => p.selected && css`
    display: inline;
  `}
`;

const StyledSelect = styled.div`
  width: 100%;
`;

const LoadingSpinner = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 2px;
`;