import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sub } from 'date-fns';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import StickyNote from './StickyNote';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { useApiState } from '@shared/hooks/useApiState';
import { DeviceNotesGetNotesByDeviceId } from '@dashboard/api/queries/deviceNotes/DeviceNotesGetNotesByDeviceId';
import { Title } from '@shared/components/atoms/Title/Title';
import { Button } from '@shared/components/atoms/Button/Button';
import { Loading } from '@shared/components/atoms/Loading/Loading';

type PropTypes = {
  device: DeviceWithMetrics;
};

const Notes = ({ device }: PropTypes) => {
  const { t } = useTranslation(['molecules']);
  const { data: notes, execute: refreshNotes, loading } = useApiState({
    query: new DeviceNotesGetNotesByDeviceId(device.id),
    initialState: []
  }, [device]);

  /**
   * Sort by the modifiedOn date if that date is newer than "0001-01-01 00:00:00.000000" which is the default value
   * in the database if it has never been updated, otherwise sort by the createdOn date.
   */
  const sortedNotes = orderBy(notes, x => {
    const useModifedDate = new Date(x.modifiedOn) > sub(new Date(), { years: 100 });
    return useModifedDate ? x.modifiedOn : x.createdOn;
  }, 'desc');


  return (
    <>
      <FlexRow>
        <Title
          text={t('DeviceCard.Notes', { ns: 'molecules' })}
          wrapperStyle={{ paddingBottom: '20px' }}
        />

        <Button
          tertiary
          circle
          label={<FontAwesomeIcon icon={solid('rotate')} />}
          onClick={refreshNotes}
        />
      </FlexRow>

      {loading &&
        <Loading />
      }

      {!loading &&
        <NotesWrapper>
          <StickyNote
            deviceId={device.id}
            refreshNotes={refreshNotes}
          />

          {sortedNotes.map((note) => (
            <StickyNote
              key={note.id}
              note={note}
              deviceId={device.id}
              refreshNotes={refreshNotes}
            />
          ))}
        </NotesWrapper>
      }
    </>
  );
};

export default Notes;

const NotesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 4px;
`;