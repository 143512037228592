
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Metric } from '@shared/api/models/Metric/Metric';
import { round } from '@shared/utils/NumberUtils';
import HeatmapConfigCO2 from './scales/HeatmapConfigCO2';
import HeatmapConfigHumidity from './scales/HeatmapConfigHumidity';
import HeatmapConfigMotion from './scales/HeatmapConfigMotion';
import HeatmapConfigLight from './scales/HeatmapConfigLight';
import HeatmapConfigNoise from './scales/HeatmapConfigNoise';
import HeatmapConfigRelay from './scales/HeatmapConfigRelay';
import HeatmapConfigTemperature from './scales/HeatmapConfigTemperature';
import HeatmapConfigSignalStrength from './scales/HeatmapConfigSignalStrength';
import HeatmapConfigDataRate from './scales/HeatmapConfigDataRate';
import HeatmapConfigBatteryVoltage from './scales/HeatmapConfigBatteryVoltage';
import HeatmapConfigLastReadingTime from './scales/HeatmapConfigLastReadingTime';
import HeatmapConfigWindowPosition from './scales/HeatmapConfigWindowPosition';
import HeatmapConfigChildLock from './scales/HeatmapConfigChildLock';
import HeatmapConfigMotorConsumption from './scales/HeatmapConfigMotorConsumption';
import HeatmapConfigElectricity from './scales/HeatmapConfigElectricity';
import HeatmapConfigBackplateAttached from './scales/HeatmapConfigBackplateAttached';
import HeatmapConfigBrokenSensor from './scales/HeatmapConfigBrokenSensor';
import HeatmapConfigCalibrationFailed from './scales/HeatmapConfigCalibrationFailed';
import HeatmapConfigMotorPosition from './scales/HeatmapConfigMotorPosition';
import HeatmapConfigPerceiveAsOnline from './scales/HeatmapConfigPerceiveAsOnline';
import HeatmapConfigMotorRange from './scales/HeatmapConfigMotorRange';
import { LocalisationFunction, LocalisedMetricTypes } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';

type ScalePoint = {
  /**
   * The upper limit threshold, value will be compared with less or equal operator (i.e. '<=')
   */
  threshold: number | string,
  /**
   * String that is displayed on top of the scale
   */
  label: string,
  /**
   * Color of the heatmap square
   */
  color: string,
  /**
   * Option to overwrite defaultTextColor for the value in the heatmap square
   */
  textColor: string,
  /**
   * Option to hide this threshold point from the scale
   */
  hideFromScale?: boolean,
  /**
   * For category scales only. Compare values against this point's threshold with the following comparator (Default: Equal)
   */
  comparator?: 'GreaterThan' | 'Equal'
}

type Scale = {
  /**
   * Array of scale points
   */
  points: ScalePoint[],
  /**
   * Set to true if the value is a category value and must match the threshold value exactly
   */
  categoryScale?: boolean
}

export interface IHeatmapConfig {
  /**
   * Optional function to be applied to each value before it is compared to the thresholds
   */
  accessor: (x: string) => number | string,
  /**
   * Optional function to be applied to each value to customise the string displayed on the heatmap square
   */
  displayFormat?: (x: number | string) => string | number,
  /**
   * Metric specific scale
   */
  scale: Scale,
}

/**
 * Retrieves the heatmap configuration based on the metric type and localisation.
 */

export const GetHeatmapMetricConfigs = (metricType: MetricType, toLocale: LocalisationFunction, getUnit: (metric: MetricType | AltUnits) => string): IHeatmapConfig => {
  const configsMap: { [key: string]: IHeatmapConfig } = {
    [MetricType.Temperature]: HeatmapConfigTemperature(toLocale, getUnit),
    [MetricType.TemperatureTarget]: HeatmapConfigTemperature(toLocale, getUnit),
    [MetricType.Humidity]: HeatmapConfigHumidity,
    [MetricType.Noise]: HeatmapConfigNoise,
    [MetricType.CO2]: HeatmapConfigCO2,
    [MetricType.Light]: HeatmapConfigLight,
    [MetricType.MotionMeasuredOn]: HeatmapConfigMotion,
    [MetricType.RelayOutputStatus]: HeatmapConfigRelay,
    [MetricType.SignalStrength]: HeatmapConfigSignalStrength,
    [MetricType.DataRate]: HeatmapConfigDataRate,
    [MetricType.BatteryVoltage]: HeatmapConfigBatteryVoltage,
    [MetricType.DeviceLastMeasuredOn]: HeatmapConfigLastReadingTime,
    [MetricType.WindowPosition]: HeatmapConfigWindowPosition,
    [MetricType.ChildLock]: HeatmapConfigChildLock,
    [MetricType.HighMotorConsumption]: HeatmapConfigMotorConsumption,
    [MetricType.LowMotorConsumption]: HeatmapConfigMotorConsumption,
    [MetricType.ElectricityKwh]: HeatmapConfigElectricity,
    [MetricType.AttachedBackplate]: HeatmapConfigBackplateAttached,
    [MetricType.BrokenSensor]: HeatmapConfigBrokenSensor,
    [MetricType.CalibrationFailed]: HeatmapConfigCalibrationFailed,
    [MetricType.MotorPosition]: HeatmapConfigMotorPosition,
    [MetricType.MotorRange]: HeatmapConfigMotorRange,
    [MetricType.PerceiveAsOnline]: HeatmapConfigPerceiveAsOnline
  };

  return configsMap[metricType] ?? undefined;
}

/**
 * Get a metric-specific heatmap config
 */
export const getHeatmapMetricConfig = (metricType: MetricType, toLocale: LocalisationFunction, getUnit: (metric: MetricType | AltUnits) => string): IHeatmapConfig | undefined => {
  return GetHeatmapMetricConfigs(metricType, toLocale, getUnit);
};

/**
 * Given a value, return the label, color, and text-color for a single heatmap square
 */
export const getLabelAndScaleColor = (metric: Metric, toLocale: LocalisationFunction, getUnit: (metric: MetricType | AltUnits) => string): [string | number, string, string] => {
  const config = GetHeatmapMetricConfigs(metric.metricType, toLocale, getUnit);

  if (!config) {
    let displayValue = metric.value;
    let numericValue = Number(metric.value);
    numericValue = toLocale(metric.metricType, numericValue)

    // If the value is a number, round to 1 decimal place for values <100, and to zero decimal places for values >=100
    if (!Number.isNaN(numericValue)) {
      displayValue = round(numericValue, numericValue >= 100 ? 0 : 1).toString();
    }

    return [displayValue, '#2289eb', '#fff'];
  }

  if (config.scale.categoryScale) {
    const modifiedValue = config.accessor(metric.value);

    const point = config.scale.points.find(p => p.comparator === 'GreaterThan'
      ? modifiedValue > p.threshold
      : modifiedValue === p.threshold
    );

    if (point) {
      const displayLabel = config.displayFormat ? config.displayFormat(modifiedValue) : point?.label;
      return [displayLabel, point.color, point.textColor];
    } else {
      return ['', '#fff', '#000'];
    }
  }

  let modifiedValue: string | number = '';

  if (LocalisedMetricTypes.includes(metric.metricType)) {
    modifiedValue = config.accessor(toLocale(metric.metricType, parseFloat(metric.value)).toString());
  } else {
    modifiedValue = config.accessor(metric.value);
  }

  const displayValue = config.displayFormat ? config.displayFormat(modifiedValue) : modifiedValue;

  // Get the matching threshold point
  const point = config.scale.points.find(p => modifiedValue <= p.threshold);
  if (point) {
    return [displayValue, point.color, point.textColor];
  }

  // If no threshold was matched, use colors of last threshold point
  const lastThresholdColor = config.scale.points[config.scale.points.length - 1].color;
  const lastThresholdTextColor = config.scale.points[config.scale.points.length - 1].textColor;

  return [displayValue, lastThresholdColor, lastThresholdTextColor];
};
