import { HeatmapSpaceDto } from '@dashboard/api/models/HeatmapSpaceDto';
import { useModal } from '@shared/hooks/useModal';
import HeatmapTooltip from './Tooltip/HeatmapTooltip';
import HeatmapSpaceModal from './Modal/HeatmapSpaceModal';
import HeatmapSquare from './HeatmapSquare';
import { HeatmapConfig } from '@dashboard/components/organisms/BuildingPage/BuildingPage_Heatmap';

type PropTypes = {
  space: HeatmapSpaceDto;
  config: HeatmapConfig;
  linkPathToSpace: string;
  onFloorplan?: boolean;
  size?: number;
  loadingRefetch?: boolean;
}

const HeatmapSquareWrapper = ({ space, config, linkPathToSpace, onFloorplan, size, loadingRefetch }: PropTypes) => {
  const { isOpen, toggle, ref } = useModal({});
  const showSpaceIcon = !config.model;

  return (
    <>
      <HeatmapTooltip
        space={space}
        config={config}
        noContent={showSpaceIcon}
      >
        <HeatmapSquare
          space={space}
          toggleModal={toggle}
          onFloorplan={onFloorplan}
          showSpaceIcon={showSpaceIcon}
          size={size}
          loadingRefetch={loadingRefetch}
        />
      </HeatmapTooltip>

      <HeatmapSpaceModal
        ref={ref}
        spaceId={space.spaceId}
        isOpen={isOpen}
        linkPathToSpace={linkPathToSpace}
      />
    </>
  );
};

export default HeatmapSquareWrapper;