import styled from 'styled-components';
import { ILabelValuePair } from './LabelValuePair.types';
import { isNullOrUndefined } from '@shared/utils/NumberUtils';

export const LabelValuePair = ({
  label,
  value,
  prefix,
  suffix,
  size,
  smallGap,
  inline,
  style,
  labelStyle,
  valueStyle
}: ILabelValuePair) => {
  return (
    <div style={style}>
      <Label style={labelStyle} inline={inline}>
        {label}
        {inline && <>&nbsp;</>}
      </Label>
      <Value style={valueStyle} size={size} smallGap={smallGap} inline={inline}>
        {!isNullOrUndefined(value) && prefix && (
          <PrefixSuffix>{prefix}&nbsp;</PrefixSuffix>
        )}
        {!isNullOrUndefined(value) ? value : '--'}
        {!isNullOrUndefined(value) && suffix && (
          <PrefixSuffix>&nbsp;{suffix}</PrefixSuffix>
        )}
      </Value>
    </div>
  );
};

const Label = styled.div<{ inline?: boolean }>`
  display: ${(p) => (p.inline ? 'inline-block' : 'block')};

  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.text.secondary};
  padding-bottom: 2px;
`;

const Value = styled.div<{
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  smallGap?: boolean;
  inline?: boolean;
}>`
  display: ${(p) => (p.inline ? 'inline-block' : 'block')};

  font-size: ${(p) =>
    (p.size === 'xs' && '14px') ||
    (p.size === 'sm' && '16px') ||
    (p.size === 'lg' && '26px') ||
    (p.size === 'xl' && '32px') ||
    '20px'};

  line-height: ${(p) => (p.smallGap ? '16px' : 'normal')};

  font-weight: 500;
  color: ${(p) => p.theme.text.primary};
`;

const PrefixSuffix = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: ${(p) => p.theme.text.primary};
`;
