import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faSquareMinus } from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountDownAlt, faSortAmountUp, faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefObject } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { TableColumn, TableRow, TableSelectIcon, TableSelectIconWrapper, TableValue } from './Table.shared';
import { ITableColumn, TableSortOrder } from './Table.types';

interface ITableHeader<TRecord> {
  refProp: RefObject<HTMLDivElement>;
  columns: ITableColumn<TRecord>[];
  sortColumn: string;
  selectable?: boolean;
  someSelected: boolean;
  allSelected: boolean;
  onMultiSelect: () => void;
  wrapColumns: boolean;
  disableSorting?: boolean;
  sortOrder: TableSortOrder;
  defaultSortOrder?: TableSortOrder;
  compact?: boolean;
  updateSortColumn: (column: string) => void;
  updateSortOrder: (order: TableSortOrder) => void;
}

const TableHeader = <TRecord extends object>({ refProp, columns, sortColumn, selectable, someSelected, allSelected, onMultiSelect, wrapColumns, disableSorting, sortOrder, defaultSortOrder, compact, updateSortColumn, updateSortOrder }: ITableHeader<TRecord>) => {

  const setSort = (property?: string) => {
    if (property && property !== sortColumn) {
      updateSortColumn(property);
      updateSortOrder(defaultSortOrder ? defaultSortOrder : TableSortOrder.ASC);
      return;
    }

    updateSortOrder(sortOrder === TableSortOrder.ASC ? TableSortOrder.DESC : TableSortOrder.ASC);
  };

  const renderSortIcon = (property?: string) => {
    if (!property || disableSorting || sortColumn !== property) {
      return null;
    }

    return <SortIcon icon={sortOrder === TableSortOrder.ASC ? faSortAmountDownAlt : faSortAmountUp} />;
  };

  const getTableSelectIcon = () => {
    if (!someSelected) {
      return faSquare;
    }

    return allSelected ? faSquareCheck : faSquareMinus;
  }

  return (
    <StyledTableRow
      ref={refProp}
      wrapColumns={wrapColumns}
    >
      {selectable &&
        <TableSelectIconWrapper>
          <TableSelectIcon
            selected={someSelected}
            icon={getTableSelectIcon()}
            onClick={onMultiSelect}
          />
        </TableSelectIconWrapper>
      }

      {columns.map((column) => {
        return (
          <TableColumn
            key={uuidv4()}
            width={column.width}
            fixedWidth={column.fixedWidth}
            rightAlign={!wrapColumns && column.rightAlign}
            wrapColumns={wrapColumns}
            compact={compact}
            onClick={() => !column.disableSort && !disableSorting && setSort(column.key)}
          >
            <StyledTableValue
              rightAlign={!wrapColumns && column.rightAlign}
              clickable={!column.disableSort && !disableSorting && column.key !== undefined}
              wrapColumns={wrapColumns}
              compact={compact}
            >
              <span>{column.label}</span>
              <span>{renderSortIcon(column.key)}</span>
            </StyledTableValue>
          </TableColumn>
        );
      })}
    </StyledTableRow>
  );
};

export default TableHeader;

const StyledTableRow = styled(TableRow) <{ wrapColumns?: boolean }>`
  position: relative;
  border-bottom: 1px solid ${(p) => p.theme.palette.borders.weak};
  box-shadow: 0 7px 7px -8px ${(p) => p.theme.palette.shadows.medium};
  border-radius: 5px 5px 0 0;
`;

const StyledTableValue = styled(TableValue) <{ rightAlign?: boolean, clickable?: boolean, wrapColumns?: boolean }>`
  font-weight: 500;
  cursor: ${p => p.clickable ? 'pointer' : 'unset'};

  gap: 5px;
  
  user-select: none;
  overflow: hidden;

  ${p => p.rightAlign && css`
    flex-direction: row-reverse;
  `}
`;

const SortIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.primary};
`;