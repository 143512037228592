import Notes from '@dashboard/components/molecules/DeviceNotes/Notes';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';

type PropTypes = {
  device: DeviceWithMetrics;
};

const DevicePage_Notes = ({ device }: PropTypes) => {
  return <Notes device={device} />;
};

export default DevicePage_Notes;