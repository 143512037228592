import { Feature } from '@shared/api/models/Feature/Feature';
import { Tenant } from '@shared/api/models/Tenant/Tenant';
import FeatureListItem from './FeatureListItem';

interface IFeatureList {
  tenants: Tenant[];
  features: Feature[];
  refresh: () => void;
}

const FeatureList = ({ tenants, features, refresh }: IFeatureList) => {
  return (
    <>
      {features.map((feature, i) => (
        <FeatureListItem
          key={i}
          tenants={tenants}
          feature={feature}
          refresh={refresh}
        />
      ))}
    </>
  );
};

export default FeatureList;