import { Card } from '@shared/components/molecules/Card/Card';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SiteOperatorInfoField from './SiteOperatorInfoField';
import { SiteOperatorDto } from '@shared/api/models/ResidentApp/SiteOperatorDto';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

type SiteOperatorInfoProps = {
  siteOperator?: SiteOperatorDto;
}

const SiteOperatorInfo = ({ siteOperator }: SiteOperatorInfoProps) => {
  const { t } = useTranslation(['molecules', 'status']);
  const { getUnit, toLocale } = useLocalisation();

  return (
    <Card maxWidth='fit-content'>
      <Info>
        <SiteOperatorInfoField
          label={t('ResidentApp.OperatorContactEmail')}
          value={siteOperator?.email}
        />
        <SiteOperatorInfoField
          label={t('ResidentApp.MaximumRecommendedTemperature', {temperatureUnit: getUnit(MetricType.Temperature)})}
          value={siteOperator?.maxTemp ? toLocale(MetricType.Temperature, siteOperator.maxTemp, {round: 1}).toString() : undefined}
        />
        <SiteOperatorInfoField
          label={t('ResidentApp.MinimumRecommendedTemperature', {temperatureUnit: getUnit(MetricType.Temperature)})}
          value={siteOperator?.minTemp ? toLocale(MetricType.Temperature, siteOperator?.minTemp, {round: 1}).toString() : undefined}
          tooltip={t('ResidentApp.MinimumRecommendedTemperatureTooltip')}
        />
      </Info>
    </Card>
  );
};

export default SiteOperatorInfo;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;