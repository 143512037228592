import { useTranslation } from 'react-i18next'
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useModal } from '@shared/hooks/useModal';
import { useEffect, useState } from 'react';
import SiteFeature from '../SiteFeature';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useApi } from '@shared/hooks/useApi';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import SiteMetricTypeResidentAppCreateCommand from '@shared/api/queries/ResidentApp/SiteMetricTypes/SiteMetricTypeResidentAppCreateCommand';
import { SiteMetricTypeResidentAppDeleteCommand } from '@shared/api/queries/ResidentApp/SiteMetricTypes/SiteMetricTypeResidentAppDeleteCommand';

type ResidentAppMetricFeatureProps ={
  metricType: MetricType;
}

const ResidentAppMetricFeature =({ metricType }: ResidentAppMetricFeatureProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const { siteOperator, refreshSiteOperator} = useSiteContext();
  const { isOpen: isDialogOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});
  const [isEnabled, setIsEnabled] = useState(false);

  const siteMetricTypeDto = siteOperator?.siteMetricTypes?.find((siteMetricType) => siteMetricType.metricType == metricType);

  useEffect(() => {
    setIsEnabled(!!siteMetricTypeDto);
  }, [siteMetricTypeDto]);

  const handleChange = (enabled: boolean) => {
    if (enabled) {
      setIsEnabled(enabled);
      enableSiteMetricType();
    } else {
      toggleDialog();
    }
  };

  const enableSiteMetricType = async () => {
    if(siteOperator){
      await execute({
        query: new SiteMetricTypeResidentAppCreateCommand(
          siteOperator.id,
          metricType),
        successMessage: t('ResidentApp.MetricEnabled', { ns: 'molecules', metricType: metricType }),
        errorMessage: t('ResidentApp.MetricEnabledError', { ns: 'molecules', metricType: metricType }),
        pendingMessage: t('ResidentApp.MetricEnabledPending', { ns: 'molecules', metricType: metricType })
      })
    }

    refreshSiteOperator();
  }

  const disableSiteMetricType = async () => {
    if(siteMetricTypeDto){
      await execute({
        query: new SiteMetricTypeResidentAppDeleteCommand(
          siteMetricTypeDto.id
        ),
        successMessage: t('ResidentApp.MetricDisabled', { ns: 'molecules', metricType: metricType }),
        errorMessage: t('ResidentApp.MetricDisabledError', { ns: 'molecules', metricType: metricType }),
        pendingMessage: t('ResidentApp.MetricDisabledPending', { ns: 'molecules', metricType: metricType })
      })

      toggleDialog();
      refreshSiteOperator();
    }
  }

  return(
    <>
      <WarningDialog
        modalRef={dialogRef}
        isOpen={isDialogOpen}
        sectionOne={t('ResidentApp.DisableResidentMetricDialog', { ns: 'molecules', metricType: t(metricType, {ns: 'enums'})})}
        confirmButton={t('Disable', { ns: 'common' })}
        onCancel={toggleDialog}
        onConfirm={disableSiteMetricType}
      />

      <SiteFeature
        name={t(metricType, {ns: 'enums'})}
        description={t('ResidentApp.MetricFeatureDescription', { ns: 'molecules', metricType: t(metricType, {ns: 'enums'})})}
        icon={solid('mobile-screen-button')}
        isEnabled={isEnabled}
        onChange={handleChange}
      />
    </>
  )
};

export default ResidentAppMetricFeature;