import { Site } from '@shared/api/models/Site/Site';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class SiteUpdateCommand extends PatchQuery<Site> {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  region?: string;
  country: string;
  postCode: string;

  constructor(site: Site) {
    super(site.id);
    this.name = site.name;
    this.addressLine1 = site.address.addressLine1;
    this.addressLine2 = site.address.addressLine2;
    this.city = site.address.city;
    this.region = site.address.region;
    this.country = site.address.country;
    this.postCode = site.address.postCode;
  }

  public targetUrl(): string {
    return '/sites';
  }
}
