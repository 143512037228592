import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExceptionsActions from './ExceptionsActions';

type ExceptionsTableProps = {
  exceptions: SpaceClimateControlDto[];
  selectable?: boolean;
  showStatus?: boolean;
  showActions?: boolean;
  onEditException?: (exception: SpaceClimateControlDto) => void;
  onDeleteException?: (exceptionToDelete: SpaceClimateControlDto) => void;
  setSelectedExceptions?: (selectedExceptions: SpaceClimateControlDto[]) => void;
}

const ExceptionsTable = ({ exceptions, selectable, showStatus, showActions, onEditException, onDeleteException, setSelectedExceptions }: ExceptionsTableProps) => {
  const { toLocale, getUnit, localisation } = useLocalisation();
  const { t } = useTranslation();

  const renderEndDate = useCallback((record: SpaceClimateControlDto) => {
    if (!record.endDate) {
      return '-';
    }
    if (dayjs(new Date(record.endDate)).isBefore(dayjs())) {
      return t('Expired', { ns: 'common' })
    }
    return t('Active', { ns: 'common' });
  }, [t])

  const tableColumns: ITableColumn<SpaceClimateControlDto>[] = useMemo(() => ([
    {
      label: t('Spaces', { ns: 'assets' }),
      key: 'spaceName'
    },
    {
      label: t('Floor', { ns: 'common' }),
      key: 'floorName',
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName',
    },
    {
      label: t('ClimateControl.Minimum', { ns: 'molecules' }),
      key: 'minTemp',
      sortFormat: record => record.minTemp,
      displayFormat: record => `${toLocale(MetricType.Temperature, record.minTemp ?? 0, { round: 0 })} ${getUnit(MetricType.Temperature)}`
    },
    {
      label: t('ClimateControl.Maximum', { ns: 'molecules' }),
      key: 'maxTemp',
      sortFormat: record => record.maxTemp,
      displayFormat: record => `${toLocale(MetricType.Temperature, record.maxTemp ?? 0, { round: 0 })} ${getUnit(MetricType.Temperature)}`
    },
    {
      label: t('ClimateControl.InitialTarget', { ns: 'molecules' }),
      key: 'targetTemp',
      sortFormat: record => record.targetTemp,
      displayFormat: record => `${toLocale(MetricType.Temperature, record.targetTemp ?? 0, { round: 0 })} ${getUnit(MetricType.Temperature)}`
    },
    {
      label: t('ClimateControl.EndDate', { ns: 'molecules' }),
      key: 'endDate',
      sortFormat: record => record.endDate && new Date(record.endDate),
      displayFormat: record => record.endDate ? dayjs(new Date(record.endDate)).format(localisation.dateFormats.date) : 'Never',
    }
  ]), [getUnit, localisation.dateFormats.date, t, toLocale]);


  const getColumns = useCallback(() => {
    const updatedColumns = [...tableColumns];

    if (showStatus) {
      updatedColumns.push({
        label: t('Status', { ns: 'common' }),
        key: 'status',
        customElement: (record) => renderEndDate(record),
        sortFormat: record => record.endDate && new Date(record.endDate)
      });
    }

    if (showActions) {
      updatedColumns.push({
        key: 'actions',
        customElement: (e) => <ExceptionsActions onEditException={(exception) => onEditException && onEditException(exception)} onDeleteException={(exception) => onDeleteException && onDeleteException(exception)} exception={e} />,
        fixedWidth: '60px'
      });
    }

    return updatedColumns;
  }, [onDeleteException, onEditException, renderEndDate, showActions, showStatus, t, tableColumns]);

  return (
    <Table
      columns={getColumns()}
      records={exceptions}
      recordKey='id'
      defaultSortColumn={'status'}
      onSelect={setSelectedExceptions}
      loading={false}
      cardEffect
      disablePaging={exceptions.length < 10}
      removeDefaultStyling={true}
      selectable={selectable}
      minHeight={'100%'}
      recordBorder={true}
      showOverflow={true}
      emptyMessage={t('None', { ns: 'status' })}
    />
  )
}

export default ExceptionsTable;