import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { isEmpty, isMatch } from 'lodash';
import styled, { useTheme } from 'styled-components';
import Masonry from 'react-masonry-css';
import { useTranslation } from 'react-i18next';
import SiteMetadataUpdateCommand from '@settings/api/queries/SiteMetadata/SiteMetadataUpdateCommand';
import MonthlyWeightingInput from '@settings/components/organisms/BuildingManagement/BuildingPage/Edit/MonthlyWeightingInput';
import { MonthlyWeighting } from '@shared/api/models/Building/BuildingMetadata';
import { SiteMetadata } from '@shared/api/models/SiteMetadata/SiteMetadata';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { useApi } from '@shared/hooks/useApi';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { Button } from '@shared/components/atoms/Button/Button';
import { Card } from '@shared/components/molecules/Card/Card';
import { Select } from '@shared/components/atoms/Select/Select';
import { getCurrencies } from '@shared/utils/CurrencyUtils';
import { RouterPrompt } from '@shared/components/navigation/RouterPrompt/RouterPrompt';
import { Site } from '@shared/api/models/Site/Site';
import { useApiState } from '@shared/hooks/useApiState';
import SiteMetadataGetBySiteIdQuery from '@settings/api/queries/SiteMetadata/SiteMetadataGetBySiteIdQuery';
import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { currencyDecimalName } from '@shared/utils/CurrencyUtils';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';

const masonryBreakpointColumns = {
  default: 2,
  1600: 1
};

interface ISiteMetadataEdit {
  site: Site
}

type FormValues = {
  currency?: string,
  electricityPricePerKwh?: number,
  heatingPricePerKwh?: number,
  waterPricePerM3?: number,
  assetArea?: number,
  targetCarbon?: number,
  baselineCarbon?: number,
  electricityCarbonIntensity?: number
  heatingCarbonIntensity?: number,
  electricityConsumptionTarget?: number,
  heatingConsumptionTarget?: number,
  waterConsumptionTarget?: number,
  gasConsumptionTarget?: number,
  electricityConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  heatingConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  waterConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  gasConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  waterCarbonIntensity?: number,
  wasteWaterCarbonIntensity?: number,
  wasteWaterProportion?: number,
  wasteWaterPricePerM3?: number,
  gasPricePerM3?: number,
  gasCarbonIntensity?: number,
  gasCalorificValue?: number,
  epc?: string,
  gresb?: number,
  fitwel?: number,
  breeam?: number,
  liveDate?: Date
}

const SiteMetadataEdit = ({ site }: ISiteMetadataEdit) => {
  const { t } = useTranslation(['settingsAsset', 'currencies']);
  const theme = useTheme();
  const { getUnit, toLocale, fromLocale } = useLocalisation();
  const { isSuperAdmin } = useUserContext();

  const { data: siteMetadata } = useApiState({
    query: new SiteMetadataGetBySiteIdQuery(site.id.toString())
  }, [site]);

  const decimalName = siteMetadata?.currency != null ? currencyDecimalName(siteMetadata?.currency, t) : t('GBPDecimal', { ns: 'common' });

  const currencyOptions = getCurrencies().map(({ code }) => ({
    value: code,
    label: `${t(`Currencies.${code}`, { ns: 'currencies' })} - ${code}`
  }))

  const { register, reset, handleSubmit, control, formState: { errors } } = useForm<FormValues>();

  useEffect(() => {
    const defaultValues: FormValues = {
      currency: siteMetadata?.currency,
      electricityConsumptionTarget: siteMetadata?.electricityConsumptionTarget,
      heatingConsumptionTarget: siteMetadata?.heatingConsumptionTarget,
      waterConsumptionTarget: toLocale(MetricType.WaterVolume, siteMetadata?.waterConsumptionTarget ?? 0, { round: 4 }),
      gasConsumptionTarget: toLocale(MetricType.GasVolume, siteMetadata?.gasConsumptionTarget ?? 0, { round: 4 }),
      electricityConsumptionTargetMonthlyWeighting: siteMetadata?.electricityConsumptionTargetMonthlyWeighting,
      heatingConsumptionTargetMonthlyWeighting: siteMetadata?.heatingConsumptionTargetMonthlyWeighting,
      waterConsumptionTargetMonthlyWeighting: siteMetadata?.waterConsumptionTargetMonthlyWeighting,
      gasConsumptionTargetMonthlyWeighting: siteMetadata?.gasConsumptionTargetMonthlyWeighting,
      electricityPricePerKwh: siteMetadata?.electricityPricePerKwh,
      heatingPricePerKwh: siteMetadata?.heatingPricePerKwh,
      waterPricePerM3: siteMetadata?.waterPricePerM3,
      targetCarbon: toLocale(AltUnits.CO2WeightArea, siteMetadata?.targetCarbon ?? 0, { round: 4 }),
      baselineCarbon: toLocale(AltUnits.CO2WeightArea, siteMetadata?.baselineCarbon ?? 0, { round: 4 }),
      electricityCarbonIntensity: toLocale(AltUnits.CO2WeightIntensity, siteMetadata?.electricityCarbonIntensity ?? 0, { round: 4 }),
      heatingCarbonIntensity: toLocale(AltUnits.CO2WeightIntensity, siteMetadata?.heatingCarbonIntensity ?? 0, { round: 4 }),
      waterCarbonIntensity: toLocale(AltUnits.CO2SubWeightArea, siteMetadata?.waterCarbonIntensity ?? 0, { round: 4 }),
      wasteWaterCarbonIntensity: toLocale(AltUnits.CO2SubWeightArea, siteMetadata?.wasteWaterCarbonIntensity ?? 0, { round: 4 }),
      wasteWaterProportion: siteMetadata?.wasteWaterProportion,
      wasteWaterPricePerM3: siteMetadata?.wasteWaterPricePerM3,
      gasPricePerM3: siteMetadata?.gasPricePerM3,
      gasCarbonIntensity: toLocale(AltUnits.CO2SubWeightArea, siteMetadata?.gasCarbonIntensity ?? 0, { round: 4 }),
      gasCalorificValue: siteMetadata?.gasCalorificValue,
      epc: siteMetadata?.epc,
      gresb: siteMetadata?.gresb,
      fitwel: siteMetadata?.fitwel,
      breeam: siteMetadata?.breeam,
      liveDate: siteMetadata?.liveDate
    };
    reset({ ...defaultValues });
  }, [siteMetadata, reset, toLocale])


  const [editMode, setEditMode] = useState(false);
  const { execute } = useApi();

  const exitEditMode = () => {
    setEditMode(false)
    reset();
  };

  const onSave: SubmitHandler<FormValues> = async data => {
    setEditMode(false);
    let isMounted = true;

    if (!siteMetadata) {
      return;
    }
    const modifiedSiteMetadata: SiteMetadata = {
      ...siteMetadata,
      currency: data.currency,
      electricityConsumptionTarget: data.electricityConsumptionTarget,
      heatingConsumptionTarget: data.heatingConsumptionTarget,
      waterConsumptionTarget: fromLocale(MetricType.WaterVolume, data.waterConsumptionTarget ?? 0, { round: 4 }),
      gasConsumptionTarget: fromLocale(MetricType.GasVolume, data.gasConsumptionTarget ?? 0, { round: 4 }),
      electricityConsumptionTargetMonthlyWeighting: data.electricityConsumptionTargetMonthlyWeighting,
      heatingConsumptionTargetMonthlyWeighting: data.heatingConsumptionTargetMonthlyWeighting,
      waterConsumptionTargetMonthlyWeighting: data.waterConsumptionTargetMonthlyWeighting,
      gasConsumptionTargetMonthlyWeighting: data.gasConsumptionTargetMonthlyWeighting,
      electricityPricePerKwh: data.electricityPricePerKwh,
      heatingPricePerKwh: data.heatingPricePerKwh,
      waterPricePerM3: data.waterPricePerM3,
      targetCarbon: fromLocale(AltUnits.CO2WeightArea, data.targetCarbon ?? 0, { round: 4 }),
      baselineCarbon: fromLocale(AltUnits.CO2WeightArea, data.baselineCarbon ?? 0, { round: 4 }),
      heatingCarbonIntensity: fromLocale(AltUnits.CO2WeightIntensity, data.heatingCarbonIntensity ?? 0, { round: 4 }),
      waterCarbonIntensity: fromLocale(AltUnits.CO2SubWeightArea, data.waterCarbonIntensity ?? 0, { round: 4 }),
      wasteWaterCarbonIntensity: fromLocale(AltUnits.CO2SubWeightArea, data.wasteWaterCarbonIntensity ?? 0, { round: 4 }),
      wasteWaterProportion: data.wasteWaterProportion,
      wasteWaterPricePerM3: data.wasteWaterPricePerM3,
      electricityCarbonIntensity: fromLocale(AltUnits.CO2WeightIntensity, data.electricityCarbonIntensity ?? 0, { round: 4 }),
      gasPricePerM3: data.gasPricePerM3,
      gasCarbonIntensity: fromLocale(AltUnits.CO2SubWeightArea, data.gasCarbonIntensity ?? 0, { round: 4 }),
      gasCalorificValue: data.gasCalorificValue,
      breeam: data.breeam,
      epc: data.epc,
      fitwel: data.fitwel,
      gresb: data.gresb,
      liveDate: data.liveDate
    }

    if (!isMatch(siteMetadata, modifiedSiteMetadata)) {
      const siteMetadataDto = await execute({
        query: new SiteMetadataUpdateCommand(modifiedSiteMetadata),
        pendingMessage: t('Sites.SiteMetadataEditing.Saving', { ns: 'settingsAsset' }),
        successMessage: t('Sites.SiteMetadataEditing.ChangesSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Sites.SiteMetadataEditing.UpdateFailed', { ns: 'settingsAsset' })
      });

      isMounted = siteMetadataDto !== undefined;
    }

    if (!isMounted) {
      return;
    }
  }

  return (
    <>
      <RouterPrompt when={editMode} />

      <PaddedContainer>
        <FlexRow>
          {editMode &&
            <>
              <Button
                tertiary
                label={t('Cancel', { ns: 'common' })}
                onClick={() => exitEditMode()}
                color={theme.palette.red}
              />

              <Button
                label={t('Sites.SiteMetadataEditing.Save', { ns: 'settingsAsset' })}
                onClick={handleSubmit(onSave)}
                disabled={!isEmpty(errors)}
              />
            </>
          }

          {!editMode &&
            <>
              <Button
                label={t('Sites.SiteMetadataEditing.Edit', { ns: 'settingsAsset' })}
                onClick={() => setEditMode(true)}
              />
            </>
          }
        </FlexRow>

        <Form>
          <Masonry
            breakpointCols={masonryBreakpointColumns}
            className="building-edit-masonry-grid"
            columnClassName="building-edit-masonry-grid_column"
          >

            <Card cardTitle={t('Sites.SiteMetadataEditing.General', { ns: 'settingsAsset' })}>
              <div className="row" style={{ marginBottom: '15px' }}>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.Currency', { ns: 'settingsAsset' })}</Label>
                  <Controller
                    control={control}
                    name="currency"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={currencyOptions.find(currency => currency.value === value)}
                        isSearchable={true}
                        onChange={selected => selected && onChange(selected.value)}
                        options={currencyOptions}
                        readOnly={!editMode}
                      />
                    )}
                  />
                  <ErrorMessage>{errors.currency?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.ContractStartDate', { ns: 'settingsAsset' })}</Label>
                  <Controller
                    control={control}
                    name="liveDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        value={value == undefined ? null : dayjs(value)}
                        onChange={(value) => {
                          if (value != undefined) {
                            onChange(dayjs(value));
                          }
                        }}
                        placeholder='N/A'
                        disabled={!editMode || !isSuperAdmin}
                        showTime={false}
                      />
                    )}
                  />
                  <ErrorMessage>{errors.liveDate?.message}</ErrorMessage>
                </div>
              </div>
            </Card>

            <Card cardTitle={t('Sites.SiteMetadataEditing.Utilities', { ns: 'settingsAsset' })}>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.ElectricityPrice', { decimalName: decimalName })}</Label>
                  <Input
                    {...register('electricityPricePerKwh', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.ElectricityPriceValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 0,
                        message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.EnergyPricePlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.electricityPricePerKwh?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.HeatingPrice', { decimalName: decimalName })}</Label>
                  <Input
                    {...register('heatingPricePerKwh', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.HeatingPriceValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 0,
                        message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.EnergyPricePlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.heatingPricePerKwh?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.WaterPrice', { decimalName: decimalName, unit: getUnit(MetricType.WaterVolume) })}</Label>
                  <Input
                    {...register('waterPricePerM3', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.WaterPriceValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 0,
                        message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.WaterPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.waterPricePerM3?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.WasteWaterPrice', { decimalName: decimalName, unit: getUnit(MetricType.WaterVolume) })}</Label>
                  <Input
                    {...register('wasteWaterPricePerM3', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.WasteWaterPriceValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 0,
                        message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.WaterPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.wasteWaterPricePerM3?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.WasteWaterProportion', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('wasteWaterProportion', {
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.WasteWaterProportionMin', { ns: 'settingsAsset' })
                      },
                      max: {
                        value: 99,
                        message: t('Sites.SiteMetadataEditing.WasteWaterProportionMax', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.WasteWaterProportionPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.wasteWaterProportion?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.GasPrice', { decimalName: decimalName, unit: getUnit(MetricType.GasVolume) })}</Label>
                  <Input
                    {...register('gasPricePerM3', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.GasPriceValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 0,
                        message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.GasPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.GasVolume) })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.gasPricePerM3?.message}</ErrorMessage>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.GasCalorificValue')}</Label>
                  <Input
                    {...register('gasCalorificValue', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.GasCalorificValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.GasCalorificPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.gasCalorificValue?.message}</ErrorMessage>
                </div>
              </div>
            </Card>

            <Card cardTitle={t('Sites.SiteMetadataEditing.CertificationsAndRatings', { ns: 'settingsAsset' })}>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.EPCRating', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('epc', {
                      setValueAs: value => nullIfEmptyString(value),
                      pattern: {
                        value: /[A-G]/,
                        message: t('Sites.SiteMetadataEditing.RatingValidation', { ns: 'settingsAsset' })
                      }
                    }
                    )}
                    placeholder={t('Sites.SiteMetadataEditing.RatingPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.epc?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.GRESB', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('gresb', {
                      pattern: {
                        value: /\d{1,3}/,
                        message: t('Sites.SiteMetadataEditing.GRESBValidation', { ns: 'settingsAsset' })
                      },
                      max: {
                        value: 100,
                        message: t('Sites.SiteMetadataEditing.GRESBValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.GRESBValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.GRESBPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.gresb?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.Fitwel', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('fitwel', {
                      pattern: {
                        value: /\d{2,3}/,
                        message: t('Sites.SiteMetadataEditing.FitwelValidation', { ns: 'settingsAsset' })
                      },
                      max: {
                        value: 144,
                        message: t('Sites.SiteMetadataEditing.FitwelValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 90,
                        message: t('Sites.SiteMetadataEditing.FitwelValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.FitwelPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.fitwel?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.BREEAM', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('breeam', {
                      pattern: {
                        value: /\d{1,3}/,
                        message: t('Sites.SiteMetadataEditing.BREEAMValidation', { ns: 'settingsAsset' })
                      },
                      max: {
                        value: 100,
                        message: t('Sites.SiteMetadataEditing.BREEAMValidation', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.BREEAMValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.BREEAMPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.breeam?.message}</ErrorMessage>
                </div>
              </div>
            </Card>

            <Card cardTitle={t('Sites.SiteMetadataEditing.Emissions', { ns: 'settingsAsset' })}>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.BaselineCarbonEmission', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}</Label>
                  <Input
                    {...register('baselineCarbon', {
                      pattern: {
                        value: /[0-9]/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.BaselineCarbonEmissionPlaceholder', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.baselineCarbon?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.TargetCarbonEmission', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}</Label>
                  <Input
                    {...register('targetCarbon', {
                      pattern: {
                        value: /[0-9]/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.TargetCarbonEmissionPlaceholder', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.targetCarbon?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.ElectricityCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightIntensity) })}</Label>
                  <Input
                    {...register('electricityCarbonIntensity', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.ElectricityCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.electricityCarbonIntensity?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.HeatingCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightIntensity) })}</Label>
                  <Input
                    {...register('heatingCarbonIntensity', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.HeatingCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.heatingCarbonIntensity?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.WaterCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
                  <Input
                    {...register('waterCarbonIntensity', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.WaterCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.waterCarbonIntensity?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.WasteWaterCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
                  <Input
                    {...register('wasteWaterCarbonIntensity', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.WasteWaterCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.wasteWaterCarbonIntensity?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.GasCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
                  <Input
                    {...register('gasCarbonIntensity', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Sites.SiteMetadataEditing.GasCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.gasCarbonIntensity?.message}</ErrorMessage>
                </div>
              </div>
            </Card>
            <Card cardTitle={t('Sites.SiteMetadataEditing.YearlyConsumptionTargets', { ns: 'settingsAsset' })}>
              <div className="row" style={{ marginBottom: '15px' }}>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.Electricity', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('electricityConsumptionTarget', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.electricityConsumptionTarget?.message}</ErrorMessage>
                </div>
              </div>

              <div style={{ marginBottom: '35px' }}>
                <Controller
                  control={control}
                  name={'electricityConsumptionTargetMonthlyWeighting'}
                  render={({ field: { onChange, value } }) => (
                    <MonthlyWeightingInput
                      weighting={value}
                      onChange={(weighting) => onChange(weighting)}
                      readOnly={!editMode}
                    />
                  )}
                />
              </div>

              <div className="row" style={{ marginBottom: '15px' }}>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.Heating', { ns: 'settingsAsset' })}</Label>
                  <Input
                    {...register('heatingConsumptionTarget', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.heatingConsumptionTarget?.message}</ErrorMessage>
                </div>
              </div>

              <div style={{ marginBottom: '25px' }}>
                <Controller
                  control={control}
                  name={'heatingConsumptionTargetMonthlyWeighting'}
                  render={({ field: { onChange, value } }) => (
                    <MonthlyWeightingInput
                      weighting={value}
                      onChange={(weighting) => onChange(weighting)}
                      readOnly={!editMode}
                    />
                  )}
                />
              </div>

              <div className="row" style={{ marginBottom: '15px' }}>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.Gas', { ns: 'settingsAsset', unit: getUnit(MetricType.GasVolume) })}</Label>
                  <Input
                    {...register('gasConsumptionTarget', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.gasConsumptionTarget?.message}</ErrorMessage>
                </div>
              </div>

              <div style={{ marginBottom: '25px' }}>
                <Controller
                  control={control}
                  name={'gasConsumptionTargetMonthlyWeighting'}
                  render={({ field: { onChange, value } }) => (
                    <MonthlyWeightingInput
                      weighting={value}
                      onChange={(weighting) => onChange(weighting)}
                      readOnly={!editMode}
                    />
                  )}
                />
              </div>

              <div className="row" style={{ marginBottom: '15px' }}>
                <div className="col-md-6">
                  <Label>{t('Sites.SiteMetadataEditing.Water', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}</Label>
                  <Input
                    {...register('waterConsumptionTarget', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
                      },
                      min: {
                        value: 1,
                        message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    readOnly={!editMode}
                  />
                  <ErrorMessage>{errors.waterConsumptionTarget?.message}</ErrorMessage>
                </div>
              </div>

              <div style={{ marginBottom: '25px' }}>
                <Controller
                  control={control}
                  name={'waterConsumptionTargetMonthlyWeighting'}
                  render={({ field: { onChange, value } }) => (
                    <MonthlyWeightingInput
                      weighting={value}
                      onChange={(weighting) => onChange(weighting)}
                      readOnly={!editMode}
                    />
                  )}
                />
              </div>
            </Card>

          </Masonry>
        </Form>
      </PaddedContainer>
    </>
  );
}

export default SiteMetadataEdit;

const FlexRow = styled.div`
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 5px;
      margin-bottom: 20px;
      `;