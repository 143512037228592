import styled from 'styled-components';

type LabelProps = {
  text: string;
  inputName?: string;
  marginBottom?: boolean;
}

const Label = ({text, inputName, marginBottom}: LabelProps) => {
  return (
    <StyledLabel htmlFor={inputName} marginBottom={marginBottom}>{text}</StyledLabel>
  )
};

export default Label;

const StyledLabel = styled.label<{marginBottom?: boolean}>`
  font-size: 14px;
  color: ${(p) => p.theme.palette.forms.label.color};
  display: block;
  margin-bottom: ${(p) => p.marginBottom ? '8px' : '0'};
`;