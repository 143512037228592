import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useModal } from '@shared/hooks/useModal';
import OnboardingModal from './OnboardingModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

export const OnboardingModalCookie = 'hide-onboarding-modal';

const Onboarding = () => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const autoShowModal = !Cookie.get(OnboardingModalCookie);
  const { ref, isOpen, toggle, setIsOpen } = useModal({ disableCloseOnClickOutside: autoShowModal, disableCloseOnEscape: autoShowModal });

  // Automatically show the modal if a cookie is not yet set
  useEffect(() => {
    if (!Cookie.get(OnboardingModalCookie)) {
      setIsOpen(true);
    }
  }, [setIsOpen]);

  const handleToggle = () => {
    toggle();
    trackAction('whats_new_open', 'whats_new');
  };

  return (
    <Container>
      <OnboardingModal
        modalRef={ref}
        isOpen={isOpen}
        toggle={toggle}
      />

      <Wrapper onClick={handleToggle}>
        <Icon icon={solid('gift')} />
        <Label>
          {t('WhatsNew', { ns: 'common' })}
        </Label>
      </Wrapper>
    </Container>
  );
};

export default Onboarding;

const Container = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  color: ${p => p.theme.success.main};
  cursor: pointer;
  margin-right: 5px;
  border-radius: 20px;
  background-color: ${p => p.theme.success.background};
  display: flex;
  align-items: center;
  gap: 7px;
  height: 28px;
  padding: 0 14px;
  flex-shrink: 0;
  transition: all 150ms ease;

  &:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
  }
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;