import SiteDeleteCommand from '@settings/api/queries/Sites/SiteDeleteCommand';
import SiteImageDeleteCommand from '@settings/api/queries/Sites/SiteImageDeleteCommand';
import SiteImageUploadCommand from '@settings/api/queries/Sites/SiteImageUploadCommand';
import SiteUpdateCommand from '@settings/api/queries/Sites/SiteUpdateCommand';
import { Site } from '@shared/api/models/Site/Site';
import { Button } from '@shared/components/atoms/Button/Button';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { LabelTooltip } from '@shared/components/atoms/LabelToolTip/LabelTooltip';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Select } from '@shared/components/atoms/Select/Select';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { BlobFileUploadArea } from '@shared/components/molecules/BlobFileUploadArea/BlobFileUploadArea';
import { Card } from '@shared/components/molecules/Card/Card';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { RouterPrompt } from '@shared/components/navigation/RouterPrompt/RouterPrompt';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@shared/hooks/useApi';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { useModal } from '@shared/hooks/useModal';
import { getCountries } from '@shared/utils/LocalisationUtils';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { isEmpty, isMatch } from 'lodash';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

const countriesArray = getCountries();

const masonryBreakpointColumns = {
  default: 2,
  1600: 1
};

interface ISiteEdit {
  site: Site,
  onSiteUpdate: (site: Site) => void
}

type FormValues = {
  name: string,
  address: {
    addressLine1: string,
    addressLine2?: string,
    city: string,
    region?: string,
    country: string,
    postCode: string,
  },
  totalAssetArea?: number
}

const SiteEdit = ({ site, onSiteUpdate }: ISiteEdit) => {
  const { t } = useTranslation(['settingsAsset']);
  const { localisation } = useLocalisation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { register, reset, handleSubmit, control, formState: { errors } } = useForm<FormValues>({
    defaultValues: {
      name: site.name,
      address: {
        addressLine1: site.address.addressLine1,
        addressLine2: site.address.addressLine2,
        city: site.address.city,
        region: site.address.region,
        country: site.address.country,
        postCode: site.address.postCode,
      },
      totalAssetArea: site.totalAssetArea
    }
  });
  const { file, fileName, fileHasChanged, fileToBeDeleted, handleFileChange, handleFileDelete, resetFile } = useFileHandler(site.siteImageId);
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteConfirmationDialog, ref: deleteDialogRef } = useModal({});
  const [editMode, setEditMode] = useState(false);
  const { execute } = useApi();

  const exitEditMode = () => {
    setEditMode(false)
    reset();
    resetFile();
  };

  const onSave: SubmitHandler<FormValues> = async data => {
    setEditMode(false);
    let isMounted = true;

    const modifiedSite: Site = {
      ...site,
      name: data.name,
      address: data.address
    };

    if (!isMatch(site, modifiedSite)) {
      const siteDto = await execute({
        query: new SiteUpdateCommand(modifiedSite),
        pendingMessage: t('Sites.SiteEditing.Saving', { ns: 'settingsAsset' }),
        successMessage: t('Sites.SiteEditing.ChangesSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Sites.SiteEditing.SiteUpdateFailed', { ns: 'settingsAsset' })
      });
      isMounted = siteDto !== undefined;
    }

    if (fileHasChanged && file) {
      const res = await execute({
        query: new SiteImageUploadCommand(file, modifiedSite.id),
        successMessage: t('Sites.SiteEditing.ImageSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Sites.SiteEditing.ImageUploadFailed', { ns: 'settingsAsset' })
      });
      modifiedSite.siteImageId = res?.blobName;
      isMounted = res !== undefined;
    }

    if (fileToBeDeleted && site.siteImageId) {
      const res = await execute({
        query: new SiteImageDeleteCommand(site.siteImageId, modifiedSite.id),
        successMessage: t('Sites.SiteEditing.ImageDeleted', { ns: 'settingsAsset' }),
        errorMessage: t('Sites.SiteEditing.ImageDeleteFailed', { ns: 'settingsAsset' })
      });
      modifiedSite.siteImageId = undefined;
      isMounted = res !== undefined;
    }

    if (!isMounted) {
      return;
    }

    onSiteUpdate(modifiedSite);
  }

  const onDelete = async () => {
    await execute({
      query: new SiteDeleteCommand(site.id),
      successMessage: t('Sites.SiteEditing.SiteDeleted', { ns: 'settingsAsset' }),
      errorMessage: t('Sites.SiteEditing.SiteDeleteFailed', { ns: 'settingsAsset' })
    });

    navigate('../../..');
  }

  return (
    <>
      <RouterPrompt when={editMode} />

      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('Sites.SiteEditing.DeleteSiteConfirm', { ns: 'settingsAsset' })}
        confirmButton={t('Sites.SiteEditing.Delete', { ns: 'settingsAsset' })}
        onCancel={toggleDeleteConfirmationDialog}
        onConfirm={onDelete}
      />

      <PaddedContainer>
        <FlexRow>
          {editMode &&
            <>
              <Button
                tertiary
                label={t('Cancel', { ns: 'common' })}
                onClick={() => exitEditMode()}
                color={theme.palette.red}
              />

              <Button
                label={t('Sites.SiteEditing.Save', { ns: 'settingsAsset' })}
                onClick={handleSubmit(onSave)}
                disabled={!isEmpty(errors)}
              />
            </>
          }

          {!editMode &&
            <>
              <Button
                tertiary
                label={t('Sites.SiteEditing.Delete', { ns: 'settingsAsset' })}
                onClick={toggleDeleteConfirmationDialog}
                color={theme.palette.red}
              />

              <Button
                label={t('Sites.SiteEditing.Edit', { ns: 'settingsAsset' })}
                onClick={() => setEditMode(true)}
              />
            </>
          }
        </FlexRow>

        <Form>
          <Masonry
            breakpointCols={masonryBreakpointColumns}
            className="building-edit-masonry-grid"
            columnClassName="building-edit-masonry-grid_column"
          >
            <Card cardTitle={t('Sites.SiteEditing.General', { ns: 'settingsAsset' })}>
              <Form>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <Label>{t('Sites.SiteEditing.Name', { ns: 'settingsAsset' })}</Label>
                      <Input {...register('name', { required: t('Sites.SiteEditing.FieldRequired', { ns: 'settingsAsset' }) })} readOnly={!editMode} />
                      <ErrorMessage>{errors.name?.message}</ErrorMessage>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Label>{t('Sites.SiteEditing.AddressLine1', { ns: 'settingsAsset' })}</Label>
                      <Input {...register('address.addressLine1', { required: t('Sites.SiteEditing.FieldRequired', { ns: 'settingsAsset' }) })} readOnly={!editMode} />
                      <ErrorMessage>{errors.address?.addressLine1?.message}</ErrorMessage>
                    </div>
                    <div className="col-md-6">
                      <Label>{t('Sites.SiteEditing.AddressLine2', { ns: 'settingsAsset' })}</Label>
                      <Input {...register('address.addressLine2', { setValueAs: value => nullIfEmptyString(value) })} readOnly={!editMode} />
                      <ErrorMessage>{errors.address?.addressLine2?.message}</ErrorMessage>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Label>{t('Sites.SiteEditing.City', { ns: 'settingsAsset' })}</Label>
                      <Input {...register('address.city', { required: t('Sites.SiteEditing.FieldRequired', { ns: 'settingsAsset' }) })} readOnly={!editMode} />
                      <ErrorMessage>{errors.address?.city?.message}</ErrorMessage>
                    </div>
                    <div className="col-md-6">
                      <Label>{t('Sites.SiteEditing.Region', { ns: 'settingsAsset' })}</Label>
                      <Input {...register('address.region', { setValueAs: value => nullIfEmptyString(value) })} readOnly={!editMode} />
                      <ErrorMessage>{errors.address?.region?.message}</ErrorMessage>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Label>{t('Sites.SiteEditing.Country', { ns: 'settingsAsset' })}</Label>
                      <Controller
                        control={control}
                        name="address.country"
                        rules={{ required: t('Sites.SiteEditing.FieldRequired', { ns: 'settingsAsset' }) }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={countriesArray.find(country => country.label === value)}
                            isSearchable={true}
                            onChange={selected => selected && onChange(selected.label)}
                            options={countriesArray}
                            readOnly={!editMode}
                          />
                        )}
                      />
                      <ErrorMessage>{errors.address?.country?.message}</ErrorMessage>
                    </div>
                    <div className="col-md-2">
                      <Label>{localisation.localPostcodeName}</Label>
                      <Input {...register('address.postCode', { required: t('Sites.SiteEditing.FieldRequired', { ns: 'settingsAsset' }) })} readOnly={!editMode} />
                      <ErrorMessage>{errors.address?.postCode?.message}</ErrorMessage>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <LabelTooltip
                        tooltip={t('Sites.SiteEditing.TotalAssetAreaDescription', { ns: 'settingsAsset' })}
                        label={t('Sites.SiteEditing.TotalAssetArea', { ns: 'settingsAsset' })}
                        tooltipPlacement={TooltipPlacement.Right}
                        modal
                      />
                      <Input
                        {...register('totalAssetArea',
                          {
                            setValueAs: value => stringToNumber(value)
                          }
                        )}
                        type="number"
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Card>

            <Card cardTitle={t('Sites.SiteEditing.Image', { ns: 'settingsAsset' })}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <BlobFileUploadArea
                      blobName={fileName}
                      mainText={t('Sites.SiteEditing.UploadImage', { ns: 'settingsAsset' })}
                      dimText={'JPEG, PNG, SVG, CAD'}
                      acceptedTypes={['image/*']}
                      onFileChange={handleFileChange}
                      onFileDelete={handleFileDelete}
                      readOnly={!editMode}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Masonry>
        </Form>
      </PaddedContainer>
    </>
  );
}

export default SiteEdit;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-bottom: 20px;
`;