import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@shared/hooks/useApi';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { submitSiteClimateControlForm } from '../ClimateControlUtils';
import TemperatureRanges from '../Setup/TemperatureRanges/TemperatureRanges';
import { TemperatureRangeForm } from '../Setup/types/TemperatureRangeForm';
import SetupInformation from '../SetupInformation';

const TemperatureControls = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const { fromLocale } = useLocalisation();
  const navigate = useNavigate();
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();
  const temperatureRangeFormMethods = useForm<TemperatureRangeForm>({ mode: 'onChange' });
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

  if (!siteClimateControl) {
    return null;
  }

  const onUpdate = async (): Promise<void> => {
    if (savingInProgress) {
      return;
    }

    setSavingInProgress(true);
    try {
      await submitSiteClimateControlForm(siteClimateControl, temperatureRangeFormMethods.getValues(), temperatureRangeFormMethods.formState, fromLocale, t, execute);
    } catch (error) {
      console.error(error);
    } finally {
      refreshSiteClimateControl();
      setSavingInProgress(false);
      navigate('./..');
    }
  };

  return (
    <ResponsiveContainer>
      <Title
        size='lg'
        text={t('ClimateControl.EditTemperatureControls', { ns: 'molecules' })}
      />
      <FormProvider {...temperatureRangeFormMethods}>
        <TemperatureRanges siteClimateControl={siteClimateControl} />
      </FormProvider>
      <SetupInformation />
      <ButtonContainer>
        <Button
          tertiary
          label={t('Cancel', { ns: 'common' })}
          onClick={() => navigate('./..')}
          color={theme.palette.red}
        />
        <Button
          label={t('Update', { ns: 'common' })}
          onClick={onUpdate}
          disabled={savingInProgress}
          analytics={{ action: 'update_temperature_controls', category: 'climate_control' }}
        />
      </ButtonContainer>
    </ResponsiveContainer>
  )
}

export default TemperatureControls

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  @media print {
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;