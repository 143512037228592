import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const TableRow = styled.div<{ wrapColumns?: boolean }>` 
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  ${p => p.wrapColumns && css`
    padding: 10px 20px;
  `}
`;

export const TableColumn = styled.div<{ width?: number, fixedWidth?: string, rightAlign?: boolean, wrapColumns?: boolean, compact?: boolean }>`
  display: flex;
  align-items: center;
  
  padding: ${p => p.compact ? '0 8px' : '0 15px'};
  
  &:first-child {
    padding: ${p => p.compact ? '0 8px 0 15px' : '0 15px 0 30px'};
  }

  &:last-child {
    padding: ${p => p.compact ? '0 15px 0 8px' : '0 30px 0 15px'};
  }

  ${p => p.rightAlign && css`
    margin-left: auto;
    text-align: right;
  `}

  ${p => p.wrapColumns && css`
    width: 100%;
    padding: 0 15px !important;
  `}

  ${p => !p.wrapColumns && css`
    ${!p.fixedWidth && !p.width && css`
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    `}

    ${p.width && css`
      flex: 0 0 ${`calc(100% / 12 * ${p.width})`};
      max-width: ${p.width};
    `}

    ${p.fixedWidth && css`
      width: ${p.fixedWidth};
      flex-shrink: 0;
    `}
  `}
`;

export const TableValue = styled.div<{ rightAlign?: boolean, wrapColumns?: boolean, compact?: boolean, fitContent?: boolean, textValue?: string | undefined }>`
  font-size: 14px;
  font-weight: 400;
  height: ${p => {
    if (p.compact) return '40px';
    if (p.fitContent) return 'fit-content';
    return '65px'
  }};

  padding: ${p => p.fitContent ? '23.5px 0' : '0'};

  color: ${p => ((p.textValue === 'Empty field') || (p.textValue === 'Invalid SpaceType')) ? p.theme.palette.red : 'inherit'};

  display: flex;
  align-items: center;

  ${p => p.rightAlign && css`
    margin-left: auto;
    text-align: right;
  `}

  ${p => p.wrapColumns && css`
    height: auto;
    padding: 2px 0;
  `}
`;

export const TableSelectIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 70px;
  padding: 0 20px 0 30px;
`;

export const TableSelectIcon = styled(FontAwesomeIcon) <{ selected: boolean }>`
  color: ${p => p.selected ? p.theme.palette.primary : p.theme.palette.text.fair};
  font-size: 20px;
  cursor: pointer;
`;