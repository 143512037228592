import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useModal } from '@shared/hooks/useModal';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import BillingMeterUtilityTabs from './BillingMeterUtilityTabs';
import BillingMeterForm from './BillingMeterForm';
import { switchUtilityTab } from './BillingMeterUtils';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';

export type EnergyMeterBillFormValues = {
  meterNumber: string,
  location: string | undefined,
  meterReading: string,
  consumption: string,
  periodStartDate: Date | undefined,
  periodEndDate: Date | undefined,
  measuredOn: Date | undefined,
  billingMeter: boolean
};

const UtilityConsumptionCreate = () => {
  const [selected, setSelected] = useState<number>(0);
  const [selectedUtility, setSelectedUtility] = useState<EnergyMeterType>(EnergyMeterType.Electricity);
  const [nextSelected, setNextSelected] = useState<number>(0);
  const [nextSelectedUtility, setNextSelectedUtility] = useState<EnergyMeterType>(EnergyMeterType.Electricity);
  const { t } = useTranslation();
  const theme = useTheme();
  const lineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const formMethods = useForm<EnergyMeterBillFormValues>();
  const { reset } = formMethods;
  const [hasConsumption, setHasConsumption] = useState(false);
  const [hasMeterReading, setHasMeterReading] = useState(false);
  const [hasMeterNumber, setHasMeterNumber] = useState(false);

  const { isOpen, toggle, ref } = useModal({});

  useEffect(() => {
    switchUtilityTab(selected, tabRefs, lineRef)
  }, [selected]);

  const handleTabClick = (utility: EnergyMeterType, currentIndex: number) => {
    setSelected(currentIndex);
    setSelectedUtility(utility);
  };

  const handleUtilityDialogWarningToggle = (nextSelectedUtility: EnergyMeterType, nextIndex: number) => {
    setNextSelected(nextIndex);
    setNextSelectedUtility(nextSelectedUtility)

    toggle();
  }

  const handleUtilityWarningDialogConfirm = () => {
    reset({
      meterNumber: '',
      location: '',
      meterReading: '',
      consumption: '',
      periodStartDate: undefined,
      periodEndDate: undefined,
      measuredOn: undefined
    })
    setHasConsumption(false);
    setHasMeterReading(false);
    setSelected(nextSelected);
    setSelectedUtility(nextSelectedUtility);
    toggle();
  }

  return (
    <>
      <WarningDialog
        modalRef={ref}
        isOpen={isOpen}
        sectionOne={t('UtilityConsumption.TabSwitchConfirmationMessage', { ns: 'molecules' })}
        sectionTwo={t('UtilityConsumption.TabSwitchConfirmationSubText', { ns: 'molecules' })}
        confirmButton={t('Leave', { ns: 'common' })}
        confirmButtonColor={theme.palette.primary}
        cancelButton={t('Stay', { ns: 'common' })}
        onCancel={toggle}
        onConfirm={() => { handleUtilityWarningDialogConfirm() }}
      />

      <PaddedContainer centered>
        <Container>
          <BackButton
            label={t('BackToBills', { ns: 'navigation' })}
            url='./..'
          />

          <Card
            cardTitle={t('UtilityConsumption.AddBill', { ns: 'molecules' })}
            noPadding
          >
            <BillingMeterUtilityTabs
              tabRefs={tabRefs}
              selected={selected}
              onTabClick={handleTabClick}
              lineRef={lineRef}
              hasMeterNumber={hasMeterNumber}
              hasMeterReading={hasMeterReading}
              hasConsumption={hasConsumption}
              onUtilityDialogWarningToggle={handleUtilityDialogWarningToggle}
            />
            <FormProvider {...formMethods}>
              <BillingMeterForm
                selectedUtility={selectedUtility}
                setSelected={setSelected}
                setSelectedUtility={setSelectedUtility}
                setNextSelected={setNextSelected}
                setNextSelectedUtility={setNextSelectedUtility}
                setHasMeterReading={setHasMeterReading}
                setHasConsumption={setHasConsumption}
                hasMeterReading={hasMeterReading}
                hasConsumption={hasConsumption}
                setHasMeterNumber={setHasMeterNumber}
              />
            </FormProvider>
          </Card>
        </Container>
      </PaddedContainer>
    </>
  );
}

export default UtilityConsumptionCreate;

const Container = styled.div` 
  width: 100%;
  max-width: 520px;
`;