import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { groupBy, lowerCase, orderBy, sortBy, upperFirst } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AlertRuleFlexChild, AlertRuleFlexRow, AlertRuleLabel } from '../AlertRuleConfiguration';
import AlertRuleCard from '../AlertRuleCard';
import styled, { css, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { AlertRuleFilter, DeviceFilterType } from '@shared/api/models/AlertRule/AlertRule';
import { ICascaderMultiSelectNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import { HierarchyLocationType } from '@shared/api/enums/HierarchyLocationType/HierarchyLocationType';
import { LettingStatus } from '@shared/api/enums/LettingStatus/LettingStatus';
import { ICheckboxSelectGroup } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect.types';
import { useApiState } from '@shared/hooks/useApiState';
import SitesGetAllQuery from '@settings/api/queries/Sites/SitesGetAllWithHierarchyQuery';
import { Device } from '@shared/api/models/Device/Device';
import { CascaderMultiSelect } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect';
import { CheckboxSelect } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect';
import { Select } from '@shared/components/atoms/Select/Select';
import { DeviceModel } from '@shared/api/enums/DeviceModel/DeviceModel';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { IModalListSelectGroup } from '@shared/components/molecules/ModalListSelect/ModalListSelect.types';
import { alphabeticalSort, includesCI, substringNCharWithEllipsis } from '@shared/utils/StringUtils';
import { useModal } from '@shared/hooks/useModal';
import { ModalListSelect } from '@shared/components/molecules/ModalListSelect/ModalListSelect';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

interface IDevices {
  enabled?: boolean;
  filters: AlertRuleFilter;
  onChange: (config: AlertRuleFilter) => void
}

const FilterConfiguration = ({ enabled, filters, onChange }: IDevices) => {
  const { t } = useTranslation(['settingsAlerting']);
  const { site } = useSiteContext();
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<{ type: HierarchyLocationType, id: number }>>();
  const [lettingStatusOptions, setLettingStatusOptions] = useState<ICheckboxSelectGroup<LettingStatus>[]>([]);
  const { data: sites } = useApiState({
    query: new SitesGetAllQuery(),
    initialState: [],
  }, []);

  useEffect(() => {
    setLettingStatusOptions([{
      options: Object.values(LettingStatus).map(x => ({ label: x, value: x, selected: filters.lettingStatuses.includes(x) }))
    }]);
  }, [filters.lettingStatuses]);

  const inHierarchy = useCallback((type: HierarchyLocationType, id: number) => {
    return filters.hierarchy.some(x => x.type === type && x.id === id);
  }, [filters.hierarchy]);

  useEffect(() => {
    const options: ICascaderMultiSelectNodeGroup<{ type: HierarchyLocationType, id: number }> = {
      header: t('Site', { ns: 'common' }),
      nodes: sites.filter(x => x.id === site.id).map((site) => ({
        label: `${site.name}`,
        value: { type: HierarchyLocationType.Site, id: site.id },
        selected: inHierarchy(HierarchyLocationType.Site, site.id),
        childGroup: {
          header: t('FilterConfiguration.Buildings', { ns: 'settingsAlerting' }),
          nodes: orderBy(site.buildings, x => x.name.toLocaleLowerCase()).map((building) => ({
            label: `${building.name}`,
            value: { type: HierarchyLocationType.Building, id: building.id },
            selected: inHierarchy(HierarchyLocationType.Building, building.id),
            childGroup: {
              header: t('FilterConfiguration.Floors', { ns: 'settingsAlerting' }),
              nodes: orderBy(building.floors, x => x.floorNumber).map((floor) => ({
                label: `${floor.name}`,
                value: { type: HierarchyLocationType.Floor, id: floor.id },
                selected: inHierarchy(HierarchyLocationType.Floor, floor.id),
                childGroup: {
                  header: t('FilterConfiguration.Spaces', { ns: 'settingsAlerting' }),
                  nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map((space) => ({
                    label: `${space.name}`,
                    value: { type: HierarchyLocationType.Space, id: space.id },
                    selected: inHierarchy(HierarchyLocationType.Space, space.id),
                  }))
                }
              })),
            },
          })),
        },
      })),
    };

    setCascaderOptions(options);
  }, [site.id, sites, inHierarchy, t])

  const handleLocationChange = useCallback((values?: { type: HierarchyLocationType; id: number; }[]) => {
    onChange({
      ...filters,
      hierarchy: values ?? []
    });
  }, [filters, onChange]);

  const getDevices = useCallback((type: HierarchyLocationType, id: number): Device[] => {
    const devices: Device[] = [];
    if (type === HierarchyLocationType.Site) {
      const site = sites.find(s => s.id === id);
      const foundDevices = site ? site.buildings.flatMap(b => b.floors.flatMap(f => f.spaces.flatMap(s => s.devices))) : [];
      devices.push(...foundDevices);
    }

    if (type === HierarchyLocationType.Building) {
      const building = sites.flatMap(s => s.buildings).find(b => b.id === id);
      const foundDevices = building ? building.floors.flatMap(f => f.spaces.flatMap(s => s.devices)) : [];
      devices.push(...foundDevices);
    }

    if (type === HierarchyLocationType.Floor) {
      const floor = sites.flatMap(s => s.buildings.flatMap(b => b.floors)).find(f => f.id === id);
      const foundDevices = floor ? floor.spaces.flatMap(s => s.devices) : [];
      devices.push(...foundDevices);
    }

    if (type === HierarchyLocationType.Space) {
      const space = sites.flatMap(s => s.buildings.flatMap(b => b.floors.flatMap(x => x.spaces))).find(f => f.id === id);
      const foundDevices = space ? space.devices : [];
      devices.push(...foundDevices);
    }

    return devices;
  }, [sites]);

  return (
    <AlertRuleCard
      title={t('FilterConfiguration.LocationAndDevices', { ns: 'settingsAlerting' })}
      open={true}
      icon={light('location-dot')}
      enabled={enabled}
    >
      <AlertRuleFlexRow>
        <AlertRuleFlexChild>
          <AlertRuleLabel>{t('FilterConfiguration.Location', { ns: 'settingsAlerting' })}</AlertRuleLabel>
          {cascaderOptions &&
            <CascaderMultiSelect
              placeholder={t('Select', { ns: 'common' })}
              group={cascaderOptions}
              onChange={handleLocationChange}
              confirmButton={t('Apply', { ns: 'common' })}
              cancelButton={t('Cancel', { ns: 'common' })}
            />
          }
        </AlertRuleFlexChild>

        <AlertRuleFlexChild>
          <AlertRuleLabel>{t('FilterConfiguration.WithLettingStatus', { ns: 'settingsAlerting' })}</AlertRuleLabel>
          <CheckboxSelect
            options={lettingStatusOptions}
            onChange={values =>
              onChange({
                ...filters,
                lettingStatuses: values
              })
            }
            placeholder={t('Select', { ns: 'common' })}
          />
        </AlertRuleFlexChild>

        <AlertRuleFlexChild>
          <AlertRuleLabel>{t('Devices', { ns: 'assets' })}</AlertRuleLabel>
          <Select
            value={filters.devices && { label: upperFirst(lowerCase(filters.devices.filterType)), value: filters.devices.filterType }}
            options={Object.values(DeviceFilterType).map(x => ({ label: upperFirst(lowerCase(x)), value: x }))}
            onChange={selected => selected && onChange({
              ...filters,
              devices: { filterType: selected.value }
            })}
          />
        </AlertRuleFlexChild>
      </AlertRuleFlexRow>

      {filters.devices.filterType === DeviceFilterType.ByModels &&
        <ModelSelect
          deviceModels={filters.devices.models}
          onChange={(models) => onChange({ ...filters, devices: { filterType: DeviceFilterType.ByModels, models: models } })}
        />
      }

      {filters.devices.filterType === DeviceFilterType.CustomList &&
        <DeviceSelect
          devices={sortBy(filters.hierarchy.flatMap(x => getDevices(x.type, x.id)), x => x.deviceModel)}
          identifiers={filters.devices.identifiers}
          onChange={(identifiers) => onChange({ ...filters, devices: { filterType: DeviceFilterType.CustomList, identifiers: identifiers } })}
        />
      }
    </AlertRuleCard>
  );
};

export default FilterConfiguration;

interface IModelSelect {
  deviceModels?: DeviceModel[];
  onChange: (deviceModels: DeviceModel[]) => void;
}

const ModelSelect = ({ deviceModels, onChange }: IModelSelect) => {
  const { t } = useTranslation(['settingsAlerting']);
  const { allDeviceConfigs, getDisplayString } = useDeviceConfigContext();
  const [options, setOptions] = useState<IModalListSelectGroup<DeviceModel>[]>([]);

  useEffect(() => {
    const groupedByTypeAndManufacturer = Object.entries(groupBy(allDeviceConfigs, x => x?.deviceType))
      .sort((a, b) => alphabeticalSort(a[0], b[0]))
      .map(x => ({
        type: x[0],
        group: Object.entries(groupBy(x[1], a => a?.manufacturer))
          .map(y => ({ manufacturer: y[0], deviceModels: y[1] }))
      }));

    const deviceModelOptions: IModalListSelectGroup<DeviceModel>[] = groupedByTypeAndManufacturer
      .map((types) => ({
        label: types.type,
        subGroups: [...types.group]
          .sort((a, b) => alphabeticalSort(a.manufacturer, b.manufacturer))
          .map(manufacturers => ({
            label: manufacturers.manufacturer,
            options: manufacturers.deviceModels.map(deviceModel => ({
              label: getDisplayString(deviceModel.deviceModel),
              value: deviceModel.deviceModel
            }))
          }))
      }));

    const options = deviceModelOptions.map(group => ({
      ...group,
      subGroups: group.subGroups.map(sg => ({
        ...sg,
        options: sg.options.map(opt => ({
          ...opt,
          selected: deviceModels?.includes(opt.value)
        }))
      }))
    }));

    setOptions(options);
  }, [deviceModels, allDeviceConfigs, getDisplayString]);

  return (
    <AlertRuleFlexChild style={{ marginTop: '12px' }}>
      <AlertRuleLabel>{t('FilterConfiguration.DeviceModels', { ns: 'settingsAlerting' })}</AlertRuleLabel>
      <ModalListSelect
        modalWidth={'380px'}
        options={options}
        onChange={(values) => onChange(values)}
        placeholder={t('FilterConfiguration.Select', { ns: 'settingsAlerting' })}
        title={t('FilterConfiguration.FilterModels', { ns: 'settingsAlerting' })}
      />
    </AlertRuleFlexChild>
  );
};

const filterPredicate = (x: Device, searchValue: string) => {
  return includesCI(x.friendlyName, searchValue)
    || includesCI(x.deviceModel, searchValue)
    || includesCI(x.deviceIdentifier, searchValue)
    || includesCI(x.spaceName, searchValue)
};

interface IDeviceSelect {
  devices: Device[];
  identifiers?: string[];
  onChange: (identifiers: string[]) => void;
}

const DeviceSelect = ({ devices, identifiers, onChange }: IDeviceSelect) => {
  const { t } = useTranslation(['settingsAlerting']);
  const theme = useTheme();
  const { getDisplayString } = useDeviceConfigContext();
  const { isOpen, toggle, ref } = useModal({});
  const [searchString, setSearchString] = useState<string>('');

  const setAllTo = (selected: boolean) => {
    if (selected) {
      onChange(devices.map(x => x.deviceIdentifier));
    } else {
      onChange([]);
    }
  };

  return (
    <>
      <AlertRuleFlexChild style={{ marginTop: '12px' }}>
        <AlertRuleLabel>{t('FilterConfiguration.Devices', { ns: 'settingsAlerting' })}</AlertRuleLabel>
        <SelectField onClick={toggle}>
          {(!identifiers || identifiers.length === 0) &&
            <Placeholder>
              {t('FilterConfiguration.SelectDevices', { ns: 'settingsAlerting' })}
            </Placeholder>
          }

          {identifiers?.map((identifier, i) => (
            <SelectedLabel key={i}>
              {substringNCharWithEllipsis(identifier, 20)}
            </SelectedLabel>
          ))}
        </SelectField>
      </AlertRuleFlexChild>

      <Modal
        ref={ref}
        isOpen={isOpen}
        plainModal
        width="900px"
        bodyStyles={{ padding: 0 }}
        modalStyles={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
        dialogStyles={{ boxShadow: `0px 2px 12px 1px ${theme.palette.shadows.strong}` }}
      >
        <ModalHeader>
          <FlexRow>
            {t('FilterConfiguration.FilterDevices', { ns: 'settingsAlerting' })}
            <CloseButton onClick={toggle}>&times;</CloseButton>
          </FlexRow>
          <FlexRow style={{ margin: '12px 0 8px 0', alignItems: 'end' }}>
            <HeaderButton onClick={() => setAllTo(true)}>
              <SelectClearIcon icon={regular('square-check')} />
              {t('FilterConfiguration.SelectAll', { ns: 'settingsAlerting' })}
            </HeaderButton>
            <HeaderButton onClick={() => setAllTo(false)}>
              <SelectClearIcon icon={regular('square')} />
              {t('FilterConfiguration.Clear', { ns: 'settingsAlerting' })}
            </HeaderButton>
            <div style={{ marginLeft: 'auto' }} />
            <SearchField
              placeholder={t('FilterConfiguration.SearchDevices', { ns: 'settingsAlerting' })}
              onSearchChange={setSearchString}
            />
          </FlexRow>
        </ModalHeader>

        <Table>
          <TitleRow>
            <div style={{ width: '20px' }} />
            <div style={{ width: '200px' }}>
              {t('FilterConfiguration.FriendlyName', { ns: 'settingsAlerting' })}
            </div>
            <div style={{ width: '200px' }}>
              {t('FilterConfiguration.DeviceModel', { ns: 'settingsAlerting' })}
            </div>
            <div style={{ width: '200px' }}>
              {t('FilterConfiguration.DeviceIdentifier', { ns: 'settingsAlerting' })}
            </div>
            <div style={{ width: '190px' }}>
              {t('FilterConfiguration.Location', { ns: 'settingsAlerting' })}
            </div>
          </TitleRow>

          <ScrollContainer>
            {devices.filter(x => filterPredicate(x, searchString)).map((device) => {
              const selected = identifiers?.includes(device.deviceIdentifier);

              return (
                <RowWrapper
                  key={device.id}
                  onClick={() => {
                    const clone = identifiers ? [...identifiers] : [];

                    if (selected) {
                      onChange(clone.filter(x => x !== device.deviceIdentifier))
                    } else {
                      onChange([...clone, device.deviceIdentifier]);
                    }
                  }}
                >
                  <StyledRow>
                    <div style={{ width: '20px' }}>
                      <Checkbox selected={selected}>
                        {selected &&
                          <CheckIcon icon={solid('check')} />
                        }
                      </Checkbox>
                    </div>
                    <div style={{ width: '200px' }}>
                      {device.friendlyName}
                    </div>
                    <div style={{ width: '200px' }}>
                      {getDisplayString(device.deviceModel)}
                    </div>
                    <div style={{ width: '200px' }}>
                      {device.deviceIdentifier}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {device.spaceName}
                    </div>
                  </StyledRow>
                </RowWrapper>
              );
            })}
          </ScrollContainer>
        </Table>
      </Modal>
    </>
  );
};

const ModalHeader = styled.div<{ titleColor?: string }>`
  padding: 10px 16px;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 4px 4px 0 0;
  font-size: 18px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const CloseButton = styled.div`
  color: ${p => p.theme.palette.text.fair};
  margin-left: auto;
  font-size: 25px;

  &:hover, &:focus {
    color: ${p => p.theme.palette.primary};
    text-decoration: none;
    cursor: pointer;
  }
`;

const SelectField = styled.div`
  height: 38px;
  cursor: pointer;
  overflow: auto;  
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: auto;
  
  color: ${p => p.theme.palette.text.weak};
  background-color: transparent;
  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 4px;
  padding: 0 8px;

  &:hover {
    border-color: ${p => p.theme.palette.primary};
  }
`;

const Placeholder = styled.div`
  font-size: 14px;
  margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedLabel = styled.div`
  display: flex;
  align-items: center;
  min-width: max-content;
  padding: 1px 7px;

  font-size: 14px;
  font-weight: 400;

  border-radius: 5px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};
  transition: all 150ms ease;

  &:not(:first-child) {
    margin-left: 4px;
  }
`;

const ScrollContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  border-radius: 4px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 13px 15px;

  font-weight: 500;
  box-shadow: 0 6px 7px -4px ${p => p.theme.palette.shadows.medium};
  z-index: 1;

  > * {
    flex-shrink: 0;
  }
`;

const RowWrapper = styled.div`
  min-width: 100%;
  width: max-content;
  padding: 10px 15px;

  &:nth-child(odd) {
    background-color: ${(p) => p.theme.palette.tables.evenRow};
  }

  &:nth-child(even) {
    background-color: ${(p) => p.theme.palette.tables.unevenRow};
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  > * {
    flex-shrink: 0;
    word-wrap: break-word;
  }
`;

const Checkbox = styled.div<{ selected?: boolean }>`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border: 1px solid ${p => p.theme.palette.cascader.checkboxBorder};
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 8px;
  cursor: pointer;
  transition: border-color 150ms ease;

  display: flex;
  justify-content: center;
  align-items: center;

  ${p => !p.selected && css`
    &:hover {
      border-color: ${p => transparentize(0, p.theme.palette.primary)};
    }
  `}

  ${p => p.selected && css`
    background-color: ${p => p.theme.palette.primary};
    border-color: ${p => p.theme.palette.primary};
  `}
`;

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 11px;
  color: ${p => p.theme.palette.text.onPrimary};
`;

const HeaderButton = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const SelectClearIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.cascader.checkboxBorder};
  cursor: pointer;
  margin-left: auto;
  
  ${HeaderButton}:hover & {
    color: ${p => transparentize(0.2, p.theme.palette.primary)};
  }
`;