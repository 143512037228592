import { MetricType } from './MetricType';

/**
 * Get color to use on charts
 */
export const MetricType_ChartColor = (metricType: MetricType): string => {
  return metricTypeToChartColor[metricType] ?? '#c5d5e6';
};

/**
 * Map MetricType to a color to use on charts
 */
const metricTypeToChartColor: { [key in MetricType]: string } = Object.freeze({
  /** Common */
  [MetricType.BatteryVoltage]: '#7020FF',
  [MetricType.BatteryPercentage]: '#7020FF',
  [MetricType.RSSI]: '#7020FF',
  [MetricType.SNR]: '#7020FF',
  [MetricType.SignalStrength]: '#7020FF',
  [MetricType.DataRate]: '#7020FF',
  [MetricType.DeviceLastMeasuredOn]: '#7020FF',

  /** Environmental */
  [MetricType.CO2]: '#44A901',
  [MetricType.OutdoorHumidity]: '#00AEED',
  [MetricType.Humidity]: '#00AEED',
  [MetricType.Light]: '#FFAA37',
  [MetricType.LightLevel]: '#FFAA37',
  [MetricType.Noise]: '#FF4B5A',
  [MetricType.NoiseRaw]: '#FF4B5A',
  [MetricType.PIR]: '#7020FF',
  [MetricType.OutdoorTemperature]: '#516EFF',
  [MetricType.Temperature]: '#516EFF',
  [MetricType.MotionMeasuredOn]: '#f67045',
  [MetricType.VOC]: '#FF4B5A',
  [MetricType.Pressure]: '#00AEED',
  [MetricType.CO]: '#44A901',
  [MetricType.O2]: '#00AEED',
  [MetricType.H2S]: '#44A901',
  [MetricType.Pollutants]: '#f67045',
  [MetricType.Particulates10]: '#f67045',
  [MetricType.Particulates2_5]: '#f67045',
  [MetricType.NO]: '#44A901',
  [MetricType.NO2]: '#44A901',
  [MetricType.IAQ]: '#00AEED',
  [MetricType.HCHO]: '#e7071a',
  [MetricType.O3]: '#44A901',
  [MetricType.WaterLeak]: '#1B98FF',

  /** Wellness */
  [MetricType.CO2Score]: '#f67045',
  [MetricType.HumidityScore]: '#00AEED',
  [MetricType.LightScore]: '#FFAA37',
  [MetricType.NoiseScore]: '#FF4B5A',
  [MetricType.TemperatureScore]: '#516EFF',
  [MetricType.WellnessScore]: '#44A901',

  /** Energy Monitoring */
  [MetricType.Emissions]: '#f67045',
  [MetricType.ElectricityKwh]: '#FFB500',
  [MetricType.ElectricityKwhDelta]: '#FFB500',
  [MetricType.ElectricityGeneratedKwh]: '#FFB500',
  [MetricType.ElectricityGeneratedKwhDelta]: '#FFB500',
  [MetricType.ElectricityKwhCost]: '#FFB500',
  [MetricType.GasVolume]: '#00C851',
  [MetricType.GasVolumeDelta]: '#00C851',
  [MetricType.GasVolumeCost]: '#00C851',
  [MetricType.GasKwh]: '#00C851',
  [MetricType.GasKwhDelta]: '#00C851',
  [MetricType.HeatingKwh]: '#FF821B',
  [MetricType.HeatingKwhDelta]: '#FF821B',
  [MetricType.HeatingKwhCost]: '#FF821B',
  [MetricType.WaterVolume]: '#1B98FF',
  [MetricType.WaterVolumeDelta]: '#1B98FF',
  [MetricType.WaterVolumeCost]: '#1B98FF',
  [MetricType.CarbonIntensityElectricity]: '#f67045',
  [MetricType.CarbonIntensityElectricitySaved]: '#f67045',
  [MetricType.CoolingKwh]: '#0074e4',
  [MetricType.CoolingKwhDelta]: '#0074e4',
  [MetricType.CoolingKwhCost]: '#0074e4',
  [MetricType.CarbonIntensityCooling]: '#0074e4',

  /** Three phase meters*/
  [MetricType.ElectricityL1Voltage]: '#7020FF',
  [MetricType.ElectricityL2Voltage]: '#00AEED',
  [MetricType.ElectricityL3Voltage]: '#ffa600',

  [MetricType.ElectricityL1Current]: '#7020FF',
  [MetricType.ElectricityL2Current]: '#00AEED',
  [MetricType.ElectricityL3Current]: '#ffa600',

  [MetricType.ElectricityL1PowerFactor]: '#7020FF',
  [MetricType.ElectricityL2PowerFactor]: '#00AEED',
  [MetricType.ElectricityL3PowerFactor]: '#ffa600',

  [MetricType.ElectricityL1ActivePower]: '#516EFF',
  [MetricType.ElectricityL2ActivePower]: '#999afe',
  [MetricType.ElectricityL3ActivePower]: '#ccc8fc',

  [MetricType.ElectricityTotalActivePower]: '#FFB500',
  [MetricType.ElectricityMaxTotalPowerDemand]: '#FFB500',
  [MetricType.ElectricityMaxL1CurrentDemand]: '#FFB500',
  [MetricType.ElectricityMaxL2CurrentDemand]: '#FFB500',
  [MetricType.ElectricityMaxL3CurrentDemand]: '#FFB500',
  [MetricType.ElectricityMaxNCurrentDemand]: '#FFB500',

  [MetricType.ElectricityTotalKwh]: '#FFB500',

  [MetricType.ElectricityL1ImportKwh]: '#44a901',
  [MetricType.ElectricityL2ImportKwh]: '#85c360',
  [MetricType.ElectricityL3ImportKwh]: '#badca3',

  [MetricType.ElectricityL1ExportKwh]: '#FFB500',
  [MetricType.ElectricityL2ExportKwh]: '#ffc86a',
  [MetricType.ElectricityL3ExportKwh]: '#fedcae',

  [MetricType.ElectricityL1TotalKwh]: '#FFB500',
  [MetricType.ElectricityL2TotalKwh]: '#FFB500',
  [MetricType.ElectricityL3TotalKwh]: '#FFB500',

  [MetricType.ElectricityGeneratedL1KwhDelta]: '#44a901',
  [MetricType.ElectricityGeneratedL2KwhDelta]: '#85c360',
  [MetricType.ElectricityGeneratedL3KwhDelta]: '#badca3',

  [MetricType.ElectricityConsumedL1KwhDelta]: '#FFB500',
  [MetricType.ElectricityConsumedL2KwhDelta]: '#ffc86a',
  [MetricType.ElectricityConsumedL3KwhDelta]: '#fedcae',

  /** Occupancy */
  [MetricType.IsolatedPeopleCount]: '#7020FF',
  [MetricType.EnterCount]: '#7020FF',
  [MetricType.ExitCount]: '#7020FF',
  [MetricType.CalculatedOccupancy]: '#7020FF',

  /** Relay */
  [MetricType.RelayOutputStatus]: '#7020FF',

  /** Switch */
  [MetricType.ContactSwitch]: '#7020FF',

  /** Wavetrend */
  [MetricType.Sensor1Temp]: '#516EFF',
  [MetricType.Sensor2Temp]: '#516EFF',
  [MetricType.Sensor3Temp]: '#516EFF',
  [MetricType.Sensor1MinTemp]: '#00AEED',
  [MetricType.Sensor1MaxTemp]: '#FF4B5A',
  [MetricType.Sensor1FlowCount]: '#7020FF',
  [MetricType.Sensor1Compliant]: '#44A901',
  [MetricType.Sensor1Error]: '#ffa600',
  [MetricType.Sensor2MinTemp]: '#00AEED',
  [MetricType.Sensor2MaxTemp]: '#FF4B5A',
  [MetricType.Sensor2FlowCount]: '#7020FF',
  [MetricType.Sensor2Compliant]: '#44A901',
  [MetricType.Sensor2Error]: '#ffa600',
  [MetricType.Sensor3MinTemp]: '#00AEED',
  [MetricType.Sensor3MaxTemp]: '#FF4B5A',
  [MetricType.Sensor3FlowCount]: '#7020FF',
  [MetricType.Sensor3Compliant]: '#44A901',
  [MetricType.Sensor3Error]: '#ffa600',
  [MetricType.SensorScald]: '#FF4B5A',
  [MetricType.SensorScaldTemp]: '#FF4B5A',
  [MetricType.SensorFreeze]: '#00AEED',
  [MetricType.SensorFreezeTemp]: '#00AEED',
  [MetricType.ErrorMessage]: '#ffa600',

  /** Weather */
  [MetricType.WindSpeedAverage]: '#00AEED',
  [MetricType.WindDirectionAverage]: '#00AEED',

  /** IoTSens Sound Monitor */
  [MetricType.Percentile_P1]: '#2B9348',
  [MetricType.Percentile_P10]: '#55A630',
  [MetricType.Percentile_P50]: '#80B918',
  [MetricType.Percentile_P90]: '#AACC00',
  [MetricType.Percentile_P99]: '#eeee0d',
  [MetricType.DBA_Min]: '#FF4B5A',
  [MetricType.LAEQ]: '#7020FF',
  [MetricType.DBA_Max]: '#00AEED',

  /** Axioma Water Meter */
  [MetricType.WaterMeterAlarm]: '#FF4B5A',

  /**External Metrics */
  [MetricType.CarbonIntensityForecast]: '#f67045',

  /**Vutility HotDrop */
  [MetricType.AmpHourAccumulation]: '#FF4B5A',
  [MetricType.AverageAmps]: '#44A901',
  [MetricType.MaximumAmps]: '#44A901',
  [MetricType.MinimumAmps]: '#44A901',
  [MetricType.CapacitorVoltage]: '#7020FF',

  /**Utopi Eastron SDM230 */
  [MetricType.ElectricityMaxImportPowerDemand]: '#FFB500',
  [MetricType.ElectricityMaxExportPowerDemand]: '#FFB500',
  [MetricType.ElectricityExportKwhDelta]: '#44a901',
  [MetricType.ElectricityExportKwh]: '#FFB500',

  /** MClimate Vicki TRV */
  [MetricType.TemperatureTarget]: '#516EFF',
  [MetricType.MinTemperature]: '#516EFF',
  [MetricType.MaxTemperature]: '#516EFF',
  [MetricType.ManualTargetTemperatureUpdate]: '#516EFF',
  [MetricType.WindowPosition]: '#516EFF',
  [MetricType.ChildLock]: '#516EFF',
  [MetricType.MotorRange]: '#516EFF',
  [MetricType.MotorPosition]: '#516EFF',
  [MetricType.HighMotorConsumption]: '#516EFF',
  [MetricType.LowMotorConsumption]: '#516EFF',
  [MetricType.BrokenSensor]: '#516EFF',
  [MetricType.CalibrationFailed]: '#516EFF',
  [MetricType.AttachedBackplate]: '#516EFF',
  [MetricType.PerceiveAsOnline]: '#516EFF',
  [MetricType.OperationalMode]: '#516EFF',
  [MetricType.KeepAliveTime]: '#516EFF',

  /**Axioma Qalcasonic E3 */
  [MetricType.HeatingTemperature]: '#FF821B',
  [MetricType.HeatingPower]: '#FFB500',
  [MetricType.HeatingVolume]: '#FFB500',
  [MetricType.CoolingTemperature]: '#0074e4'
});