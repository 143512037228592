import Filter from './Filter/Filter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from '@shared/api/models/Device/Device';
import { orderBy } from 'lodash';

type PropTypes = {
  devices: Device[];
  onChange: (selected: string[]) => void;
  resetPulse?: number;
}

const DeviceModelFilter = ({ devices, onChange, resetPulse }: PropTypes) => {
  const { t } = useTranslation();

  const uniqueModels = useMemo(() => Array.from(new Set(devices.map(x => x.deviceModel))), [devices]);
  const options = useMemo(() => orderBy(uniqueModels, x => x).map(x => ({ label: t(`${x}.DisplayString`, { ns: 'deviceConfigs' }), value: x })), [uniqueModels, t]);

  return (
    <Filter
      label={t('DeviceModel', { ns: 'common' })}
      options={options}
      onChange={onChange}
      resetPulse={resetPulse}
    />
  );
};

export default DeviceModelFilter;