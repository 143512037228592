import { stringToFloat, stringToPercentage } from '@shared/utils/NumberUtils';
import { format } from 'date-fns';
import { MetricType } from './MetricType';
import { TFunction } from 'i18next';
import { getWindDirectionLabel } from '@shared/utils/WeatherUtils';

/**
 * Get value display string (including prefix/suffix)
 */
export const MetricType_ValueDisplayString = ({ metricType, value, t }: { metricType: MetricType; value: string; t?: TFunction }): string => {
  const modifierFunc = metricTypeToValueDisplayStringModifierFunction[metricType];
  return modifierFunc !== undefined ? modifierFunc(value, t) : value;
};

/**
 * Map MetricType to the appropriate typed value
 */
const metricTypeToValueDisplayStringModifierFunction: { [key in MetricType]: (x: string, t?: TFunction) => string; } = Object.freeze({
  /** Common */
  [MetricType.BatteryVoltage]: (x: string) => `${stringToFloat(x, 2)} V`,
  [MetricType.BatteryPercentage]: (x: string) => `${stringToFloat(x, 2)}%`,
  [MetricType.RSSI]: (x: string) => `${stringToFloat(x, 1)}`,
  [MetricType.SNR]: (x: string) => `${stringToFloat(x, 1)}`,
  [MetricType.SignalStrength]: (x: string) => `${stringToFloat(x, 0)}%`,
  [MetricType.DataRate]: (x: string) => x,
  [MetricType.DeviceLastMeasuredOn]: (x: string) => format(new Date(x), 'dd MMM yyyy, HH:mm'),

  /** Environmental */
  [MetricType.CO2]: (x: string) => `${stringToFloat(x, 1)} ppm`,
  [MetricType.OutdoorHumidity]: (x: string) => `${stringToPercentage(x, 0)}%`,
  [MetricType.Humidity]: (x: string) => `${stringToPercentage(x, 0)}%`,
  [MetricType.Light]: (x: string) => `${stringToFloat(x, 1)} lux`,
  [MetricType.LightLevel]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.Noise]: (x: string) => `${stringToFloat(x, 1)} dB`,
  [MetricType.NoiseRaw]: (x: string) => `${stringToFloat(x, 1)} dB`,
  [MetricType.PIR]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.OutdoorTemperature]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.Temperature]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.MotionMeasuredOn]: (x: string) => format(new Date(x), 'dd MMM yyyy, HH:mm'),
  [MetricType.VOC]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.Pressure]: (x: string) => `${stringToFloat(x, 0)} mbar`,
  [MetricType.CO]: (x: string) => `${stringToFloat(x, 2)} ppm`,
  [MetricType.O2]: (x: string) => `${stringToPercentage(x, 0)} %`,
  [MetricType.H2S]: (x: string) => `${stringToFloat(x, 2)} ppm`,
  [MetricType.Pollutants]: (x: string) => `${stringToFloat(x, 1)} kOhm`,
  [MetricType.Particulates2_5]: (x: string) => `${stringToFloat(x, 0)} µg/m³`,
  [MetricType.Particulates10]: (x: string) => `${stringToFloat(x, 0)} µg/m³`,
  [MetricType.NO]: (x: string) => `${stringToFloat(x, 2)} ppm`,
  [MetricType.NO2]: (x: string) => `${stringToFloat(x, 2)} µg/m³`,
  [MetricType.IAQ]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.HCHO]: (x: string) => `${stringToFloat(x, 2)} mg/m³`,
  [MetricType.O3]: (x: string) => `${stringToFloat(x, 0)} ppm`,
  [MetricType.WaterLeak]: (x: string) => x,
  [MetricType.Emissions]: (x: string) => `${stringToFloat(x, 0)} kgCO₂`,

  /** Wellness */
  [MetricType.CO2Score]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.HumidityScore]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.LightScore]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.NoiseScore]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.TemperatureScore]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.WellnessScore]: (x: string) => `${stringToFloat(x, 1)}`,

  /** Energy Monitoring */
  [MetricType.ElectricityKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityKwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityKwhCost]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.GasVolume]: (x: string) => `${stringToFloat(x, 2)} m³`,
  [MetricType.GasVolumeDelta]: (x: string) => `${stringToFloat(x, 2)} m³`,
  [MetricType.GasVolumeCost]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.GasKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.GasKwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.HeatingKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.HeatingKwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.HeatingKwhCost]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.WaterVolume]: (x: string) => `${stringToFloat(x, 2)} m³`,
  [MetricType.WaterVolumeDelta]: (x: string) => `${stringToFloat(x, 2)} m³`,
  [MetricType.WaterVolumeCost]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.CarbonIntensityElectricity]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.CoolingKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.CoolingKwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.CoolingKwhCost]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.CarbonIntensityCooling]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  /** Three phase meters */
  [MetricType.ElectricityGeneratedKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityGeneratedKwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.CarbonIntensityElectricitySaved]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  [MetricType.ElectricityL1Voltage]: (x: string) => `${stringToFloat(x, 2)} V`,
  [MetricType.ElectricityL2Voltage]: (x: string) => `${stringToFloat(x, 2)} V`,
  [MetricType.ElectricityL3Voltage]: (x: string) => `${stringToFloat(x, 2)} V`,

  [MetricType.ElectricityL1Current]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.ElectricityL2Current]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.ElectricityL3Current]: (x: string) => `${stringToFloat(x, 2)} A`,

  [MetricType.ElectricityL1PowerFactor]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.ElectricityL2PowerFactor]: (x: string) => `${stringToFloat(x, 2)}`,
  [MetricType.ElectricityL3PowerFactor]: (x: string) => `${stringToFloat(x, 2)}`,

  [MetricType.ElectricityL1ActivePower]: (x: string) => `${stringToFloat(x, 2)} kW`,
  [MetricType.ElectricityL2ActivePower]: (x: string) => `${stringToFloat(x, 2)} kW`,
  [MetricType.ElectricityL3ActivePower]: (x: string) => `${stringToFloat(x, 2)} kW`,
  [MetricType.ElectricityTotalActivePower]: (x: string) => `${stringToFloat(x, 2)} kW`,

  [MetricType.ElectricityMaxTotalPowerDemand]: (x: string) => `${stringToFloat(x, 2)} kW`,
  [MetricType.ElectricityMaxL1CurrentDemand]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.ElectricityMaxL2CurrentDemand]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.ElectricityMaxL3CurrentDemand]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.ElectricityMaxNCurrentDemand]: (x: string) => `${stringToFloat(x, 2)} A`,

  [MetricType.ElectricityTotalKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityL1ImportKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityL2ImportKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityL3ImportKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  [MetricType.ElectricityL1ExportKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityL2ExportKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityL3ExportKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  [MetricType.ElectricityL1TotalKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityL2TotalKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityL3TotalKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  [MetricType.ElectricityGeneratedL1KwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityGeneratedL2KwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityGeneratedL3KwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  [MetricType.ElectricityConsumedL1KwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityConsumedL2KwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityConsumedL3KwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  /** Occupancy */
  [MetricType.IsolatedPeopleCount]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.EnterCount]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.ExitCount]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.CalculatedOccupancy]: (x: string) => `${stringToFloat(x, 0)}`,

  /** Relay */
  [MetricType.RelayOutputStatus]: (x: string) => (x === 'true' ? 'ON' : 'OFF'),

  /** Switch */
  [MetricType.ContactSwitch]: (x: string) => (x === '1' ? 'Open' : 'Closed'),

  /** Wavetrend */
  [MetricType.Sensor1Temp]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.Sensor2Temp]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.Sensor3Temp]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.Sensor1MinTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.Sensor1MaxTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.Sensor1FlowCount]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.Sensor1Compliant]: (x: string) => parseInt(x) ? 'Yes' : 'No',
  [MetricType.Sensor1Error]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.Sensor2MinTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.Sensor2MaxTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.Sensor2FlowCount]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.Sensor2Compliant]: (x: string) => parseInt(x) ? 'Yes' : 'No',
  [MetricType.Sensor2Error]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.Sensor3MinTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.Sensor3MaxTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.Sensor3FlowCount]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.Sensor3Compliant]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.Sensor3Error]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.SensorScald]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.SensorScaldTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.SensorFreeze]: (x: string) => `${stringToFloat(x, 0)}`,
  [MetricType.SensorFreezeTemp]: (x: string) => `${stringToFloat(x, 0)} °C`,
  [MetricType.ErrorMessage]: (x: string) => x,

  /** Weather */
  [MetricType.WindSpeedAverage]: (x: string) => `${stringToFloat(x, 2)} m/s`,
  [MetricType.WindDirectionAverage]: (x: string) => {
    const degrees = stringToFloat(x, 0);
    return `${degrees}° (${getWindDirectionLabel(degrees)})`
  },

  /** IoTSens Sound Monitor */
  [MetricType.Percentile_P1]: (x: string) => `${stringToFloat(x, 1)} dBA`,
  [MetricType.Percentile_P10]: (x: string) => `${stringToFloat(x, 1)} dBA`,
  [MetricType.Percentile_P50]: (x: string) => `${stringToFloat(x, 1)} dBA`,
  [MetricType.Percentile_P90]: (x: string) => `${stringToFloat(x, 1)} dBA`,
  [MetricType.Percentile_P99]: (x: string) => `${stringToFloat(x, 1)} dBA`,
  [MetricType.DBA_Min]: (x: string) => `${stringToFloat(x, 1)} dBA`,
  [MetricType.LAEQ]: (x: string) => `${stringToFloat(x, 1)} dBA`,
  [MetricType.DBA_Max]: (x: string) => `${stringToFloat(x, 1)} dBA`,

  /**External Metrics */
  [MetricType.CarbonIntensityForecast]: (x: string) => `${stringToFloat(x, 2)} gCO₂/kWh`,

  /** Water Meters */
  [MetricType.WaterMeterAlarm]: (x: string, t?: TFunction) => t ? t(`WaterMeterAlarmCodes.${x}`, { ns: 'enums' }) : x,

  /**Vutility HotDrop */
  [MetricType.AmpHourAccumulation]: (x: string) => `${stringToFloat(x, 2)} Ah`,
  [MetricType.AverageAmps]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.MaximumAmps]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.MinimumAmps]: (x: string) => `${stringToFloat(x, 2)} A`,
  [MetricType.CapacitorVoltage]: (x: string) => `${stringToFloat(x, 2)} V`,

  /**Utopi Eastron SDM230 */
  [MetricType.ElectricityMaxImportPowerDemand]: (x: string) => `${stringToFloat(x, 2)} kW`,
  [MetricType.ElectricityMaxExportPowerDemand]: (x: string) => `${stringToFloat(x, 2)} kW`,
  [MetricType.ElectricityExportKwhDelta]: (x: string) => `${stringToFloat(x, 2)} kWh`,
  [MetricType.ElectricityExportKwh]: (x: string) => `${stringToFloat(x, 2)} kWh`,

  /** MClimate Vicki TRV */
  [MetricType.TemperatureTarget]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.MinTemperature]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.MaxTemperature]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.ManualTargetTemperatureUpdate]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.WindowPosition]: (x: string) => x,
  [MetricType.ChildLock]: (x: string) => x,
  [MetricType.MotorRange]: (x: string) => x,
  [MetricType.MotorPosition]: (x: string) => x,
  [MetricType.HighMotorConsumption]: (x: string) => x,
  [MetricType.LowMotorConsumption]: (x: string) => x,
  [MetricType.BrokenSensor]: (x: string) => x,
  [MetricType.CalibrationFailed]: (x: string) => x,
  [MetricType.AttachedBackplate]: (x: string) => x,
  [MetricType.PerceiveAsOnline]: (x: string) => x,
  [MetricType.OperationalMode]: (x: string) => x,
  [MetricType.KeepAliveTime]: (x: string) => x,

  /**Axioma Qalcasonic E3 */
  [MetricType.HeatingTemperature]: (x: string) => `${stringToFloat(x, 1)} °C`,
  [MetricType.HeatingPower]: (x: string) => `${stringToFloat(x, 1)} kW`,
  [MetricType.HeatingVolume]: (x: string) => `${stringToFloat(x, 1)} m³`,
  [MetricType.CoolingTemperature]: (x: string) => `${stringToFloat(x, 1)} °C`,
});