import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import FlexRow from '@dashboard/components/atoms/FlexRow/FlexRow';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SetupInformation from '../SetupInformation';
import ExceptionsTable from './SpaceExceptions/ExceptionsTable';
import { TRVControlMode } from '@shared/api/enums/TRVControlMode/TRVControlMode';

type ReviewAndActivateProps = {
  siteClimateControl: SiteClimateControlDto;
  exceptions: SpaceClimateControlDto[];
  onEdit: (step: number) => void;
}

const ReviewAndActivate = ({ siteClimateControl, exceptions, onEdit }: ReviewAndActivateProps) => {
  const { t } = useTranslation();
  const { getUnit, toLocale } = useLocalisation();
  const unit = getUnit(MetricType.Temperature);
  const smallScreen = useMediaQuery('(max-width: 375px)');

  return (
    <Wrapper>
      <Card noPadding={true}>
        <StyledFlexCollumn>
          <FlexRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Title
              text={t('ClimateControl.TemperatureRanges', { ns: 'molecules' })}
            />
            <Button
              style={{ padding: 8 }}
              secondary
              label={
                <>
                  <ActionButtonIcon icon={solid('pen-to-square')} style={{ marginRight: smallScreen ? 0 : 8 }} />
                  {!smallScreen &&
                    t('Edit', { ns: 'common' })
                  }
                </>
              }
              onClick={() => onEdit(1)}
            />
          </FlexRow>
          <SiteTempWrapper>
            <Title style={{ fontSize: '16px' }}
              text={t('ClimateControl.SiteTemperature', { ns: 'molecules' })}
            />
            <SiteTemp>
              <SiteTempLabel>
                {t('ClimateControl.Minimum', { ns: 'molecules' })}
              </SiteTempLabel>
              <StyledValue>
                {`${toLocale(MetricType.Temperature, siteClimateControl.minTemp, { round: 0 })} ${unit}`}
              </StyledValue>
            </SiteTemp>
            <SiteTemp>
              <SiteTempLabel>
                {t('ClimateControl.Maximum', { ns: 'molecules' })}
              </SiteTempLabel>
              <StyledValue>
                {`${toLocale(MetricType.Temperature, siteClimateControl.maxTemp, { round: 0 })} ${unit}`}
              </StyledValue>
            </SiteTemp>
            <SiteTemp>
              <SiteTempLabel>
                {t('ClimateControl.InitialTarget', { ns: 'molecules' })}
              </SiteTempLabel>
              <StyledValue>
                {`${toLocale(MetricType.Temperature, siteClimateControl.targetTemp, { round: 0 })} ${unit}`}
              </StyledValue>
            </SiteTemp>
          </SiteTempWrapper>

          <UnoccupiedStepDownWrapper>
            <Title style={{ fontSize: '16px', marginBottom: '10px' }}
              text={t('ClimateControl.UnoccupiedStepDown', { ns: 'molecules' })}
            />
            {siteClimateControl.noMotionRulesEnabled ?
              <>
                <StyledValue>
                  {`${t('ClimateControl.NoMotionFor', { ns: 'molecules' })} ${siteClimateControl.noMotionThreshold1} ${t('ClimateControl.Hours', { ns: 'molecules' })}, ${t('ClimateControl.SetTemperatureTo', { ns: 'molecules' })} ${toLocale(MetricType.Temperature, siteClimateControl.noMotionThreshold1Temp, { round: 0 })} ${unit}`}
                </StyledValue>
                <StyledValue>
                  {`${t('ClimateControl.NoMotionFor', { ns: 'molecules' })} ${siteClimateControl.noMotionThreshold2} ${t('ClimateControl.Hours', { ns: 'molecules' })}, ${t('ClimateControl.SetTemperatureTo', { ns: 'molecules' })} ${toLocale(MetricType.Temperature, siteClimateControl.noMotionThreshold2Temp, { round: 0 })} ${unit}`}
                </StyledValue>
              </> :
              <StyledValue>{t('Off', { ns: 'common' })}</StyledValue>
            }

          </UnoccupiedStepDownWrapper>

          {siteClimateControl.externalSensorModeEnabled &&
            <UnoccupiedStepDownWrapper>
              <Title style={{ fontSize: '16px', marginBottom: '10px' }}
                text={t('ClimateControl.ExternalTemperatureSensor', { ns: 'molecules' })}
              />
              {siteClimateControl.controlMode === TRVControlMode.External
                ?
                <StyledValue>
                  {`${t('ClimateControl.WhenSensorIsOfflineFor', { ns: 'molecules' })} ${siteClimateControl.offlineSensorThreshold} ${t('ClimateControl.Hours', { ns: 'molecules' })}, ${t('ClimateControl.SetTemperatureToExternalSesnor', { ns: 'molecules' })} ${toLocale(MetricType.Temperature, siteClimateControl.offlineSensorTemp, { round: 0 })} ${unit}`}
                </StyledValue>
                :
                <StyledValue>{t('Off', { ns: 'common' })}</StyledValue>
              }
            </UnoccupiedStepDownWrapper>
          }

        </StyledFlexCollumn>
      </Card>

      <Card noPadding={true}>
        <StyledFlexCollumn>
          <FlexRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Title
              text={t('Exceptions', { ns: 'molecules' })}
            />
            <Button
              style={{ padding: 8 }}
              secondary
              label={
                <>
                  <ActionButtonIcon icon={solid('pen-to-square')} style={{ marginRight: smallScreen ? 0 : 8 }} />
                  {!smallScreen &&
                    t('Edit', { ns: 'common' })
                  }
                </>
              }
              onClick={() => onEdit(2)}
            />
          </FlexRow>
          <ExceptionsTable
            exceptions={exceptions}
          />
        </StyledFlexCollumn>
      </Card>

      <SetupInformation />
    </Wrapper>
  )
}

export default ReviewAndActivate

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const StyledFlexCollumn = styled(FlexColumn)`
  gap: 30px;
  padding: 20px;

  @media (max-width: 375px) {
    padding: 15px;
  }
`;

const SiteTempWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const UnoccupiedStepDownWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const StyledValue = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${p => p.theme.text.primary};
`;

const SiteTemp = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const SiteTempLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${p => p.theme.text.secondary};
`;

const ActionButtonIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;