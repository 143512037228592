import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { TableColumn, TableRow, TableSelectIcon, TableSelectIconWrapper, TableValue } from './Table.shared';
import { ITableColumn } from './Table.types';
import TextHighlighter from './TextHighlighter';

interface ITableRecord<TRecord> {
  record: TRecord;
  onSelect: (meter: TRecord, selected: boolean) => void;
  selected: boolean;
  tableIsSelectable?: boolean;
  recordIsSelectable?: boolean;
  columns: ITableColumn<TRecord>[];
  sortColumn: string;
  highlightSortedColumn?: boolean;
  wrapColumns: boolean;
  highlightString?: string,
  compact?: boolean;
  fitContent?: boolean
  onRowClick?: (record: TRecord) => void;
  isChecked?: boolean;
  removeDefaultStyling?: boolean;
  recordBorder?: boolean;
}

const TableRecord = <TRecord extends object>({ record, onSelect, selected, tableIsSelectable, recordIsSelectable, columns, sortColumn, highlightSortedColumn, wrapColumns, highlightString, compact, fitContent, onRowClick, isChecked, removeDefaultStyling, recordBorder }: ITableRecord<TRecord>) => {

  const isSortedByColumn = (property?: string) => {
    return sortColumn === property;
  };

  return (
    <StyledTableRow
      clickable={recordIsSelectable || !!onRowClick}
      wrapColumns={wrapColumns}
      onClick={() => {
        onRowClick && onRowClick(record);
        recordIsSelectable && !onRowClick && onSelect(record, !selected);
      }}
      isChecked={isChecked}
      removeDefaultStyling={removeDefaultStyling}
      recordBorder={recordBorder}
    >
      {tableIsSelectable &&
        <TableSelectIconWrapper>
          {recordIsSelectable &&
            <TableSelectIcon
              selected={selected}
              icon={selected ? faSquareCheck : faSquare}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(record, !selected)
              }}
            />
          }
        </TableSelectIconWrapper>
      }

      {columns.map((column) => {
        const value = record[column.key as keyof TRecord];
        let displayValue = column.displayFormat && column.displayFormat(record);

        if (!displayValue && value !== undefined && value !== null) {
          displayValue = ((value as unknown) as string).toString();
        }

        return (
          <StyledTableColumn
            key={uuidv4()}
            width={column.width}
            fixedWidth={column.fixedWidth}
            rightAlign={!wrapColumns && column.rightAlign}
            wrapColumns={wrapColumns}
            highlight={isSortedByColumn(column.key) && highlightSortedColumn}
            compact={compact}
          >
            <TableValue
              textValue={displayValue}
              rightAlign={!wrapColumns && column.rightAlign}
              wrapColumns={wrapColumns}
              onClick={(e) => {
                if (column.customElement && !column.propagateRowClickOnCustomElement) {
                  e.stopPropagation();
                }
              }}
              compact={compact}
              fitContent={fitContent}
            >
              {!column.customElement &&
                <>
                  {!highlightString && displayValue}

                  {highlightString && displayValue &&
                    <TextHighlighter
                      text={displayValue}
                      highlight={highlightString}
                    />
                  }

                  {column.displaySuffix &&
                    <Suffix>
                      &nbsp;{column.displaySuffix(record)}
                    </Suffix>
                  }
                </>
              }

              {column.customElement &&
                <>
                  {column.customElement(record)}
                </>
              }
            </TableValue>
          </StyledTableColumn>
        );
      })}

    </StyledTableRow>
  );
};

export default React.memo(TableRecord) as typeof TableRecord;

const StyledTableRow = styled(TableRow) <{ clickable?: boolean, isChecked?: boolean, removeDefaultStyling?: boolean, recordBorder?: boolean }>`
  cursor: ${p => p.clickable ? 'pointer' : 'unset'};
  border-bottom: ${p => p.recordBorder ? `1px solid ${p.theme.action.divider}` : 'none'};

  &:nth-child(even) {
    background-color: ${p => p.theme.palette.tables.evenRow};
    ${({ removeDefaultStyling }) =>
    removeDefaultStyling &&
    css`
        background-color: ${p => p.theme.palette.tables.evenRow};;
      `}
    ${({ isChecked, theme }) =>
    isChecked &&
    css`
        background-color: ${theme.palette.backgrounds.surfaceStrong};
      `}
  }
  
  &:nth-child(odd) {
    background-color: ${p => p.theme.palette.tables.unevenRow};
    ${({ removeDefaultStyling }) =>
    removeDefaultStyling &&
    css`
        background-color: ${p => p.theme.palette.tables.evenRow};;
      `}
    ${({ isChecked, theme }) =>
    isChecked &&
    css`
        background-color: ${theme.palette.backgrounds.surfaceStrong};
      `}
  }

  &:last-child {
    border-bottom: none;
  }
  
  &:hover {
    background-color: ${p => p.theme.palette.tables.backgroundHighlight};
  }
`;

const StyledTableColumn = styled(TableColumn) <{ highlight?: boolean }>`
  ${p => p.highlight && css`
    background-color: ${p => p.theme.palette.tables.backgroundHighlight};
  `}
`;

const Suffix = styled.span`
  color: ${p => p.theme.palette.text.fair};
  font-weight: 200;
  font-size: 12px;
`;