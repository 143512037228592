import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { MetricType_ChartColor } from '@shared/api/enums/MetricType/MetricType_ChartColor';
import { transparentize } from 'polished';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import ConsumptionWidget from './ConsumptionWidget';
import { ConsumptionChartView } from './ChartViewSelect';
import { UtilityData } from './UtilitiesSummary';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

export const metricTypeToUtility = {
  [MetricType.ElectricityKwhDelta.toString()]: 'electricity',
  [MetricType.GasVolumeDelta.toString()]: 'gas',
  [MetricType.HeatingKwhDelta.toString()]: 'heating',
  [MetricType.WaterVolumeDelta.toString()]: 'water',
};

const utilities = {
  [MetricType.ElectricityKwhDelta.toString()]: { defintion: solid('bolt'), width: 13, analytics: 'electricity_summary' },
  [MetricType.GasVolumeDelta.toString()]: { defintion: solid('fire'), width: 15, analytics: 'gas_summary' },
  [MetricType.HeatingKwhDelta.toString()]: { defintion: solid('fire-flame-curved'), width: 13, analytics: 'heating_summary' },
  [MetricType.WaterVolumeDelta.toString()]: { defintion: regular('droplet'), width: 13, analytics: 'water_summary' },
};

type UtilityTabsProps = {
  data: UtilityData[];
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const UtilityTabs = ({ data, conversions, onSetConversions }: UtilityTabsProps) => {
  const lineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const [selected, setSelected] = useState<number>(0);
  const [view, setView] = useState<ConsumptionChartView>(ConsumptionChartView.Monthly);

  // Move the line when the selected tab changes
  useEffect(() => {
    const tab = tabRefs.current[selected];

    if (tab && lineRef.current) {
      lineRef.current.style.left = tab.offsetLeft + 'px';
      lineRef.current.style.width = tab.clientWidth + 'px';
    }
  }, [selected, data]);

  const handleTabChange = useCallback((index: number, utility: string) => {
    trackAction(`${utility}_summary`, 'overview_utilities_summary');
    setSelected(index);
  }, [trackAction]);

  if (data.length === 0) {
    return null;
  }

  return (
    <StyledCard>
      <Tabs>
        {data.map((dto, i) => {
          const utility = utilities[dto.metricType];

          return (
            <Tab
              key={dto.metricType}
              ref={element => tabRefs.current[i] = element}
              selected={selected === i}
              onClick={() => handleTabChange(i, metricTypeToUtility[dto.metricType])}
              metricType={dto.metricType}
            >
              {utility &&
                <Icon icon={utility.defintion} $width={utility.width} />
              }
              <Label>{t(`SiteOverview.${dto.metricType}`, { ns: 'molecules' })}</Label>
            </Tab>
          );
        })}

        <TabLine ref={lineRef} />
      </Tabs>

      {data.map((dto, i) => (
        <Content
          key={dto.metricType}
          selected={selected === i}
        >
          <ConsumptionWidget
            metricType={dto.metricType}
            data={dto}
            view={view}
            onViewChange={setView}
            conversions={conversions}
            onSetConversions={onSetConversions}
          />
        </Content>
      ))}
    </StyledCard>
  );
};

export default UtilityTabs;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${p => p.theme.action.divider};
  background-color: ${(p) => p.theme.background.container};
  box-shadow: 0px 3px 8px ${(p) => p.theme.palette.shadows.medium};
  min-width: 530px;
`;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const Icon = styled(FontAwesomeIcon) <{ $width?: number }>`
  width: ${p => p.$width ?? 17}px;
  height: 17px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  user-select: none;
`;

const Tab = styled.div<{ selected: boolean, metricType: MetricType }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 12px;
  transition: all 150ms ease;
  color: ${p => transparentize(0.2, p.theme.palette.text.fair)};
  cursor: pointer;

  ${p => p.selected && css`

    ${Label} {
      color: ${p => p.theme.palette.text.medium};
    }

    ${Icon} {
      color: ${MetricType_ChartColor(p.metricType)};
    }
  `}
`;

const TabLine = styled.div`
  height: 2px;
  background-color: ${p => p.theme.palette.primary};
  transition: left 300ms ease, width 300ms ease;

  position: absolute;
  bottom: 0px;
  left: 0;
`;

const Content = styled.div<{ selected: boolean }>`
  display: none;

  ${p => p.selected && css`
    display: flex;
  `}
`;