import { Button } from '@shared/components/atoms/Button/Button';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import InputFormField from '@dashboard/components/molecules/ResidentApp/Common/InputFormField';
import { FormProvider, useForm } from 'react-hook-form';
import ResidentAppFormErrorMessage from '@dashboard/components/molecules/ResidentApp/Common/ResidentAppFormErrorMessage';
import { useApi } from '@shared/hooks/useApi';
import SiteOperatorResidentAppUpdateCommand from '@shared/api/queries/ResidentApp/SiteOperators/SiteOperatorResidentAppUpdateCommand';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

export type SiteOperatorForm = {
  email: string;
  maxTemp: number;
  minTemp: number;
  electricityTargetPercentage: number;
};

type SiteOperatorEditModalProps = {
  showModal: boolean;
  onCloseModal: () => void;
};

const SiteOperatorEditModal = ({ showModal, onCloseModal }: SiteOperatorEditModalProps) => {
  const { t } = useTranslation(['molecules', 'common']);
  const theme = useTheme();
  const methods = useForm<SiteOperatorForm>()
  const { formState: { errors } } = methods;
  const { execute, loading, error } = useApi();
  const { siteOperator, refreshSiteOperator} = useSiteContext();
  const { toLocale, getUnit, fromLocale } = useLocalisation();

  const onSubmitEdit = async (data: SiteOperatorForm) => {
    if(siteOperator){
      await execute({
        query:
          new SiteOperatorResidentAppUpdateCommand(
            siteOperator.id,
            data.email,
            fromLocale(MetricType.Temperature, data.minTemp),
            fromLocale(MetricType.Temperature, data.maxTemp)
          ),

        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
        pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      })}

    if (!error) {
      onCloseModal();
      refreshSiteOperator();
    }
  };

  return (
    <Modal isOpen={showModal} plainModal>
      <Content>
        <Title
          text={t('ResidentApp.EditGeneralInformation')}
          size="sm"
          wrapperStyle={{ padding: '0 15px 25px 15px' }}
        />

        <FormProvider {...methods}>
          <EditForm>
            <div>
              <InputFormField
                inputName="email"
                label={t('ResidentApp.OperatorContactEmail')}
                value={siteOperator?.email}
                isRequired={true}
                inputType="email"
                requiredErrorMessage={t('EmailRequired', { ns: 'validation' })}
                onValidate={(value) => value.includes('@') || t('InvalidEmail', { ns: 'validation' })}
              />
              {errors.email && <ResidentAppFormErrorMessage message={errors.email.message} />}
            </div>

            <div>
              <InputFormField
                inputName="maxTemp"
                label={t('ResidentApp.MaximumRecommendedTemperature', {temperatureUnit: getUnit(MetricType.Temperature)})}
                value={siteOperator?.maxTemp ? toLocale(MetricType.Temperature, siteOperator?.maxTemp, {round: 1}).toString() : undefined}
                isRequired={true}
                inputType="number"
                min={toLocale(MetricType.Temperature, siteOperator?.minTemp ?? 18)} //cant be lower than default min temperature
                requiredErrorMessage={t('MaxTemperatureRequired', { ns: 'validation' })}
              />
              {errors.maxTemp && <ResidentAppFormErrorMessage message={errors.maxTemp.message} />}
            </div>

            <InputFormField
              inputName="minTemp"
              label={t('ResidentApp.MinimumRecommendedTemperature', {temperatureUnit: getUnit(MetricType.Temperature)})}
              value={toLocale(MetricType.Temperature, siteOperator?.minTemp ?? 18, {round: 1}).toString()}
              inputType="number"
              readOnly={true}
              tooltip={t('ResidentApp.MinimumRecommendedTemperatureTooltip')}
            />

            <Buttons>
              <Button
                label={t('Cancel', { ns: 'common' })}
                onClick={onCloseModal}
                tertiary
                color={theme.palette.systemMessage.error}
                disabled={loading}
              />
              <Button
                label={t('Update', {ns: 'common'})}
                onClick={methods.handleSubmit(onSubmitEdit)}
                disabled={loading}
              />
            </Buttons>
          </EditForm>
        </FormProvider>
      </Content>
    </Modal>
  );
}

export default SiteOperatorEditModal;

const Content = styled.div`
  padding: 20px 0;
`;

const EditForm = styled.form`
  padding: 25px 15px 0 15px;
  border-top: 1px solid ${({ theme }) => theme.palette.borders.medium};
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 15px;
`;