import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@shared/components/atoms/Title/Title';
import { Space } from '@shared/api/models/Space/Space';
import { useApi } from '@shared/hooks/useApi';
import SpaceUpdateCommand from '@dashboard/api/queries/space/SpaceUpdateCommand';
import { Switch } from 'antd';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { CommonSidebar } from '../../shared/CommonSidebar/CommonSidebar';
import { useLayoutContext } from '@src/contexts/LayoutContext';
import { BuildingHierarchyLevel, useBuildingContext } from '../BuildingPage/BuildingProvider';

type PropTypes = {
  space: Space;
}

const Sidebar = ({ space }: PropTypes) => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();
  const { hierarchyLevel } = useBuildingContext();
  const { execute } = useApi();
  const [isOccupied, setIsOccupied] = useState(space?.occupied);
  const [savingInProgress, setSavingInProgress] = useState(false);

  const updateOccupiedState = async () => {
    setSavingInProgress(true);

    const originalState = isOccupied;
    const newState = !isOccupied;
    setIsOccupied(newState);

    const success = await execute({
      query: new SpaceUpdateCommand({ ...space, occupied: newState }),
      pendingMessage: t('Space.Saving', { ns: 'organisms' }),
      successMessage: t('Space.ChangesSaved', { ns: 'organisms' }),
      errorMessage: t('Space.OperationFailed', { ns: 'organisms' }),
    });

    if (!success) {
      setIsOccupied(originalState);
      return;
    }

    setSavingInProgress(false);
  };

  return (
    <CommonSidebar isHidden={isFullScreen || hierarchyLevel !== BuildingHierarchyLevel.Space}>
      <Header>
        <BackButton
          label={t('BackToFloor', { ns: 'navigation' })}
          url='..'
        />

        <Title
          text={space.name}
          size='sm'
        />
      </Header>
      <Body>
        <ToggleWrapper>
          <div style={{ marginBottom: '5px' }}>Occupied</div>
          <Switch
            checked={isOccupied}
            onClick={updateOccupiedState}
            style={{ width: '30px' }}
            size='small'
            disabled={savingInProgress}
          />
        </ToggleWrapper>
        <LabelValuePair
          label={t('Type', { ns: 'common' })}
          value={SpaceType_DisplayString(space.spaceType)}
          size="sm"
          labelStyle={{ fontSize: '16px' }}
          valueStyle={{ fontWeight: 500 }}
        />
        <LabelValuePair
          label={t('Area', { ns: 'common' })}
          value={space.area}
          suffix="m²"
          size="sm"
          labelStyle={{ fontSize: '16px' }}
          valueStyle={{ fontWeight: 500 }}
        />
      </Body>
    </CommonSidebar>
  );
};

export default Sidebar;

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 0;
`;

const ToggleWrapper = styled.div`
  color: ${p => p.theme.text.primary};
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;