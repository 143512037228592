import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Site } from '@shared/api/models/Site/Site';
import { Route as RouteType } from '@shared/types/Route';
import { useApiState } from '@shared/hooks/useApiState';
import { ProtectedRoute } from '@shared/components/navigation/ProtectedRoute/ProtectedRoute';
import { SiteClimateControlGetBySiteIdQuery } from '@shared/api/queries/ClimateControl/Site/SiteClimateControlGetBySiteIdQuery';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import SiteMetadataGetBySiteIdQuery from '@settings/api/queries/SiteMetadata/SiteMetadataGetBySiteIdQuery';
import { SiteOperatorsResidentAppGetBySiteIdQuery } from '@shared/api/queries/ResidentApp/SiteOperators/SiteOperatorsResidentAppGetBySiteIdQuery';
import { SiteMetadata } from '@shared/api/models/SiteMetadata/SiteMetadata';
import { currencyFormat } from '@shared/utils/CurrencyUtils';
import styled from 'styled-components';
import { BreadcrumbHeight, TopBarHeight } from '@src/constants/LayoutConstants';
import Breadcrumb from '@src/components/pages/SitePage/Layout/Breadcrumb';
import Sidebar from '@src/components/pages/SitePage/Layout/Sidebar';
import SiteGetByIdQuery from '@dashboard/api/queries/site/SiteGetByIdQuery';
import NoData from '@shared/components/atoms/NoData/NoData';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import { BuildingsWithFloorsGetBySiteIdQuery } from '@dashboard/api/queries/building/BuildingsWithFloorsGetBySiteIdQuery';
import { BuildingWithFloors } from '@shared/api/models/Building/BuildingWithFloors';
import { useLayoutContext } from '@src/contexts/LayoutContext';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { SiteOperatorDto } from '@shared/api/models/ResidentApp/SiteOperatorDto';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { UnreadAlertCountBySiteGetQuery } from '@dashboard/api/queries/Alerts/UnreadAlertCountBySiteGetQuery';
import SiteSalesOrderGetBySiteIdQuery from '@settings/api/queries/SalesOrder/SiteSalesOrderGetBySiteIdQuery';
import { SalesOrder } from '@shared/api/models/SalesOrder/SalesOrder';
import CurrentWeatherGetBySiteIdQuery from '@shared/api/queries/CurrentWeather/CurrentWeatherGetBySiteIdQuery';
import { CurrentWeatherDto } from '@dashboard/api/models/CurrentWeatherDto';

type PropTypes = {
  routes?: RouteType[],
};

interface ISiteContext {
  site: Site;
  buildings: BuildingWithFloors[];
  refreshBuildings: () => void;
  siteClimateControl?: SiteClimateControlDto;
  refreshSiteClimateControl: () => void;
  siteMetadata?: SiteMetadata;
  refreshSiteMetadata: () => void;
  unreadAlertCount: number;
  refreshUnreadAlerts: () => void;
  loadingUnreadAlerts: boolean;
  siteCurrencyFormat: (value: number) => string;
  salesOrder?: SalesOrder;
  refreshSalesOrder: () => void;
  loadingSalesOrder: boolean;
  siteOperator?: SiteOperatorDto;
  refreshSiteOperator: () => void;
  weather?: CurrentWeatherDto;
}

export const SiteContext = React.createContext({} as ISiteContext);
export const useSiteContext = () => useContext(SiteContext);

const SiteProvider = ({ routes }: PropTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { register } = useAnalytics();
  const { isFullScreen } = useLayoutContext();
  const { siteId } = useParams<{ siteId: string }>();
  const { data: site, loading: loadingSite } = useApiState({
    query: siteId === undefined ? undefined : new SiteGetByIdQuery(parseInt(siteId)),
  }, [siteId]);

  const { hasFeatureFlag } = useTenantContext();

  const { data: unreadAlertCount, loading: loadingUnreadAlerts, execute: refreshUnreadAlerts } = useApiState({
    query: siteId === undefined ? undefined : new UnreadAlertCountBySiteGetQuery(parseInt(siteId)),
    initialState: 0,
  }, [siteId, hasFeatureFlag]);

  const { data: buildings, execute: refreshBuildings } = useApiState({
    query: siteId === undefined ? undefined : new BuildingsWithFloorsGetBySiteIdQuery(parseInt(siteId)),
    initialState: []
  }, [siteId]);

  const { data: siteClimateControl, execute: refreshSiteClimateControl } = useApiState({
    query: site && new SiteClimateControlGetBySiteIdQuery(site.id)
  }, [site]);

  const { data: siteMetadata, execute: refreshSiteMetadata } = useApiState({
    query: site && new SiteMetadataGetBySiteIdQuery(site.id.toString())
  }, [site]);

  const { data: salesOrder, loading: loadingSalesOrder, execute: refreshSalesOrder } = useApiState({
    query: site && new SiteSalesOrderGetBySiteIdQuery(site.id)
  }, [site]);

  const { data: siteOperator, execute: refreshSiteOperator } = useApiState({
    query: site && new SiteOperatorsResidentAppGetBySiteIdQuery(site.id)
  }, [site])

  // Get current weather at the site
  const { data: weather } = useApiState({
    query: site && new CurrentWeatherGetBySiteIdQuery(site.id)
  }, [site]);

  // Set analytics properties for the current site
  useEffect(() => {
    if (site) {
      register({ site_id: site.id, site_name: site.name });
    }
  }, [site, register]);

  // Navigate to first child route
  useEffect(() => {
    const path = routes?.[0].link?.path;
    const pathLength = location.pathname.split('/').length;
    if (pathLength === 3 && path) {
      navigate(path, { replace: true });
    }
  }, [location, routes, siteId, navigate]);

  const siteCurrencyFormat = useCallback((value: number) => {
    return currencyFormat(siteMetadata?.currency).format(value)
  }, [siteMetadata]);

  const siteContextProviderValue = useMemo(() => {
    // if site doesn't exist then set temporary initial site to resolve type error (but will not render as will return null if site is null)
    const initialSite = site ?? { id: 0, name: '', address: { addressLine1: '', city: '', country: '', postCode: '' } };
    return { site: initialSite, buildings, refreshBuildings, siteClimateControl, refreshSiteClimateControl, siteMetadata, refreshSiteMetadata, siteCurrencyFormat, unreadAlertCount, refreshUnreadAlerts, loadingUnreadAlerts, salesOrder, refreshSalesOrder, loadingSalesOrder, siteOperator, refreshSiteOperator, weather }
  }, [site, buildings, refreshBuildings, siteClimateControl, refreshSiteClimateControl, siteMetadata, refreshSiteMetadata, siteCurrencyFormat, unreadAlertCount, refreshUnreadAlerts, loadingUnreadAlerts, salesOrder, refreshSalesOrder, loadingSalesOrder, siteOperator, refreshSiteOperator, weather]);

  if (loadingSite) {
    return (
      <LoadingWidget
        label={t('LoadingSite', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  if (!site) {
    return (
      <NoData
        label={t('SiteNotFound', { ns: 'status' })}
        styles={{ margin: 80 }}
      />
    );
  }

  return (
    <SiteContext.Provider value={siteContextProviderValue}>
      <Breadcrumb site={site} />

      <FlexRow isFullScreen={isFullScreen}>
        <Sidebar routes={routes} />

        <Content>
          <Routes>
            {routes?.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    route={route}
                    customProps={{ site }}
                  />
                }
              />
            ))}
          </Routes>
        </Content>
      </FlexRow>
    </SiteContext.Provider>
  );
};

export default SiteProvider;

const FlexRow = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  height: ${p => `calc(100% - ${TopBarHeight}px - ${p.isFullScreen ? 0 : BreadcrumbHeight}px)`};
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  @media print {
    overflow: visible;
  }
`;