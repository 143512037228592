import { LettingStatus } from '@shared/api/enums/LettingStatus/LettingStatus';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import EsgMetricType from '../enums/EsgMetricType/EsgMetricType';

export type EsgExplorerConfiguration = {
  siteId: number,
  buildingIds: number[],
  spaceTypes: SpaceType[],
  lettingStatuses: LettingStatus[],
  metricTypes: EsgMetricType[],
  timeRange?: { from: string, to: string },
  filters: EsgExplorerFilter[]
}

export type EsgExplorerFilter = {
  metric?: EsgMetricType,
  type?: EsgExplorerFilterType,
  threshold?: number | null,
  min?: number | null,
  max?: number | null
};

export enum EsgExplorerFilterType {
  EqualTo = 'EqualTo',
  NotEqualTo = 'NotEqualTo',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  InsideOfRange = 'InsideOfRange',
  OutsideOfRange = 'OutsideOfRange',
  InTopTen = 'InTopTen',
  AboveAverage = 'AboveAverage',
  BelowAverage = 'BelowAverage',
}